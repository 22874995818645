import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TipoAgendamento } from '../../../models/model';
import { ListaAgendamentoService } from '../../../services/lista-agendamento.service';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { EventsService } from '../../../services/events.service';

@Component({
  selector: 'app-lista-documentos',
  templateUrl: './lista-documentos.component.html',
  styleUrls: ['./lista-documentos.component.css']
})
export class ListaDocumentosComponent {
  tipoAgendamento = new Observable<TipoAgendamento | undefined>();

  baseWP: string = environment.baseWP;

  constructor(
    private route: ActivatedRoute,
    private listaAgendamentoService: ListaAgendamentoService,
    private eventsService: EventsService
  ) { }

  ToBack(){
    if (window.history.length > 1) {
      window.history.back();
    } else {
        window.location.href = '/agendamento';
    }
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      const id = params['id'];
      this.tipoAgendamento = this.listaAgendamentoService.get(id);
      this.tipoAgendamento.subscribe((servico: any) => {
        this.eventsService.emit('breadcrumb-update', '<a href="/servicos">Serviços</a> > ' + servico.nome);
      });
    });
  }
}
