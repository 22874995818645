<div class="services">
    <div class="row">
      <div class="col">
        <h4 class=" font-style-4">Serviços por órgãos</h4>
        <div class="list-services">
          <ul>
            <li *ngFor="let orgao of orgaos">
              <a class="item" href="{{'/servicos-orgao/orgao-' + orgao.idOrgao}}">
  
                <div class="icon" *ngIf="orgao.iconeTema !== ''; else iconShield">
                  <i class={{orgao.iconeTema}}></i>
                </div>
                <ng-template #iconShield>
                  <div class="icon">
                    <i class="fa fa-shield fa-inverse"></i>
                  </div>
                </ng-template>
  
                <p class="font-style-3">{{orgao.nomeOrgao}}</p>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>