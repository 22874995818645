<section class="template-default container">
    <h3>O seu portal de serviços está de cara nova!</h3>
    <p>O seu principal canal sobre os serviços públicos do Governo do Estado de Pernambuco mudou!<br>Confira as principais novidades do novo PE Cidadão.</p>

    <h3 class="desktop-mt-52">O que há de novo no PE Cidadão?</h3>

    <div class="cardlist-default">
        <div class="card-default">
            <img src="../../../assets/image/o-que-ha-de-novo-1.png" alt="Image">
            <div class="card-default-title">Navegação fácil</div>
            <div class="card-default-content">A experiência com o portal foi aprimorada e, agora, o destaque vai para as informações que são mais importantes para você. Ficou mais rápido encontrar os serviços que você precisa e as principais informações sobre eles.</div>
        </div>
        <div class="card-default">
            <img src="../../../assets/image/o-que-ha-de-novo-2.png" alt="Image">
            <div class="card-default-title">Conteúdos mais simples</div>
            <div class="card-default-content">O portal passou por uma revisão que vai facilitar seu entendimento sobre como solicitar os serviços públicos do Governo de Pernambuco.</div>
        </div>
        <div class="card-default">
            <img src="../../../assets/image/o-que-ha-de-novo-3.png" alt="Image">
            <div class="card-default-title">É só o começo</div>
            <div class="card-default-content">A mudança no portal é o primeiro passo de uma jornada que vai deixar você ainda mais perto do governo do estado. Em breve, uma plataforma digital vai tornar seu acesso aos serviços públicos ainda mais fácil e ágil!</div>
        </div>
    </div>

    <h3 class="pb-24">Como navegar no novo portal</h3>

    <p><b>Como encontrar um serviço?</b></p>

    <ul>
        <li>Clique “Digite sua busca” na tela inicial</li>
        <li>Escreva o nome serviço que você quer encontrar</li>
        <li>Clique no ícone de lupa</li>
        <li>Selecione o serviço desejado</li>
    </ul>

    <p><b>Como encontrar um serviço por órgãos?</b></p>

    <ul>
        <li>Clique na aba “Serviços” na tela inicial</li>
        <li>Clique no órgão responsável pelo serviço</li>
        <li>Busque o serviço desejado</li>
    </ul>

    <p><b>Como agendar um serviço?</b></p>

    <ul>
        <li>Na tela inicial, em “Agendamentos”, clique no botão “Agendar serviço”</li>
        <li>Selecione o serviço que você quer agendar</li>
        <li>Clique no botão “Agendar”</li>
        <li>Preencha os campos com seus dados pessoais e clique em “Avançar”</li>
        <li>Selecione o local de atendimento, dia e horário</li>
        <li>Clique em “Confirmar” para fazer o agendamento</li>
    </ul>

    <p><b>Como cancelar um agendamento?</b></p>

    <ul>
        <li>Na tela inicial, clique em “Acompanhar agendamento”</li>
        <li>Preencha os campos com seus dados pessoais</li>
        <li>Clique novamente no botão “Acompanhar agendamento”.</li>
        <li>Clique em “Cancelar agendamento”.</li>
    </ul>

    <p><b>Como fazer um reagendamento?</b></p>

    <ul>
        <li>Na tela inicial, clique em “Acompanhar agendamento”</li>
        <li>Preencha os campos com seus dados pessoais</li>
        <li>Clique novamente no botão "Acompanhar agendamento"</li>
        <li>No serviço selecionado, clique em “Reagendar atendimento”</li>
        <li>Preencha o formulário com data e horário do reagendamento</li>
        <li>Clique em “Reagendar”</li>
    </ul>

    <h3>Ainda tem dúvidas? Quer contribuir com alguma sugestão ou reclamação?</h3>

    <a class="btn-secondary-default" href="/fale-conosco">Fale Conosco</a>
</section>