<div class="container">
    <!-- <div class="row">
        <div class="col-12">
            <section class="header-section">
                <div class="info">
                    <div class="flex">
                        <a href="/agendamentos/{{protocolo}}/{{nascimento}}">
                            <img src="../../../../assets/image/icons/icon-return.png" alt="Voltar" >
                        </a>
                        <h4 *ngIf="!isLoading && !listEmpty">{{agendamento.horarioAgenda.servicoUnidade.servico.nome}}</h4>
                        <h4 *ngIf="!isLoading && listEmpty">Acompanhar processo</h4>
                    </div>
                    <a href="/" ><img src="../../../../assets/image/icons/icon-close.png" alt="Fechar" ></a>
                </div>
            </section>
        </div>
    </div> -->

    <div class="row">
        <div class="col">
            <p *ngIf="isLoading" class="center-p">Carregando...</p>
            <p *ngIf="listEmpty" class="center-p">Nenhum agendamento encontrado.</p>

                <div class="box" *ngIf="!isLoading && !listEmpty">
                    <div class="title">Processo #{{agendamento.id}}</div>
                    <p class="subtitle" *ngIf="agendamento.status == 'AGENDADO'">Seu atendimento foi agendado!</p>
                    <!-- Boleto de pagamento (DAE) emitido -->
                    <p class="subtitle" *ngIf="agendamento.status == 'ATENDIDO'">Seu atendimento foi realizado!</p>
                    <p class="subtitle" *ngIf="agendamento.status == 'CANCELADO'">Seu atendimento foi cancelado!</p>
                    <p class="subtext" *ngIf="agendamento.status == 'AGENDADO' && !agendamento.horarioAgenda.servicoUnidade.servico.flagGratuito">
                        O atendimento para a emissão da {{agendamento.horarioAgenda.servicoUnidade.servico.nome}} de {{agendamento.pessoa.nome}} acontecerá no dia {{formatarData(agendamento.horarioAgenda.horario)}} às {{formatarHorario(agendamento.horarioAgenda.horario)}} no {{agendamento.horarioAgenda.servicoUnidade.unidadeAtendimento.nome}}.
                    </p>
                    <p class="subtext" *ngIf="agendamento.status == 'ATENDIDO' && !agendamento.horarioAgenda.servicoUnidade.servico.flagGratuito">
                        A emissão da {{agendamento.horarioAgenda.servicoUnidade.servico.nome}} de {{agendamento.pessoa.nome}} está prevista para o dia 00/00/0000.
                    </p>
                    <div class="linha-tempo">
                        <div class="item-tempo">
                            <p class="title">Abertura de agendamento</p>
                            <p class="subtitle">{{formatarDataHora(agendamento.agendamento)}}</p>
                        </div>
                        <div class="item-tempo" *ngIf="['RESERVADO','AGENDADO','CANCELADO','CANCELADO_USUARIO','ATENDIDO','NAO_COMPARECIDO'].includes(agendamento.status) && !agendamento.horarioAgenda.servicoUnidade.servico.flagGratuito">
                            <p class="title">Boleto de pagamento (DAE) emitido</p>
                        </div>
                        <div class="item-tempo" *ngIf="['RESERVADO','AGENDADO','CANCELADO','CANCELADO_USUARIO','ATENDIDO','NAO_COMPARECIDO'].includes(agendamento.status) && !agendamento.horarioAgenda.servicoUnidade.servico.flagGratuito">
                            <p class="title">Boleto de pagamento (DAE) confirmado</p>
                        </div>
                        <div class="item-tempo" *ngIf="['RESERVADO'].includes(agendamento.status)">
                            <p class="title">Atendimento Reservado</p>
                            <p class="subtitle">{{formatarDataHora(agendamento.agendamento)}}</p>
                        </div>
                        <div class="item-tempo" *ngIf="['AGENDADO','CANCELADO','CANCELADO_USUARIO','ATENDIDO','NAO_COMPARECIDO'].includes(agendamento.status)">
                            <p class="title">Atendimento Agendado</p>
                            <p class="subtitle">{{formatarDataHora(agendamento.horarioAgenda.horario)}}</p>
                            <div class="agendamento-info">
                                <p>Município: {{agendamento.horarioAgenda.servicoUnidade.unidadeAtendimento.endereco.municipio}}</p>
                                <p>Local de atendimento: {{agendamento.horarioAgenda.servicoUnidade.unidadeAtendimento.nome}}</p>
                                <p>Data: {{formatarData(agendamento.horarioAgenda.horario)}}</p>
                                <p>Horário: {{formatarHorario(agendamento.horarioAgenda.horario)}}</p>
                            </div>
                        </div>
                        <div class="item-tempo" *ngIf="agendamento.status == 'CANCELADO' || agendamento.status == 'CANCELADO_USUARIO'">
                            <p class="title">Atendimento Cancelado</p>
                        </div>
                        <div class="item-tempo" *ngIf="agendamento.status == 'ATENDIDO'">
                            <p class="title">Atendimento Atendido</p>
                        </div>
                        <div class="item-tempo" *ngIf="agendamento.status == 'NAO_COMPARECIDO'">
                            <p class="title">Atendimento Não Comparecido</p>
                        </div>
                        <div class="item-tempo" *ngIf="agendamento.status == 'ATENDIDO'">
                            <p class="title">Atendimento realizado</p>
                        </div>
                    </div>
                    <div *ngIf="isEnabled('texto-agendado')">
                        <p class="subtext">Compareça ao local de atendimento no dia e horário agendados para apresentar os documentos solicitados.</p>
                    </div>
                    <div class="actions" *ngIf="isEnabled('reagendar')">
                        <a href="/agendamentos/{{protocolo}}/{{nascimento}}/{{agendamento.id}}/reagendamento"><button class="gray">Reagendar atendimento</button></a>
                    </div>
                    <hr *ngIf="isEnabled('reagendar')" />
                    <div class="actions">
                        <a href="/agendamento/documentos/{{agendamento.horarioAgenda.servicoUnidade.servico.id}}" *ngIf="isEnabled('lista-documentos-btn')">
                            <button class="blue">Ver lista de documentos</button>
                        </a>
                        <a href="/agendamentos/{{protocolo}}/{{nascimento}}/{{agendamento.id}}/cancelamento" *ngIf="isEnabled('cancelar')"><button class="red">Cancelar agendamento</button></a>
                        <a href="/agendamentos/{{protocolo}}/{{nascimento}}" *ngIf="isEnabled('voltar-btn')"><button class="red">Voltar</button></a>
                    </div>
                </div>
        </div>
    </div>
</div>