import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TipoAgendamento } from '../../../models/model';
import { ListaAgendamentoService } from '../../../services/lista-agendamento.service';
import { Observable } from 'rxjs';
import { EventsService } from '../../../services/events.service';
import { environment } from 'src/environments/environment';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-agendar',
  templateUrl: './agendar.component.html',
  styleUrls: ['./agendar.component.css']
})
export class AgendarComponent {
  servico = new Observable<TipoAgendamento | undefined>();
  
  constructor(
    private route: ActivatedRoute,
    private listaAgendamentoService: ListaAgendamentoService,
    private eventsService: EventsService,
  ) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      const id = params['id'];
      this.servico = this.listaAgendamentoService.get(id);
      this.servico.subscribe((servico: any) => {
        if(servico){
          this.eventsService.emit('breadcrumb-update', '<a href="/agendamento">Agendamento</a> > ' + servico.nome);
        }
      });
    });
  }

}
