import { Component, Input, ViewChild } from '@angular/core';
import { TipoAgendamento, AgendamentoParametroServico, AgendamentoParametroOrgao } from '../../../models/model';
import { AgendamentoService } from '../../../services/agendamento.service';
import { FormService } from '../../../services/form.service';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { FormControl } from '@angular/forms';
import { Observable, TimeInterval } from 'rxjs';
import { saveAs } from 'file-saver';
import { EventsService } from 'src/app/services/events.service';

@Component({
  selector: 'app-form-agendamento',
  templateUrl: './form-agendamento.component.html',
  styleUrls: ['./form-agendamento.component.css']
})
export class FormAgendamentoComponent {
  @Input() servico = new Observable<TipoAgendamento | undefined>();

  Servico: any = {};

  servicoID: number = 0;

  orgaoID: number = 109;

  result: any = { confirmed: false, submitted: false };

  formErrorPopup: string = '';

  formLoadingPopup: string = '';

  pagamentoErro: string = '';

  currentForm: string = 'section1';

  allForms: any = {
    section1: {
      title: 'Dados pessoais',
      step: 0,
      description: 'Preencha os campos abaixo com os dados da pessoa solicitante do serviço.',
    },
    section2: {
      title: 'Dependentes',
      step: 0,
      description: 'Preencha os campos abaixo com os dados do dependente.',
    },
    pagamento: {
      title: 'Boleto de pagamento',
      step: 0,
      description: 'Verifique se os dados abaixo estão corretos antes de imprimir e pagar seu boleto (DAE).',
    },
    section3: {
      title: 'Local de atendimento',
      step: 1,
      description: 'Selecione local e agenda que deseja atendimento',
    },
    section4: {
      title: 'Confirmar atendimento',
      step: 2,
      description: 'Verifique se os dados abaixo estão corretos e confirme seu agendamento.',
    },
  };

  helper: AgendamentoService = new AgendamentoService(this.http);

  listaMunicipio: string[] = [];

  formData: { [key: string]: FormService } = {};

  agendamentoReserva: any = {};

  sessionValid: boolean = true;

  sessionInterval: any = setTimeout(() => {}, 0);

  agendamentoProtocolo: string = '';

  constructor(private http: HttpClient, private eventsService: EventsService) {}

  maxDependentes: number = 1;

  servicoGratuito: boolean = true;

  aceiteServicoPago: boolean = false;

  dadosDAE: any = {};

  pagamentoMensagem: string = '';

  parametroServico: any = {};

  ngOnInit(){
    this.servico.subscribe((servico: any) => {
      if (servico) {
        this.Servico = servico;
        this.servicoID = servico.id;
        this.servicoGratuito = servico.flagGratuito;
        this.helper.getAgendamentoParametroServico(servico.id).subscribe((value: any) => {
          this.parametroServico = value;
          this.orgaoID = value.orgao.id;
        });
        const data: { [key: string]: any } = this.helper.getAgendamentoData(`${servico.id}`);
        data['agendaUnidade'].subscribe((value: any) => {
          this.listaMunicipio = value.sort();
        });
        this.helper.getAgendamentoParametroServico(`${servico.id}`).subscribe((value: any) => {
          if(value && value.id){
            this.maxDependentes = value.maximoPorAgendamento;
          }
        });
      }
    });
  }

  navigate(name: string){
    if(name == 'close'){
      window.location.href = '/agendamento';
    }else if(name == 'back'){
      if(this.currentForm == 'section1'){
        window.location.href = '/agendamento';
      }else if(this.currentForm == 'pagamento'){
        this.currentForm = 'section1';
        window.scrollTo(0, 0);
      }else if(this.currentForm == 'section2'){
        this.currentForm = 'section1';
        window.scrollTo(0, 0);
      }else if(this.currentForm == 'section3'){
        this.ResetSection('section2');
        window.scrollTo(0, 0);
        if(this.maxDependentes == 1){
          this.currentForm = 'section1';
        }else{
          this.currentForm = 'section2';
        }
      }else if(this.currentForm == 'section4'){
        window.scrollTo(0, 0);
        this.currentForm = 'section3';
      }
    }else{
      this.currentForm = name;
    }
  }

  ResetSection(name: string){
    // for(let itemName of Object.keys(this.formData[name].fields)){
    //   this.formData['section2'].fields[itemName] = "";
    //   this.formData['section2'].message[itemName] = "";
    // }
    this.eventsService.emit(name + '-clear',false);
    if(name == 'section2'){
      this.eventsService.emit('section2-municipio', false);
      this.eventsService.emit('section2-unidade', false);
      this.eventsService.emit('section2-horario', false);
    }
  }

  submit(form: FormService, name: string){
    this.formData[name] = form;
    switch(name){
      case 'section1':
        this.eventsService.emit('loader-toggle', true);
        this.sessionTimeout();
        this.helper.verificaAgendamentosAbertos(this.formData, this.servicoID, this.orgaoID).subscribe((result: any) => {
          this.eventsService.emit('loader-toggle', false);
          this.formErrorPopup = this.getErrorMessage(result);
          if(!this.formErrorPopup){
            if(Array.isArray(result) && result.length > 0){
              this.formErrorPopup = 'Não é possível realizar um novo agendamento quando já existem agendamentos abertos e/ou não comparecidos recentemente.';              
            }else{
              this.helper.verificaAgendamentosNaoComparecidos(this.formData, this.servicoID, this.orgaoID).subscribe((result: any) => {
                this.formErrorPopup = this.getErrorMessage(result);
                if(!this.formErrorPopup){
                  if(Array.isArray(result) && result.length > 0){
                    this.formErrorPopup = 'Não é possível realizar um novo agendamento quando já existem agendamentos abertos e/ou não comparecidos recentemente.';              
                  }else{
                    if(this.servicoGratuito){
                      if(this.maxDependentes == 1){
                        this.currentForm = 'section3';
                        window.scrollTo(0, 0);
                      }else{
                        this.currentForm = 'section2';
                        window.scrollTo(0, 0);
                      }
                    }else{
                      this.verificarDadosDAE(form);
                    }
                  }
                }
              });
            }
          }
        });
        break;
      case 'section2':
        this.currentForm = 'section3';
        window.scrollTo(0, 0);
        break;
      case 'section3':
        this.currentForm = 'section4';
        window.scrollTo(0, 0);
        break;
      case 'section4':
        this.sendFormData();
        break;
      default:
        this.currentForm = 'section1';
        window.scrollTo(0, 0);
        break;
    }
  }

  sendFormData(){
    this.eventsService.emit('loader-toggle', true);
    this.sendReservarAgendamento(() => {
      let sessionToken = this.formData['section1'].fields['sessiontoken'];
      this.helper.sendAgendamentoForm(this.agendamentoReserva, sessionToken).subscribe((result: any) => {
        this.eventsService.emit('loader-toggle', false);
        this.formErrorPopup = this.getErrorMessage(result);
        if(!this.formErrorPopup){
          this.result.submitted = true;
          this.result.confirmed = false;
          if(result.protocolo){
            this.result.confirmed = true;
            this.agendamentoProtocolo = result.protocolo;
          }
        }
      });
    });
  }

  OnSessionToken(token: string){
    this.formData['section1'].fields['sessiontoken'] = token;
    this.sessionValid = true;
    this.sessionTimeout();
  }

  sendReservarAgendamento(callback: Function){
    let sessionToken = this.formData['section1'].fields['sessiontoken'];
    this.helper.reservarAgendamento(this.formData, sessionToken, this.dadosDAE, this.Servico).subscribe((result) => {
      this.formErrorPopup = this.getErrorMessage(result);
      if(this.formErrorPopup == 'Data além do limite liberado.'){
        this.currentForm = 'section3';
      }
      this.agendamentoReserva = result;
      if(this.formData['section1'].fields['autorizacao']){
        this.agendamentoReserva.lembreteEmail = true;
      }
      callback();
    });
  }

  getErrorMessage(result: any){
    if(Array.isArray(result)){
      if(result[0] && result[0].error){
        return result[0].error;
      }
      return '';
    }
    return '';
  }

  sessionTimeout(){
    clearTimeout(this.sessionInterval);
    this.sessionInterval = setTimeout(() => {
      this.sessionValid = false;
    }, 15 * 60 * 1000);
  }

  aceitarServicoPago() {
    this.aceiteServicoPago = true;
  }

  voltarErroDAE(){
    this.dadosDAE = {};
  }

  verificarDadosDAE(formData: FormService){
    this.eventsService.emit('loader-toggle', true);
    this.currentForm = 'pagamento';
    this.formLoadingPopup = 'Aguarde enquanto verificamos seus dados.';
    this.helper.verificaDadosDAE(formData, this.servicoID)
    .subscribe((result: any) => {
      this.eventsService.emit('loader-toggle', false);
      this.dadosDAE = result;
      this.formLoadingPopup = '';
      if(!result.erro){
        if (!result.necessario) {
          if(this.maxDependentes == 1){
            this.currentForm = 'section3';
          }else{
            this.currentForm = 'section2';
          }
        } else {
          if (result.pago) {
            if(this.maxDependentes == 1){
              this.currentForm = 'section3';
            }else{
              this.currentForm = 'section2';
            }
          } else {
            if (result.codigoDae) {
              this.pagamentoMensagem = 'Você possui um DAE em aberto. Para prosseguir é preciso realizar o pagamento.\nCaso o pagamento já tenha sido realizado, a compensação pode demorar até 24h. Favor tentar novamente mais tarde.';
            } else {
              this.pagamentoMensagem = 'O serviço solicitado necessita de pagamento de DAE para prosseguir com o agendamento. As vagas de agendamento serão liberadas após a confirmação do pagamento (tempo estimado de 24h após o pagamento).';
            }
          }
        }
      }else{
        this.pagamentoErro = result.descricaoErro;
      }
    });
  }

  imprimirDAE(){
    this.formLoadingPopup = 'Aguarde enquanto verificamos seus dados.';
    if(this.dadosDAE.codigoDae){
      this.downloadDAE();
    }else{
      this.eventsService.emit('loader-toggle', true);
      const formData = this.formData['section1'];
      const nascimento = formData.fields['nascimento'].split('/').reverse().join('-') + 'T03:00:00.000Z';
      const data = {
        "servicoId": this.servicoID,
        "pessoa":{
          "nome": formData.fields['nomecompleto'].toUpperCase(),
          // "nomeMae": formData.fields['nomemae'],
          //"rg": formData.fields['rg'].replace(/\D/g,''),
          "cpf": formData.fields['cpf'].replace(/\D/g,''),
          "nascimento": nascimento,
          "email": formData.fields['email'],
          "telefoneCelular": formData.fields['celular'],
          "telefoneFixo": formData.fields['fixo']
        },
        "titularParticipa":true,
      };
      this.helper.gerarDAE(data, this.formData['section1'].fields['sessiontoken'])
      .subscribe((resp: any) => {
        this.eventsService.emit('loader-toggle', false);
        if (resp.erro) {
          this.formErrorPopup = resp.descricaoErro;
          this.formLoadingPopup = '';
        } else if (resp.codigoDae) {
          this.dadosDAE = resp;
          this.downloadDAE();
        }
      });


    }
  }

  downloadDAE(){
    this.eventsService.emit('loader-toggle', true);
    let sessionToken = this.formData['section1'].fields['sessiontoken'];
    this.helper.gerarBoletoDAE(this.dadosDAE.codigoDae, this.servicoID, sessionToken)
    .subscribe((event: any) => {
        this.eventsService.emit('loader-toggle', false);
        if (event instanceof HttpResponse) {
          this.formLoadingPopup = '';
          const data = <Blob>event.body;
          this.saveAs(data, `DAE20_${this.dadosDAE.codigoDae}.pdf`);
          this.closeAllPopups();
        }else{
          this.formLoadingPopup = '';
        }
    });
  }

  saveAs(blobData: any, filename: string){
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blobData);
    link.download = filename;
    link.click();
  }

  closeAllPopups(){
    this.result = { confirmed: false, submitted: false };
    this.formErrorPopup = '';
    this.formLoadingPopup = '';
    this.dadosDAE = {};
    this.pagamentoMensagem = '';
  }

}
