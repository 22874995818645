<div class="canais-atendimento" [hidden]="semCanaisAtendimento">
    <h4>Canais de atendimento</h4>
    <div class="single-tabs-container">
        <div class="single-tabs">
            <div [class]="'single-tab ' + (CurrentTab === 0 ? 'active' : '')" (click)="TabNavigate(0)" *ngIf="Servico.listaUnidadesAtendimento && Servico.listaUnidadesAtendimento.length > 0">Presencial</div>
            <div [class]="'single-tab ' + (CurrentTab === 1 ? 'active' : '')" (click)="TabNavigate(1)" *ngIf="Servico.servicosOnline && Servico.servicosOnline.length > 0">Web</div>
            <div [class]="'single-tab ' + (CurrentTab === 2 ? 'active' : '')" (click)="TabNavigate(2)" *ngIf="Servico.servicoAplicativoMovel && Servico.servicoAplicativoMovel.length > 0">Apps móveis</div>
            <div [class]="'single-tab ' + (CurrentTab === 3 ? 'active' : '')" (click)="TabNavigate(3)" *ngIf="Servico.servicoEmail && Servico.servicoEmail.length > 0">Email</div>
            <div [class]="'single-tab ' + (CurrentTab === 4 ? 'active' : '')" (click)="TabNavigate(4)" *ngIf="Servico.servicosTelefonicos && Servico.servicosTelefonicos.length > 0">Telefone</div>
            <div [class]="'single-tab ' + (CurrentTab === 5 ? 'active' : '')" (click)="TabNavigate(5)" *ngIf="Servico.servicoMensagemInstantanea && Servico.servicoMensagemInstantanea.length > 0">Chat</div>
        </div>
    </div>
    <div [class]="responsiveState == 'desktop' ? 'single-tabs-content container-default' : 'single-tabs-content'">
        <div class="single-tab-contents" [style]="'--tab-active:' + CurrentTab + ';margin-left:calc(calc(var(--tab-active) * var(--container-default-size)) * -1);'">
            <div [class]="responsiveState == 'desktop' ? 'single-tab-content container-default' : 'single-tab-content'">
                <div *ngIf="Servico.listaUnidadesAtendimento && Servico.listaUnidadesAtendimento.length > 0">
                    <p class="canais-atendimento-title">Endereço físico</p>
                    <app-seletor
                        [titulo]="'Onde deseja atendimento?'"
                        [Placeholder]="'Onde deseja atendimento?'"
                        [options]="MunicipiosAtendimento"
                        [Icon]="'icon-expand-down.png'"
                        [hidden]="MunicipiosAtendimento.length <= 1"
                        [OnChange]="OnSelectMunicipio.bind(this)"
                        [Variation]="'1'"
                    ></app-seletor>
                    <app-seletor
                        [titulo]="'Onde deseja atendimento?'"
                        [Placeholder]="'Onde deseja atendimento?'"
                        [options]="UnidadesAtendimento"
                        [Icon]="'icon-expand-down.png'"
                        [hidden]="UnidadesAtendimento.length <= 1"
                        [OnChange]="OnSelectUnidade.bind(this)"
                        [ClearEvent]="'clear-unidade'"
                        [Variation]="'1'"
                    ></app-seletor>
                    <div *ngIf="UnidadeSelecionada.unidadeAtendimento" [class]="loadedMap ? 'map-show' : 'map-hidden'">
                        <p class="canais-atendimento-subtitle">{{UnidadeSelecionada.unidadeAtendimento.nome}}</p>
                        <p class="canais-atendimento-text">{{formatEndereco(UnidadeSelecionada.unidadeAtendimento.enderecos[0])}}</p>
                        <p class="canais-atendimento-text">{{UnidadeSelecionada.unidadeAtendimento.contatos[0].descricao}}</p>
                        <p class="canais-atendimento-subtitle">Dia e horário de atendimento</p>
                        <p class="canais-atendimento-text" *ngFor="let horario of UnidadeSelecionada.listaHorariosAtendimento">{{formatDiaSemana(horario)}}</p>
                        <div id="map" style="height: 300px; width: 100%;overflow:hidden;"></div>
                    </div>
                </div>
            </div>
            <div [class]="responsiveState == 'desktop' ? 'single-tab-content container-default' : 'single-tab-content'">
                <div *ngIf="Servico.servicosOnline && Servico.servicosOnline.length > 0">
                    <p class="canais-atendimento-title">Endereço virtual</p>
                    <div *ngFor="let link of Servico.servicosOnline">
                        <p class="canais-atendimento-text">{{link.titulo}}</p>
                        <a [href]="link.url" target="_blank"><button class="btn-defaut">Acessar</button></a>
                    </div>
                </div>
            </div>
            <div [class]="responsiveState == 'desktop' ? 'single-tab-content container-default' : 'single-tab-content'">
                <div *ngIf="Servico.servicoAplicativoMovel && Servico.servicoAplicativoMovel.length > 0">
                    <p class="canais-atendimento-title">Galeria de aplicativos</p>
                    <div *ngFor="let link of Servico.servicoAplicativoMovel">
                        <p class="canais-atendimento-text">{{link.descricao}}</p>
                        <a [href]="link.link" target="_blank"><button class="btn-defaut">Baixar</button></a>
                    </div>
                </div>
            </div>
            <div [class]="responsiveState == 'desktop' ? 'single-tab-content container-default' : 'single-tab-content'">
                <div *ngIf="Servico.servicoEmail && Servico.servicoEmail.length > 0">
                    <p class="canais-atendimento-title">Endereço eletrônico</p>
                    <div *ngFor="let link of Servico.servicoEmail">
                        <p class="canais-atendimento-text">{{link.descricao}}</p>
                        <a [href]="'mailto:'+link.email" target="_blank"><button class="btn-defaut">Enviar email</button></a>
                    </div>
                </div>
            </div>
            <div [class]="responsiveState == 'desktop' ? 'single-tab-content container-default' : 'single-tab-content'">
                <div *ngIf="Servico.servicosTelefonicos && Servico.servicosTelefonicos.length > 0">
                    <p class="canais-atendimento-title">Endereço virtual</p>
                    <p class="canais-atendimento-text">Contate-nos:</p>
                    <div *ngFor="let link of Servico.servicosTelefonicos">
                        <p class="canais-atendimento-text">({{link.dddTel}}) {{link.numTel}}</p>
                        <a [href]="'tel:' + link.dddTel + link.numTel" target="_blank"><button class="btn-defaut">Ligar</button></a>
                    </div>
                </div>
            </div>
            <div [class]="responsiveState == 'desktop' ? 'single-tab-content container-default' : 'single-tab-content'">
                <div *ngIf="Servico.servicoMensagemInstantanea && Servico.servicoMensagemInstantanea.length > 0">
                    <p class="canais-atendimento-title">Aplicativos de mensagem</p>
                    <div *ngFor="let link of Servico.servicoMensagemInstantanea">
                        <p class="canais-atendimento-text">{{link.descricao}}</p>
                        <a [href]="GetLinkRedeSocial(link)" target="_blank"><button class="btn-defaut">{{GetDescricaoRedeSocial(link)}}</button></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<hr [hidden]="semCanaisAtendimento">