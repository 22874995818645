import { Component } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { AgendamentoService } from '../../../services/agendamento.service';
import { ActivatedRoute } from '@angular/router';
import { EventsService } from '../../../services/events.service';

@Component({
  selector: 'app-consulta-agendamento',
  templateUrl: './consulta-agendamento.component.html',
  styleUrls: ['./consulta-agendamento.component.css']
})
export class ConsultaAgendamentoComponent {

  constructor(
    private route: ActivatedRoute,
    private http: HttpClient,
    private eventsService: EventsService
  ) {}

  cpf: string = '';
  nascimento: string = '';

  agendamentos: any[] = [];

  listEmpty: boolean = false;

  isLoading: boolean = true;
  
  helper: AgendamentoService = new AgendamentoService(this.http);

  formErrorPopup: string = '';

  formResult: any = { confirmed: false, submitted: false };

  agendamentoConfirmarAutomatico: any = {};

  canCancelamentoHorarioMinimo: any = {};
  
  ngOnInit(){
    this.eventsService.emit('breadcrumb-update', '<a href="/agendamento">Agendamento</a> > <a href="/">Acompanhar agendamento</a>');
    this.route.params.subscribe(params => {
      this.cpf = params['protocolo'];
      this.nascimento = params['nascimento'];
      this.helper.consultaAgendamento({ cpf: this.cpf, nascimento: this.nascimento })
      .subscribe((result: any) => {
        this.isLoading = false;
        if(result.resultado){
          this.agendamentos = result.resultado;
          this.verificaHorarioMinimo(this.agendamentos);
          if(result.resultado.length == 0){
            this.listEmpty = true;
          }
        }else{
          this.listEmpty = true;
        }
      });
    });
  }

  formataStatus(status: string){
    switch(status.toUpperCase()){
      case 'NAO_COMPARECIDO':
        return 'NÃO COMPARECIDO';
      case 'A_CONFIRMAR':
        return 'PENDENTE';
      case 'CANCELADO':
        return 'CANCELADO';
      case 'CANCELADO_USUARIO':
        return 'CANCELADO';
      case 'NAO_CONFIRMADO':
          return 'NÃO CONFIRMADO';
      default:
        return status;
    }
  }

  toggleBox(selector: string){
    document.querySelector(selector)?.classList.toggle('active');
  }
  
  formatarDataHora(data: string){
    const dataInfo: any = {
      data: this.formatarData(data),
      horario: this.formatarHorario(data),
    };
    return `${dataInfo.data} ${dataInfo.horario}`;
  }

  formatarData(data: string){
    const dataParsed = new Date(data);
    const dataInfo: any = {
      year: dataParsed.getFullYear(),
      month: dataParsed.getMonth() + 1,
      day: dataParsed.getDate(),
    };
    if(dataInfo.day.toString().length == 1){
      dataInfo.day = '0' + dataInfo.day;
    }
    if(dataInfo.month.toString().length == 1){
      dataInfo.month = '0' + dataInfo.month;
    }
    return `${dataInfo.day}/${dataInfo.month}/${dataInfo.year}`;
  }

  formatarHorario(data: string){
    const dataParsed = new Date(data);
    const dataInfo: any = {
      hour: dataParsed.getHours(),
      minute: dataParsed.getMinutes(),
    };
    if(dataInfo.minute.toString().length === 1){
      dataInfo.minute = '0' + dataInfo.minute;
    }
    return `${dataInfo.hour}:${dataInfo.minute}`;
  }

  confirmarReagendamentoAutomatico(i: number){
    this.agendamentoConfirmarAutomatico = this.agendamentos[i];
    this.helper.getSingleAgendamento(this.agendamentoConfirmarAutomatico.protocolo)
    .subscribe((agendamento: any) => {
      this.formResult = { submitted: false, confirmed: false };
      this.formErrorPopup = '';
      this.helper.confirmarAgendamentoSemToken(agendamento).subscribe((result: any) => {
        this.formErrorPopup = this.getErrorMessage(result);
        if(this.formErrorPopup){
          this.formResult = { submitted: true, confirmed: false };
        }else{
          this.formResult = { submitted: true, confirmed: true };
        }
      });
    });
  }

  getErrorMessage(result: any){
    if(Array.isArray(result)){
      if(result[0] && result[0].error){
        return result[0].error;
      }
      return '';
    }
    return '';
  }

  closeAllPopups(){
    this.formResult = { confirmed: false, submitted: false };
    this.formErrorPopup = "";
  }

  verificaHorarioMinimo(agendamentos: any[]){
    agendamentos.forEach(agendamento => {
      if(agendamento.status == 'AGENDADO' || agendamento.status == 'RESERVADO'){
        this.canCancelamentoHorarioMinimo[agendamento.protocolo] = false;
        const unidadeID = agendamento.horarioAgenda.servicoUnidade.unidadeAtendimento.id;
        this.helper.getUnidadeHorarioCancelamento(unidadeID)
        .subscribe((tempoMinimo: any) => {
          if(tempoMinimo){
            let dataAgendamento = new Date(agendamento.horarioAgenda.horario);
            let today = new Date();
            const tempo = tempoMinimo.split(':');
            let horaMinima = new Date();
            horaMinima.setHours(today.getHours() + Number(tempo[0]));
            horaMinima.setMinutes(today.getMinutes() + Number(tempo[1]));
            this.canCancelamentoHorarioMinimo[agendamento.protocolo] = horaMinima < dataAgendamento;
          }
          return false;
        });
      }
    });
  }
}
