<div class="container">
    <div class="row">
        <div class="col">
            <div *ngIf="servico | async as agendamento; else loading">
                <app-form-agendamento [servico]="servico"></app-form-agendamento>
            </div>
              
            <ng-template #loading>
                <p class="center-p">Carregando...</p>
            </ng-template>

        </div>
    </div>
</div>