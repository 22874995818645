<app-modal *ngIf="errorMessage != ''">
    <div class="m-title">
      <img src="../../../../assets/image/icons/icon-close.png" alt="" srcset="">
    </div>
    <div class="m-body">
      <h3 class="text-center mb-3">Houve um erro ao atualizar os dados</h3>
      <p [innerHTML]="errorMessage.replaceAll('\n','<br>')"></p>
    </div>
    <div class="m-action">
      <a (click)="closeAllPopups()" class="btn-form-submit">Voltar</a>
    </div>
</app-modal>

<app-modal *ngIf="updatedForm">
    <div class="m-title">
      <img src="../../../../assets/image/icons/icon-check.png" alt="" srcset="">
    </div>
    <div class="m-body">
      <h3 class="text-center mb-3">Perfil atualizado</h3>
      <p>Seu perfil foi atualizado</p>
    </div>
    <div class="m-action">
      <a (click)="closeAllPopups()" class="btn-form-submit">Voltar</a>
    </div>
</app-modal>


<section class="header-section">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="info">
            <div class="info-title">
              <a (click)="navigate('back')" class="cursor-pointer">
                <img src="../../../../assets/image/icons/icon-return.png" alt="Voltar" >
              </a>
              <h4>Dados pessoais</h4>
            </div>
            <a (click)="navigate('close')" class="cursor-pointer">
              <img src="../../../../assets/image/icons/icon-close.png" alt="Fechar" >
            </a>
          </div>
        </div>
      </div>
    </div>
</section>
<section [hidden]="userLogged">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <h5>Faça login para continuar</h5>
            </div>
        </div>
    </div>
</section>
<section class="form-agendamento" [hidden]="!userLogged">
    <div class="container">
      <div class="row">
        <div class="col-12">
  
          <h6>Atualize os dados da sua conta no Portal PE Cidadão.</h6>
  
          <form class="row  needs-validation" novalidate>

            <div class="col-12">
                <fieldset class="reset {{form.message['cpf'] ? 'has-error' : ''}}" for="cpf">
                  <legend class="reset">CPF*</legend>
                  <input type="search" class="form-control" id="cpf" name="cpf" required [(ngModel)]="form.fields['cpf']" #ctrl="ngModel" (keyup)="mask()">
                </fieldset>
                <div class="error-label" *ngIf="form.message['cpf']">
                  {{form.message['cpf']}}
                </div>
            </div>
  
            <div class="col-12">
              <fieldset class="reset {{form.message['nomecompleto'] ? 'has-error' : ''}}" for="nome-completo">
                <legend class="reset">Nome completo*</legend>
                <input type="search" class="form-control" id="nome-completo" name="nome" required [(ngModel)]="form.fields['nomecompleto']" #ctrl="ngModel" (keyup)="mask()">
              </fieldset>
              <div class="error-label" *ngIf="form.message['nomecompleto']">
                {{form.message['nomecompleto']}}
              </div>
            </div>
  
            
  
            <div class="col-12">
              <fieldset class="reset {{form.message['nascimento'] ? 'has-error' : ''}}" for="nascimento">
                <legend class="reset">Data de nascimento*</legend>
                <input type="search" class="form-control" id="nascimento" name="nascimento" required [(ngModel)]="form.fields['nascimento']" #ctrl="ngModel" (keyup)="mask()">
              </fieldset>
              <div class="error-label" *ngIf="form.message['nascimento']">
                {{form.message['nascimento']}}
              </div>
            </div>
  
            <div class="col-12">
              <fieldset class="reset {{form.message['email'] ? 'has-error' : ''}}" for="email">
                <legend class="reset">E-mail*</legend>
                <input type="search" class="form-control" id="email" name="email" required [(ngModel)]="form.fields['email']" #ctrl="ngModel" (keyup)="mask()">
              </fieldset>
              <div class="error-label" *ngIf="form.message['email']">
                {{form.message['email']}}
              </div>
            </div>
  
            <!-- <div class="col-12">
              <div class="policies">
                <div class="form-check form-switch">
                  <input class="form-check-input" type="checkbox" name="autorizacao" id="autorizacao" checked="checked" [(ngModel)]="form.fields['autorizacao']" #ctrl="ngModel">
                  <label class="form-check-label label-autorizacao" for="autorizacao">Autorizo o envio de emails com nossas atualizações e serviços.</label>
                </div>
              </div>
            </div> -->
          
            <div class="col-12">
              <hr>
              <div class="buttons">
                <button class="btn btn-form-submit" (click)="submit()">Atualizar</button>
              </div>
            </div>
  
          </form>
  
        </div>
      </div>
    </div>
</section>