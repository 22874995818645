import { Component } from '@angular/core';
import { ResponsiveService } from '../../services/responsive.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent {

  responsiveState: string = '';

  constructor(
    private responsiveService: ResponsiveService,
  ){}

  ngOnInit() {
    this.initResponsiveState();
  }

  initResponsiveState(){
    this.responsiveService.onChange((state: string) => {
      if(state === 'tablet'){
        state = 'desktop';
      }
      this.responsiveState = state;
    });
  }
}
