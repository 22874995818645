import { AgendamentoService } from '../../services/agendamento.service';
import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {HttpClient} from '@angular/common/http';

@Component({
  selector: 'app-busca-geral',
  templateUrl: './busca-geral.component.html',
  styleUrls: ['./busca-geral.component.css']
})
export class BuscaGeralComponent {
  searchQuery: string = '';

  isLoading: boolean = true;
  
  results: any[] = [];

  constructor(
    private route: ActivatedRoute,
    private http: HttpClient
  ) {}

  helper: AgendamentoService = new AgendamentoService(this.http);

  ngOnInit(){
    this.route.params.subscribe(params => {
      this.searchQuery = params['query'];
      this.helper.getBuscaGeral(this.searchQuery).subscribe((results: any) => {
        this.isLoading = false;
        if(Array.isArray(results)){
          this.results = results;
        }else{
          this.results = [];
        }
      });
    });
  }

  OnToggleBox(id: number){
    document.querySelector('#box-' + id)?.classList.toggle('active');
  }

  navigate(route: string){
    if (window.history.length > 1) {
      window.history.back();
    } else {
      window.location.href = '/';
    }
  }

  removeRichText(text: string){
    return text.replace(/<\/?[^>]+(>|$)/g, '');
  }

}
