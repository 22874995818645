import { Component, Input, Output, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import { FormService } from '../../../services/form.service';
import { TipoAgendamento, AgendamentoParametroServico, AgendamentoParametroOrgao } from '../../../models/model';
import { AgendamentoService } from '../../../services/agendamento.service';
import {HttpClient} from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { NgbDate, NgbDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { EventsService } from '../../../services/events.service';
import { ResponsiveService } from '../../../services/responsive.service';

@Component({
  selector: 'app-selecao-unidade',
  templateUrl: './selecao-unidade.component.html',
  styleUrls: ['./selecao-unidade.component.css']
})
export class SelecaoUnidadeComponent {
  @Input() HideTitle: boolean = false;
  @Input() OnSubmit: Function = (fields?: any) => {};
  @Input() OnNavigate: Function = (fields?: any) => {};
  @Input() OnCaptchaSolve: Function = (token?: any) => {};
  @Input() FormName: string = '';
  @Input() FormData: { [key: string]: FormService } = {};
  @Input() ListaMunicipio: string[] = [];
  @Input() Servico = new Observable<TipoAgendamento | undefined>();
  @Input() variation: string = "default";
  @Input() agendamento: any = {};

  dataSelecionada: Date = new Date();
  dataInicial: Date = new Date('2000-01-01');
  dataLimite: Date = new Date('2000-02-02');

  helper: AgendamentoService = new AgendamentoService(this.http);

  constructor(
    private http: HttpClient,
    private localeService: BsLocaleService,
    private eventsService: EventsService,
    private responsiveService: ResponsiveService,
  ) {
    this.localeService.use('pt-br');
  }

  captchaSolved: boolean = false;

  form: FormService = new FormService();

  ListaUnidade: any[] = [];

  UnidadeAtual: any = {};

  HorariosDisponiveis: any[] = [];

  semHorariosDisponiveis: boolean = false;

  HorarioSelecionado: string = '';

  EnableHorarioPagination: boolean = false;

  servico: TipoAgendamento | undefined;

  parametroServicoUnidade: any = { diasAutenticadoVerAgenda: 99, diasNaoAutenticadoVerAgenda: 99 };

  showDatePicker: boolean = false;

  responsiveState: string = '';

  minPickerDate: any = {
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
    day: new Date().getDate()
  };

  excludedDates: Date[] = [];

  isDisabled: any = (date: NgbDate, current: {month: number}) => {
    const thisDate = new Date(date.year, date.month-1, date.day);
    var dateLimitDays = new Date();
    dateLimitDays.setDate(dateLimitDays.getDate() + this.parametroServicoUnidade.diasNaoAutenticadoVerAgenda - 1);
    if(thisDate >= dateLimitDays){
      return true;
    }
    if(thisDate < this.dataInicial || thisDate > this.dataLimite){
      return true;
    }
    for(let i = 0; i < this.excludedDates.length; i++) {
      if(thisDate.getTime() === this.excludedDates[i].getTime()){
        return true;
      }
    }
    return false;
  };

  OnDatePickerFocus(){
    this.showDatePicker = true;
    const datefield = document.querySelector('#data-atendimento');
    if(datefield){
      const offsetY = (datefield as any).offsetTop;
      window.scrollTo(0, offsetY);
    }
  }

  OnDatePickerBlur(){}

  ngOnInit(){
    this.Servico.subscribe((value) => this.servico = value);
    if(this.agendamento.id){
      this.servico = this.agendamento;
    }
    this.form.field('municipio','text','Para continuar inclua esta informação, ela é obrigatória.');
    this.form.field('unidade','text','Para continuar inclua esta informação, ela é obrigatória.');
    this.form.field('unidadenome','','');
    this.form.field('dataatendimento','date','Para continuar inclua esta informação, ela é obrigatória.');
    this.form.field('horario','text','Para continuar inclua esta informação, ela é obrigatória.');
    this.trackOutsideClicks();
    this.eventsService.when('section2-clear', () => {
      this.ResetForm();
    });
    //this.initResponsiveState();
  }

  initResponsiveState(){
    this.responsiveService.onChange((state: string) => {
      if(state === 'tablet'){
        state = 'desktop';
      }
      this.responsiveState = state;
    });
  }

  OnMunicipioSelect(municipio: string){
    this.eventsService.emit('section2-unidade', false);
    this.eventsService.emit('section2-horario', false);
    this.HorariosDisponiveis = [];
    this.semHorariosDisponiveis = false;
    this.HorarioSelecionado = '';
    this.dataSelecionada = new Date();
    this.dataInicial = new Date('2000-01-01');
    this.dataLimite = new Date('2000-02-02');
    this.EnableHorarioPagination = false;
    this.form.fields['unidade'] = '';
    this.form.fields['horario'] = '';
    this.form.fields['dataatendimento'] = '';
    this.form.fields['municipio'] = municipio;
    if(this.servico){
      this.eventsService.emit('loader-toggle', true);
      this.helper
        .getAgendamentoBuscaUnidades(`${this.servico.id}`,this.form.fields['municipio'])
        .subscribe((value: any) => {
          this.eventsService.emit('loader-toggle', false);
          this.ListaUnidade = value.sort((a: any, b: any) => a.nome.localeCompare(b.nome));
        });
    }
  }

  OnUnidadeSelect(unidade: string){
    this.eventsService.emit('section2-horario', false);
    this.HorariosDisponiveis = [];
    this.semHorariosDisponiveis = false;
    this.HorarioSelecionado = '';
    this.dataSelecionada = new Date();
    this.dataInicial = new Date('2000-01-01');
    this.dataLimite = new Date('2000-02-02');
    this.EnableHorarioPagination = false;
    this.form.fields['horario'] = '';
    this.form.fields['dataatendimento'] = '';
    this.form.fields['unidade'] = unidade;
    this.ListaUnidade.forEach((unidade: any) => {
      if(unidade.id == this.form.fields['unidade']){
        this.UnidadeAtual = unidade;
        this.form.fields['unidadenome'] = unidade.nome;
      }
    });
    if(this.servico){
      this.eventsService.emit('loader-toggle', true);
      this.helper
        .getAgendamentoBuscaRestricoesDataUnidades(`${this.servico.id}`,this.form.fields['unidade'])
        .subscribe((value: any) => {
          this.eventsService.emit('loader-toggle', false);
          this.dataInicial = new Date(value.dataInicial);
          this.dataLimite = new Date(value.dataFinal);
          this.dataLimite.setDate(this.dataLimite.getDate() + 1);
          this.excludedDates = value.datas.map((date: any) => new Date(date.split('-')));
          this.form.fields['dataatendimento'] = '';
        });
      this.helper.getParametroServicoUnidade(this.UnidadeAtual.orgao.id, this.servico.id, this.UnidadeAtual.id)
        .subscribe((result: any) => {
          if(result){
            this.parametroServicoUnidade = result;
          }
        });
    }
  }

  OnDataSelect(){
    this.eventsService.emit('section2-horario', false);
    this.EnableHorarioPagination = false;
    this.HorariosDisponiveis = [];
    this.semHorariosDisponiveis = false;
    this.HorarioSelecionado = '';
    this.form.fields['horario'] = '';
    this.HorariosDisponiveis = [];
    setTimeout(() => {
      if(this.servico && this.form.fields['dataatendimento'] != ''){
        const data = (new Date(this.form.fields['dataatendimento'])).toLocaleDateString().split('/').reverse().join('-');
        this.eventsService.emit('loader-toggle', true);
        this.helper
          .getAgendamentoBuscaHorariosUnidade(`${this.servico.id}`, this.form.fields['unidade'], data, 0)
          .subscribe((value: any) => {
            this.eventsService.emit('loader-toggle', false);
            this.HorariosDisponiveis = value.resultado;
            if(this.HorariosDisponiveis.length < value.totalResultados){
              this.EnableHorarioPagination = true;
            }else{
              this.EnableHorarioPagination = false;
            }
            this.semHorariosDisponiveis = this.HorariosDisponiveis.length == 0;
          });
      }
    }, 1000);
  }

  onDateSelectorNavigate(event: any) {
    if (event.next.year < this.dataInicial.getFullYear() || event.next.year > this.dataLimite.getFullYear()) {
      event.preventDefault();
    } else if (event.next.year === this.dataInicial.getFullYear() && event.next.month < this.dataInicial.getMonth() + 1) { 
      event.preventDefault();
    } else if (event.next.year === this.dataLimite.getFullYear() && event.next.month > this.dataLimite.getMonth() + 1) { 
      event.preventDefault();
    }
  }

  GetHorariosMore(page: number){
    if(this.servico && this.form.fields['dataatendimento'] != ''){
      const data = (new Date(this.form.fields['dataatendimento'])).toLocaleDateString().split('/').reverse().join('-');
      this.helper
        .getAgendamentoBuscaHorariosUnidade(`${this.servico.id}`, this.form.fields['unidade'], data, page)
        .subscribe((value: any) => {
          this.HorariosDisponiveis = this.HorariosDisponiveis.concat(value.resultado);
          if(this.HorariosDisponiveis.length < value.totalResultados){
            this.EnableHorarioPagination = true;
          }else{
            this.EnableHorarioPagination = false;
          }
        });
    }
  }

  OnHorarioSelect(horario: any){
    this.HorarioSelecionado = horario.horario;
    this.form.fields['horario'] = horario;
  }

  openDataPopup(){
    const dataInput = document.querySelector('#data-atendimento') as any;
    if(dataInput){
      dataInput.click();
    }  
  }

  navigate(name: string){
    if(name == 'back'){
      this.eventsService.emit('section2-clear', false);
      this.eventsService.emit('section2-municipio', false);
      this.eventsService.emit('section2-unidade', false);
      this.eventsService.emit('section2-horario', false);
    }
    this.OnNavigate(name);
  }

  ResetForm(){
    this.ListaUnidade = [];
    this.HorariosDisponiveis = [];
    this.semHorariosDisponiveis = false;
    this.HorarioSelecionado = '';
    this.dataSelecionada = new Date();
    this.dataInicial = new Date('2000-01-01');
    this.dataLimite = new Date('2000-02-02');
    this.form.fields['municipio'] = "";
    this.form.fields['unidade'] = "";
    this.form.fields['unidadenome'] = "";
    this.form.fields['dataatendimento'] = "";
    this.form.fields['horario'] = "";
    this.form.message['municipio'] = "";
    this.form.message['unidade'] = "";
    this.form.message['unidadenome'] = "";
    this.form.message['dataatendimento'] = "";
    this.form.message['horario'] = "";
  }

  submit(){
    if(this.form.validate()){
      this.OnSubmit(this.form, this.FormName);
    }
  }

  formataHorario(horario: string){
    return new Date(horario).toLocaleTimeString('pt-BR', { hour: '2-digit', minute: '2-digit' });
  }

  whenCaptchaSolved(token: any){
    this.OnCaptchaSolve(token);
  }

  formatarData(data: string){
    const dataParsed = new Date(data);
    const dataInfo: any = {
      year: dataParsed.getFullYear(),
      month: dataParsed.getMonth() + 1,
      day: dataParsed.getDate(),
    };
    if(dataInfo.day.toString().length == 1){
      dataInfo.day = '0' + dataInfo.day;
    }
    if(dataInfo.month.toString().length == 1){
      dataInfo.month = '0' + dataInfo.month;
    }
    if(!dataInfo.day){
      return '';
    }
    return `${dataInfo.day}/${dataInfo.month}/${dataInfo.year}`;
  }

  InputFocus(query: string){
    (document.querySelector(query) as any)?.click();
  }

  trackOutsideClicks(){
    window.addEventListener('mousedown', (event: any) => {
      if(!document.querySelector('.ngb-dtpicker')?.contains(event.target) && this.showDatePicker){
        setTimeout(() => {
          this.showDatePicker = false;
        }, 100);
      }
    });
  }

  onDateSelect(date: NgbDate){
    this.form.fields['dataatendimento'] = `${date.year}/${date.month}/${date.day}`;
    this.showDatePicker = false;
    this.OnDataSelect();
  }
}
