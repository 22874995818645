import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CacheService {

  constructor() { }

  defaultCacheTime: number = 7200000;

  getCachedObserverServicosDestaque(callback: any){
    const key = 'servicos-destaque';
        return {
          subscribe: (cb: any) => {
            const creationTimestamp = localStorage.getItem(`angcached_${key}_creation`);
            if(creationTimestamp){
              if(new Date().getTime() - Number(creationTimestamp) > this.defaultCacheTime){
                callback().subscribe((data: any) => {
                  if(data){
                    var rdata = [];
                    for(let i = 0; i < data.length;i++){
                      rdata.push({ b64Imagem: data[i].b64Imagem, servico: data[i].servico });
                    }
                    localStorage.setItem(`angcached_${key}`, JSON.stringify(rdata));
                    localStorage.setItem(`angcached_${key}_creation`, new Date().getTime() + '');
                    return cb(rdata);
                  }else{
                    return cb(JSON.parse(localStorage.getItem(`angcached_${key}`) || ""));
                  }
                });
              }else{
                return cb(JSON.parse(localStorage.getItem(`angcached_${key}`) || ""));
              }
            }else{
              callback().subscribe((data: any) => {
                if(data){
                  var rdata = [];
                  for(let i = 0; i < data.length;i++){
                    rdata.push({ b64Imagem: data[i].b64Imagem, servico: data[i].servico });
                  }
                  localStorage.setItem(`angcached_${key}`, JSON.stringify(rdata));
                  localStorage.setItem(`angcached_${key}_creation`, new Date().getTime() + '');
                  return cb(rdata);
                }
              });
            }
          }
        };
  }

  getCachedFunction(key: string, callback: any){
    const creationTimestamp = localStorage.getItem(`angcached_${key}_creation`);
    if(creationTimestamp){
      if(new Date().getTime() - Number(creationTimestamp) < this.defaultCacheTime){
        const data = callback();
        localStorage.setItem(`angcached_${key}`, JSON.stringify(data));
        localStorage.setItem(`angcached_${key}_creation`, new Date().getTime() + '');
        return data;
      }else{
        return localStorage.getItem(`angcached_${key}`);
      }
    }else{
      const data = callback();
      localStorage.setItem(`angcached_${key}`, JSON.stringify(data));
      localStorage.setItem(`angcached_${key}_creation`, new Date().getTime() + '');
      return data;
    }
  }

  getCachedObserver(key: string, callback: any){
    return {
      subscribe: (cb: any) => {
        const creationTimestamp = localStorage.getItem(`angcached_${key}_creation`);
        if(creationTimestamp){
          if(new Date().getTime() - Number(creationTimestamp) > this.defaultCacheTime){
            callback().subscribe((data: any) => {
              if(data){
                localStorage.setItem(`angcached_${key}`, JSON.stringify(data));
                localStorage.setItem(`angcached_${key}_creation`, new Date().getTime() + '');
                return cb(data);
              }else{
                return cb(JSON.parse(localStorage.getItem(`angcached_${key}`) || ""));
              }
            });
          }else{
            return cb(JSON.parse(localStorage.getItem(`angcached_${key}`) || ""));
          }
        }else{
          callback().subscribe((data: any) => {
            if(data){
              localStorage.setItem(`angcached_${key}`, JSON.stringify(data));
              localStorage.setItem(`angcached_${key}_creation`, new Date().getTime() + '');
              return cb(data);
            }
          });
        }
      }
    };
  }
}
