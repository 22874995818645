import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ContatoService {

  constructor(private http: HttpClient) {}

  contato(form: any){
    return this.http.post(`${environment.baseAPI}${encodeURI(environment.contatoForm)}`, form, { responseType: 'text' })
    .pipe(
      catchError((error: HttpErrorResponse) => {
        return of(error);
      })
    );
  }
}
