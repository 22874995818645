<section class="header-section">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="info">
            <div class="info-title flex">
              <a (click)="navigate('back')" class="cursor-pointer">
                <img src="../../../../assets/image/icons/icon-return.png" alt="Voltar" >
              </a>
              <h4>Reagendar local de atendimento</h4>
            </div>
            <a (click)="navigate('close')" class="cursor-pointer">
              <img src="../../../../assets/image/icons/icon-close.png" alt="Fechar" >
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
  
  <div>
    <app-modal *ngIf="result.submitted && result.confirmed">
      <div class="m-title">
        <img src="../../../../assets/image/icons/icon-check.png" alt="" srcset="">
      </div>
      <div class="m-body">
        <h3 class="text-center mb-3">Reagendamento de serviço confirmado</h3>
        <p>Enviamos para o seu e-mail que você cadastrou anteriormente o número do seu processo. Você pode usar esse número para consultar, cancelar ou reagendar o serviço.</p>
        <p>A lista de documentos que devem ser apresentados no balcão de atendimento também foi enviada.</p>
      </div>
      <div class="m-action" *ngIf="agendamento.horarioAgenda">
        <a href="/agendamento/documentos/{{agendamentoServico.id}}" class="btn-form-submit">Consultar instruções de atendimento</a>
      </div>
    </app-modal>
  
    <app-modal *ngIf="result.submitted && !result.confirmed">
      <div class="m-title">
        <img src="../../../../assets/image/icons/icon-check.png" alt="" srcset="">
      </div>
      <div class="m-body">
        <h3 class="text-center mb-3">Houve um erro no seu reagendamento</h3>
        <p>Aguarde alguns minutos.</p>
        <p>Volte a tela de reagendamento e realize novamente o processo.</p>
      </div>
      <div class="m-action">
        <a (click)="closeAllPopups()" class="btn-form-submit">Voltar</a>
      </div>
    </app-modal>

    <app-modal *ngIf="formErrorPopup != ''">
      <div class="m-title">
        <img src="../../../../assets/image/icons/icon-close.png" alt="" srcset="">
      </div>
      <div class="m-body">
        <h3 class="text-center mb-3">Houve um erro no seu agendamento</h3>
        <p>{{formErrorPopup}}</p>
      </div>
      <div class="m-action">
        <a (click)="closeAllPopups()" class="btn-form-submit">Voltar</a>
      </div>
    </app-modal>
  </div>

  <p *ngIf="isLoading" class="center-p">Carregando...</p>

  <p *ngIf="!isLoading && listEmpty" class="center-p">Houve um erro ao obter os dados</p>

  <app-steps
    [current]="currentForm"
    [available]="allForms"
  ></app-steps>

  <app-selecao-unidade
    *ngIf="agendamento.horarioAgenda"
    [OnNavigate]="navigate.bind(this)"
    [OnSubmit]="submit.bind(this)"
    [FormName]="'agendamento'"
    [FormData]="formData"
    [ListaMunicipio]="listaMunicipio"
    [agendamento]="agendamentoServico"
    [variation]="'reagendamento'"
    [OnCaptchaSolve]="OnCaptchaSolve.bind(this)"
    [HideTitle]="true"
    [hidden]="currentForm != 'form1'"
  ></app-selecao-unidade>

  <div class="container" [hidden]="currentForm != 'form2'">
    <div class="row">
        <div class="col">
            <div class="box" *ngIf="!isLoading && !listEmpty">
                <div class="title">Deseja reagendar o seu atendimento?</div>
                <p class="subtitle">Ao reagendar o agendamento, sua reserva atual será cancelada e um novo horário será marcado conforme a disponibilidade.</p>
                <div></div>
                <p class="subtext subtext-title">Dados de atendimento</p>
                <p class="subtext">Município: {{(formData['agendamento'].fields || {})['municipio']}}</p>
                <p class="subtext">Local de atendimento: {{(formData['agendamento'].fields || {})['unidadenome']}}</p>
                <p class="subtext">Data: {{formatarData($any(formData['agendamento'].fields['horario']).horario)}}</p>
                <p class="subtext">Horário: {{formatarHorario($any(formData['agendamento'].fields['horario']).horario)}}</p>
                <hr>
                <div class="protocolo-title">Número do protocolo:</div>
                <div class="protocolo">{{protocolo.toUpperCase()}}</div>
            </div>
        </div>
    </div>
    <div class="col-12">
        <hr>
        <div class="buttons flex-end">
            <a class="btn btn-form-return"  (click)="navigate('back')">Voltar</a>
            <button class="btn btn-form-submit btn-secondary" (click)="confirm()">Reagendar atendimento</button>
        </div>
    </div>
</div>