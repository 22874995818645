import { Component } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { ServicosService } from 'src/app/services/servicos.service';
import { EventsService } from '../../../services/events.service';

@Component({
  selector: 'app-tema',
  templateUrl: './tema.component.html',
  styleUrls: ['./tema.component.css']
})
export class TemaComponent {

  constructor(
    private route: ActivatedRoute,
    private http: HttpClient,
    private eventsService: EventsService
  ) {}

  helper : ServicosService = new ServicosService(this.http);

  temaTitulo : string = "";

  temaID : string = "";

  servicos : any = [];

  ngOnInit(){
    this.route.params.subscribe(params => {
      this.temaID = (params['id'] || '').replace('tema-', '');
      this.helper.getServicosPorTema(this.temaID).subscribe((result: any) => {
        this.servicos = result.sort((a: any, b: any) => a.nomeServico.localeCompare(b.nomeServico));
      });
      this.helper.getTemas().subscribe((result: any) => {
        if(Array.isArray(result)){
          result.forEach((tema: any) => {
            if(tema.id == this.temaID){
              this.temaTitulo = tema.titulo;
              this.eventsService.emit('breadcrumb-update', '<a href="/servicos">Serviços</a> > ' +  '<a>' + this.temaTitulo + '</a>');
            }
          });
        }
      });
    });
  }

  back(){
    window.location.href = '/servicos';
  }

}
