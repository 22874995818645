<div class="menu-default" *ngIf="CanShowComponent()">
    <div class="row">
        <div class="col">
          <nav class="navbar navbar-expand">
            <ul class="{{responsiveState == 'desktop' ? 'container' : 'navbar-nav'}}">
              <li [class]="rotaAtiva.path === '' ? 'active nav-item' : 'nav-item'">
                <a [class]="rotaAtiva.path === '' ? 'active nav-link' : 'nav-link'" href="/">Início</a>
              </li>
              <li [class]="rotaAtiva.path === 'servicos' ? 'active nav-item' : 'nav-item'">
                <a [class]="rotaAtiva.path=== 'servicos' ? 'active nav-link' : 'nav-link'" href="/servicos">Serviços</a>
              </li>
              <li [class]="rotaAtiva.path === 'fale-conosco' ? 'active nav-item' : 'nav-item'">
                <a [class]="rotaAtiva.path === 'fale-conosco' ? 'active nav-link' : 'nav-link'" href="/fale-conosco">Fale conosco</a>
              </li>
            </ul>
          </nav>
        </div>
    </div>
</div>