import { Component, Input, Output, ViewChild } from '@angular/core';
import { FormService } from '../../services/form.service';
import { Observable, Subject } from 'rxjs';
import { UserService } from '../../services/user.service';
import {HttpClient} from '@angular/common/http';

@Component({
  selector: 'app-perfil',
  templateUrl: './perfil.component.html',
  styleUrls: ['./perfil.component.css']
})
export class PerfilComponent {

  form: FormService = new FormService();

  formResult: any = {};

  updatedForm: boolean = false;

  constructor(private http: HttpClient) {}
  
  helper: UserService = new UserService(this.http);

  formSuccess: boolean = false;

  errorMessage: string = '';

  userLogged: boolean = false;

  ngOnInit(){
    this.form.field('nomecompleto','text','Para continuar inclua esta informação, ela é obrigatória.');
    this.form.field('cpf','cpf','Insira um CPF válido.','cpf');
    this.form.field('nascimento','text','Para continuar inclua esta informação, ela é obrigatória.','birth');
    this.form.field('email','text','Para continuar inclua esta informação, ela é obrigatória.');
    this.form.field('autorizacao','','');

    this.helper.getUserInfo()
    .subscribe((result: any) => {
      if(result.idKeycloak){
        this.formResult = result;
        this.userLogged = true;
        this.form.fields['nomecompleto'] = result.nome;
        this.form.fields['cpf'] = result.cpf;
        this.form.fields['nascimento'] = result.dataNascimento;
        this.form.fields['email'] = result.email;
      }else{
        this.userLogged = false;
      }
    });
  }

  mask(){
    this.form.updateMasks();
  }

  navigate(name: string){
    window.location.href = '/';
  }

  submit(){
    if(this.form.validate()){
      // this.formResult['nome'] = this.form.fields['nomecompleto'];
      // this.formResult['cpf'] = this.form.fields['cpf'];
      // this.formResult['dataNascimento'] = this.form.fields['nascimento'];
      // this.formResult['email'] = this.form.fields['email'];
      this.helper.updateUserInfo(this.formResult)
      .subscribe((result: any) => {
        this.updatedForm = true;
        this.formResult = result;
        this.errorMessage = this.getErrorMessage(result);
        if(!this.errorMessage){
          // this.formResult = result;
          // this.form.fields['nomecompleto'] = result.nome;
          // this.form.fields['cpf'] = result.cpf;
          // this.form.fields['nascimento'] = result.dataNascimento;
          // this.form.fields['email'] = result.email;
        }
      });
    }
  }

  getErrorMessage(result: any){
    if(Array.isArray(result)){
      if(result[0] && result[0].error){
        return result[0].error;
      }
      return '';
    }
    return '';
  }

  closeAllPopups(){
    this.errorMessage = '';
    this.formSuccess = false;
    this.updatedForm = false;
  }

  reenviarEmailConfirmacao(){

  }
}
