import { Component, Input, Output, ViewChild } from '@angular/core';
import { FormService } from '../../services/form.service';
import { Observable, Subject } from 'rxjs';
import { UserService } from '../../services/user.service';
import {HttpClient} from '@angular/common/http';

@Component({
  selector: 'app-cadastro',
  templateUrl: './cadastro.component.html',
  styleUrls: ['./cadastro.component.css']
})
export class CadastroComponent {
  acceptedTerms: boolean = false;

  termsPopupOpen: boolean = true;

  captcha: string = '';

  form: FormService = new FormService();

  passForm: FormService = new FormService();

  formResult: any = {};

  constructor(private http: HttpClient) {}
  
  helper: UserService = new UserService(this.http);

  currentForm: string = 'form1';

  formSuccess: boolean = false;

  errorMessage: string = '';

  ngOnInit(){
    this.form.field('nomecompleto','text','Para continuar inclua esta informação, ela é obrigatória.');
    this.form.field('cpf','cpf','Insira um CPF válido.','cpf');
    this.form.field('nascimento','text','Para continuar inclua esta informação, ela é obrigatória.','birth');
    this.form.field('email','text','Para continuar inclua esta informação, ela é obrigatória.');
    this.form.field('autorizacao','','');
    this.passForm.field('senha','text','Para continuar inclua esta informação, ela é obrigatória.');
    this.passForm.field('confirmarsenha','text','Para continuar inclua esta informação, ela é obrigatória.');
  }

  onCaptchaSolve(token: any){
    this.captcha = token;
  }

  mask(){
    this.form.updateMasks();
  }

  passwordValidation(){
    const senha = this.passForm.fields['senha'];
    const confirmarsenha = this.passForm.fields['confirmarsenha'];

    if(senha !== confirmarsenha){
      this.passForm.message['confirmarsenha'] = 'As senhas precisam ser iguais';
      return false;
    }

    if(senha.length < 8){
      this.passForm.message['senha'] = 'Ter ao menos 8 caracteres';
      return false;
    }

    if(!/[A-Z]/.test(senha)){
      this.passForm.message['senha'] = 'Ter ao menos uma letra maiúscula';
      return false;
    }

    if(!/[!@#$%^&*(),.?":{}|<>]/.test(senha)){
      this.passForm.message['senha'] = 'Ter ao menos um símbolo (ex.: @?#$%)';
      return false;
    }

    if(!/\d/.test(senha)){
      this.passForm.message['senha'] = 'Ter ao menos um número';
      return false;
    }

    return true;
  }

  navigate(name: string){
    if(name == 'back' && this.currentForm == 'form2'){
      this.currentForm = 'form1';
    }else{
      window.location.href = '/';
    }
  }

  submit(){
    if(this.currentForm == 'form2' && this.passForm.validate()){
      if(this.passwordValidation() && this.captcha){
        const data = {
          "nome": this.form.fields['nomecompleto'],
          /*"nomeMae": " ",*/
          "cpf": this.form.fields['cpf'].replace(/[^\d]/g, ''),
          "dataNascimento": this.form.fields['nascimento'],
          "email": this.form.fields['email'],
          /*"rg": "123123",*/
          /*"orgaoExpedidor": "teste",*/
          "senha": this.passForm.fields['senha'],
          "confirmacao_senha": this.passForm.fields['confirmarsenha'],
          "captchaResponse": this.captcha,
          "versao": 1,
          /*"ufRg": "PE"*/
        };
        this.helper.cadastro(this.captcha, data).subscribe((result: any) => {
          this.formResult = result;
          this.errorMessage = this.getErrorMessage(result);
          if(!this.errorMessage){
            this.formSuccess = true;
          }
        });
      }
    }
    if(this.currentForm == 'form1' && this.form.validate()){
      this.currentForm = 'form2';
    }
  }

  getErrorMessage(result: any){
    if(Array.isArray(result)){
      if(result[0] && result[0].error){
        return result[0].error;
      }
      return '';
    }
    return '';
  }

  closeAllPopups(){
    if(this.acceptedTerms){
      this.termsPopupOpen = false;
    }
    this.errorMessage = '';
    this.formSuccess = false;
  }

  toggleTerms(event: any){
    if(event.target.checked){
      this.acceptedTerms = true;
    }else{
      this.acceptedTerms = false;
    }
  }

  getFormLevel(){
    return ({
      'form1':50,
      'form2':100,
    })[this.currentForm];
  }
}
