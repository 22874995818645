import { Component } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../environments/environment';
import { UserService } from './services/user.service';
import { ActivatedRoute, Router, Route } from '@angular/router';
import { Location } from '@angular/common';
import { RouteService } from './services/route.service';
import { EventsService } from './services/events.service';
import { ResponsiveService } from './services/responsive.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  
  constructor(
    private http: HttpClient,
    public route: ActivatedRoute,
    private router: Router,
    private Location:Location,
    private eventsService: EventsService,
    private responsiveService: ResponsiveService,
  ) { }

  routeService: RouteService = new RouteService(this.Location, this.router);

  routePath: string = '';

  currentRoute: any = {};

  menuType: string = '';

  menuOpened: boolean = false;

  isOnTop: boolean = true;

  responsiveState: string = '';

  userHelper: UserService = new UserService(this.http);

  consentCookies: boolean = true;

  wordpressOptions: any = {};

  wordpressContent: string = '';

  rodapeItems: any = [];

  highContrastActive: boolean = false;

  fontScale: number = 0;

  userPopupOpen: boolean = false;

  userInfo: any = {
    logged: false,
    loading: true,
    nome: '',
    cpf: '',
    nascimento: '',
    email: '',
  };

  ngOnInit(){
    this.routePath = this.Location.path();
    this.currentRoute = this.routeService.getCurrentRoute();
    this.menuType = this.routeService.GetMenuType();
    this.consentCookies = localStorage.getItem('pecidadao-consent-cookies') === 'true';
    this.fontScale = parseInt(localStorage.getItem('pecidadao-font-scale') || '0');
    this.highContrastActive = localStorage.getItem('pecidadao-high-contrast') === 'true';
    // this.http.get(`${environment.baseWP}/wp-json/menu/v1/itens`)
    // .subscribe((result: any) => {
    //   if(result){
    //     this.rodapeItems = result;
    //   }
    // });
    this.initUserAuth();
    this.initHighContrast();
    this.initFontIncrease();
    this.initResponsiveState();
    this.initWordpressContent();
    this.retrieveWordpressOptions();
  }

  initResponsiveState(){
    this.responsiveService.onChange((state: string) => {
      if(state === 'tablet'){
        state = 'desktop';
        document.body.dataset['adaptive'] = 'tablet';
      }else{
        document.body.dataset['adaptive'] = '';
      }
      this.responsiveState = state;
      document.body.dataset['responsive'] = state;
    });
  }

  initWordpressContent(){
    const wordpressPageContent = document.querySelector('.pec-page-content');
    if(wordpressPageContent){
      this.wordpressContent = wordpressPageContent.innerHTML;
    }
  }

  logoutAction(){
    this.userHelper.logout();
  }

  initUserAuth(){
    var self = this;
    self.userInfo.loading = true;
    if(this.userHelper.getAuthCookie()){
      this.userHelper.getUserInfo()
      .subscribe((result: any) => {
        if(result.idKeycloak){
          self.userInfo.logged = true;
          self.userInfo.nome = result.nome;
          self.userInfo.cpf = result.cpf;
          self.userInfo.nascimento = result.dataNascimento;
          self.userInfo.email = result.email;
        }else{
          self.userInfo.logged = false;
        }
      });
  	}
  }

  switchUserPopup(){
    this.userPopupOpen = !this.userPopupOpen;
  }

  updateHighContrast(enable: boolean){
    document.body.classList[enable ? 'add' : 'remove']('high-contrast');
  }

  toggleHighContrast(){
    this.highContrastActive = !this.highContrastActive;
    this.updateHighContrast(this.highContrastActive);
    localStorage.setItem('pecidadao-high-contrast', this.highContrastActive ? 'true' : '');
  }

  initHighContrast(){
    if(localStorage.getItem('pecidadao-high-contrast') ? 'true' : ''){
      this.highContrastActive = true;
    }else{
      this.highContrastActive = false;
    }
    (window as any).ToggleHighContrast = () => { this.toggleHighContrast() };
    this.updateHighContrast(this.highContrastActive);
  }

  increaseFontScale(scale: number){
    this.fontScale += scale;
    if(this.fontScale < -6){
      this.fontScale = -6;
    }
    if(this.fontScale > 6){
      this.fontScale = 6;
    }
    document.body.style.setProperty('--font-size-factor', this.fontScale + 'px');
    localStorage.setItem('pecidadao-font-scale', this.fontScale + '');
  }

  resetFontScale(){
    this.fontScale = 0;
    this.increaseFontScale(0);
    localStorage.setItem('pecidadao-font-scale', this.fontScale + '');
  }

  initFontIncrease(){
    (window as any).IncreaseFontScale = (scale: number) => { this.increaseFontScale(scale) };
    (window as any).ResetFontScale = (scale: number) => { this.resetFontScale() };
    this.increaseFontScale(0);
  }

  retrieveWordpressOptions(){
    this.http.get(`${environment.baseWP}/wp-json/pecidadao/v1/options`)
    .subscribe((result: any) => {
      if(result){
        this.wordpressOptions = result;
      }
    });
  }

  setConsentCookies(){
    localStorage.setItem('pecidadao-consent-cookies', 'true');
    this.consentCookies = true;
  }

  toggleMenu(){
    this.menuOpened = !this.menuOpened;
  }

  isInternalMenuEnabled(){
    return this.routeService.getCurrentRoute()?.data?.hasInternalMenu;
  }

  OnScroll(event: any){
    if(event.target.scrollingElement.scrollTop > 100){
      // if(this.isOnTop){
      //   setTimeout(() => {
      //     window.scrollTo(0, 1);
      //   }, 100);
      // }
      this.isOnTop = false;
      this.eventsService.emit('scroll-top-enter', event);
    }
    if(event.target.scrollingElement.scrollTop == 0){
      this.isOnTop = true;
      this.eventsService.emit('scroll-top-exit', event);
    }
  }

  title = 'pecidadao';
}
