import { NgModule, LOCALE_ID } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import ptBr from '@angular/common/locales/pt';
import { BrowserModule } from '@angular/platform-browser';
import { environment } from '../environments/environment';
import { RECAPTCHA_SETTINGS, RecaptchaFormsModule, RecaptchaModule, RecaptchaSettings } from 'ng-recaptcha';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { ptBrLocale } from 'ngx-bootstrap/locale';
import { NgxMaskModule } from 'ngx-mask';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home.component';
import { ListaTemaComponent } from './components/home/lista-tema/lista-tema.component';
import { ServicosComponent } from './components/servicos/servicos.component';
import { FaleConoscoComponent } from './components/fale-conosco/faleconosco.component';
import { HomeTabsComponent } from './components/home/home-tabs/home-tabs.component';
import { SearchBoxComponent } from './components/home/search-box/search-box.component';
import { ServicosDestaqueComponent } from './components/home/servicos-destaque/servicos-destaque.component';
import { AgendamentoHomeComponent } from './components/home/agendamento-home/agendamento-home.component';
import { AgendamentoComponent } from './components/agendamento/agendamento.component';
import { InformacoesAgendamentoComponent } from './components/agendamento/informacoes-agendamento/informacoes-agendamento.component';
import { AgendarComponent } from './components/agendamento/agendar/agendar.component';
import { FormAgendamentoComponent } from './components/agendamento/form-agendamento/form-agendamento.component';
import { RequerenteAgendamentoComponent } from './components/agendamento/requerente-agendamento/requerente-agendamento.component';
import { SelecaoUnidadeComponent } from './components/agendamento/selecao-unidade/selecao-unidade.component';
import { ConfirmarAgendamentoComponent } from './components/agendamento/confirmar-agendamento/confirmar-agendamento.component';
import { DependentesComponent } from './components/agendamento/dependentes/dependentes.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ModalComponent } from './components/agendamento/modal/modal.component';
import { ListaDocumentosComponent } from './components/agendamento/lista-documentos/lista-documentos.component';
import { ConsultaAgendamentoComponent } from './components/home/consulta-agendamento/consulta-agendamento.component';
import { ConsultaProcessoComponent } from './components/home/consulta-processo/consulta-processo.component';
import { ReagendamentoFormComponent } from './components/agendamento/reagendamento-form/reagendamento-form.component';
import { CancelamentoFormComponent } from './components/agendamento/cancelamento-form/cancelamento-form.component';
import { BuscaGeralComponent } from './components/busca-geral/busca-geral.component';
import { ContatoFormComponent } from './components/fale-conosco/contato-form/contato-form.component';
import { TemaComponent } from './components/temas/tema/tema.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PagamentoComponent } from './components/agendamento/pagamento/pagamento.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { SeletorComponent } from './components/interface/seletor/seletor.component';
import { CadastroComponent } from './components/cadastro/cadastro.component';
import { PerfilComponent } from './components/perfil/perfil.component';
import { WpBannerComponent } from './components/interface/wp-banner/wp-banner.component';
import { BreadcrumbsComponent } from './components/interface/breadcrumbs/breadcrumbs.component';
import { IndicadorServicosComponent } from './components/home/indicador-servicos/indicador-servicos.component';
import { ListaOrgaoComponent } from './components/home/lista-orgao/lista-orgao.component';
import { OrgaoComponent } from './components/temas/orgao/orgao.component';
import { StepsComponent } from './components/interface/steps/steps.component';
import { RemarcacaoComponent } from './components/agendamento/remarcacao/remarcacao.component';
import { NaoconfirmarComponent } from './components/agendamento/naoconfirmar/naoconfirmar.component';
import { CanaisAtendimentoComponent } from './components/agendamento/canais-atendimento/canais-atendimento.component';
import { OQueHaDeNovoComponent } from './components/o-que-ha-de-novo/o-que-ha-de-novo.component';
import { LigaDigitalComponent } from './components/liga-digital/liga-digital.component';
import { LoaderComponent } from './components/interface/loader/loader.component';

// Front-end

defineLocale('pt-br', ptBrLocale);
registerLocaleData(ptBr);

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ListaTemaComponent,
    ServicosComponent,
    FaleConoscoComponent,
    HomeTabsComponent,
    SearchBoxComponent,
    ServicosDestaqueComponent,
    AgendamentoHomeComponent,
    AgendamentoComponent,
    InformacoesAgendamentoComponent,
    AgendarComponent,
    FormAgendamentoComponent,
    SelecaoUnidadeComponent,
    ConfirmarAgendamentoComponent,
    RequerenteAgendamentoComponent,
    DependentesComponent,
    ModalComponent,
    ListaDocumentosComponent,
    ConsultaAgendamentoComponent,
    ConsultaProcessoComponent,
    ReagendamentoFormComponent,
    CancelamentoFormComponent,
    BuscaGeralComponent,
    ContatoFormComponent,
    TemaComponent,
    PagamentoComponent,
    NotFoundComponent,
    SeletorComponent,
    CadastroComponent,
    PerfilComponent,
    WpBannerComponent,
    BreadcrumbsComponent,
    IndicadorServicosComponent,
    ListaOrgaoComponent,
    OrgaoComponent,
    StepsComponent,
    RemarcacaoComponent,
    NaoconfirmarComponent,
    CanaisAtendimentoComponent,
    OQueHaDeNovoComponent,
    LigaDigitalComponent,
    LoaderComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    BsDatepickerModule.forRoot(),
    NgxMaskModule.forRoot(),
    NgbModule,
  ],
  providers: [
    {  provide: LOCALE_ID,  useValue: 'pt-BR'  },
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: environment.recaptcha_site_key,
      } as RecaptchaSettings,
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
