<section class="form-agendamento">
    <div class="container">
      <div class="row">
        <div class="col-12">
  
          <h6>Dependentes</h6>
  
          <form class="row needs-validation" novalidate>

            <div class="dependente" *ngFor="let dependente of form; let i = index">
                <hr *ngIf="i == 0">
                <div class="flex">
                  <div class="label-title">Dependente {{i + 1}}</div>
                  <button class="btn btn-primary btn-remove-dependente" (click)="removerDependente(i)">Remover dependente</button>
                </div>
                <div class="col-12">
                    <fieldset class="reset {{form[i].message['nomecompleto'] ? 'has-error' : ''}}">
                      <legend class="reset">Nome completo*</legend>
                      <input type="text" class="form-control" id="nome-completo-{{i}}" name="nome-completo-{{i}}" required [(ngModel)]="form[i].fields['nomecompleto']" #ctrl="ngModel" (keyup)="mask()">
                    </fieldset>
                    <div class="error-label" *ngIf="form[i].message['nomecompleto']">
                      {{form[i].message['nomecompleto']}}
                    </div>
                </div>

                <div class="col-12">
                    <fieldset class="reset {{form[i].message['cpf'] ? 'has-error' : ''}}">
                      <legend class="reset">CPF*</legend>
                      <input type="text" class="form-control" id="cpf-{{i}}" name="cpf-{{i}}" required [(ngModel)]="form[i].fields['cpf']" #ctrl="ngModel" (keyup)="mask()">
                    </fieldset>
                    <div class="error-label" *ngIf="form[i].message['cpf']">
                      {{form[i].message['cpf']}}
                    </div>
                </div>

                <div class="col-12">
                    <fieldset class="reset {{form[i].message['nascimento'] ? 'has-error' : ''}}">
                      <legend class="reset">Nascimento*</legend>
                      <input type="text" class="form-control" id="nascimento-{{i}}" name="nascimento-{{i}}" required [(ngModel)]="form[i].fields['nascimento']" #ctrl="ngModel" (keyup)="mask()">
                    </fieldset>
                    <div class="error-label" *ngIf="form[i].message['nascimento']">
                      {{form[i].message['nascimento']}}
                    </div>
                </div>

                <hr [hidden]="i == form.length - 1">
            </div>

            <div class="col-12">
                <hr>
                <div class="buttons">
                    <a class="btn btn-form-new-dependente"  (click)="novoDependente()">Novo dependente</a>
                    <a class="btn btn-form-return"  (click)="navigate('back')">Voltar</a>
                    <button class="btn btn-form-submit" (click)="submit()">Avançar</button>
                </div>
            </div>
  
          </form>
  
        </div>
      </div>
    </div>
</section>