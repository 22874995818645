import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import { TemaServico } from '../models/model';

@Injectable({
  providedIn: 'root'
})
export class ListaTemaService {

  constructor(private http: HttpClient) { }
   
  getAll(){
    return this.http.get<TemaServico[]>(`${environment.baseAPI}${encodeURI(environment.tema)}`);
  }

  getOrgaosAll(){
    return this.http.get(`${environment.baseAPI}${environment.listarTemaOrgaos}?apiKey=${environment.apikey}`);
  }
}
