import { Component, Input, Output, ViewChild } from '@angular/core';
import { FormService } from '../../../services/form.service';
import { Observable, Subject } from 'rxjs';
import { AgendamentoService } from '../../../services/agendamento.service';
import {HttpClient} from '@angular/common/http';
import { UserService } from 'src/app/services/user.service';
import { ResponsiveService } from '../../../services/responsive.service';

@Component({
  selector: 'app-requerente-agendamento',
  templateUrl: './requerente-agendamento.component.html',
  styleUrls: ['./requerente-agendamento.component.css']
})
export class RequerenteAgendamentoComponent {
  @Input() OnSubmit: Function = (fields?: any) => {};
  @Input() OnNavigate: Function = (fields?: any) => {};
  @Input() FormName: string = '';
  @Input() FormData: { [key: string]: FormService } = {};
  @Input() servico: any = {};

  captchaSolved: boolean = false;

  form: FormService = new FormService();

  formPopupError: string = '';

  constructor(
    private http: HttpClient,
    private responsiveService: ResponsiveService,
  ) {}

  servicoID: number = 0;
  
  helper: AgendamentoService = new AgendamentoService(this.http);

  userHelper: UserService = new UserService(this.http);

  responsiveState: string = '';

  sessaoCriada: boolean = false;

  ngOnInit(){
    this.servico.subscribe((servico: any) => {
      this.servicoID = servico.id;
    });
    this.form.field('nomecompleto','text:100','Para continuar inclua esta informação, ela é obrigatória.');
    // this.form.field('nomesocial','empty:200','');
    this.form.field('cpf','cpf','Insira um CPF válido.','cpf');
    this.form.field('nascimento','birth','Verifique a informação e digite novamente, por favor.','birth');
    //this.form.field('nomemae','text','Preencha esse campo.');
    //this.form.field('rg','text','Preencha esse campo.','rg');
    this.form.field('email','email:150','Verifique a informação e digite novamente, por favor.');
    this.form.field('celular','text:16','Verifique a informação e digite novamente, por favor.','phone');
    this.form.field('fixo','','');
    this.form.field('termos','text','Para continuar você precisa aceitar a Política de privacidade e os Termos de Uso.');
    this.form.field('autorizacao','','');
    this.form.field('sessiontoken','text','');
    this.form.fields['autorizacao'] = '1';
    this.form.fields['fixo'] = '';

    if(this.userHelper.getAuthCookie()){
      this.userHelper.getUserInfo()
      .subscribe((result: any) => {
        if(result.idKeycloak){
          this.form.fields['nomecompleto'] = result.nome || '';
          this.form.fields['cpf'] = result.cpf || '';
          this.form.fields['nascimento'] = result.dataNascimento || '';
          this.form.fields['email'] = result.email || '';
        }
      });
    }
    this.initResponsiveState();
  }

  initResponsiveState(){
    this.responsiveService.onChange((state: string) => {
      if(state === 'tablet'){
        state = 'desktop';
      }
      this.responsiveState = state;
    });
  }

  mask(){
    this.form.updateMasks();
  }

  navigate(name: string){
    this.OnNavigate(name);
  }

  submit(){
    if(this.form.validate() && this.captchaSolved && this.sessaoCriada){
      this.OnSubmit(this.form, this.FormName);
    }
  }

  onCaptchaSolve(token: any){
    this.captchaSolved = token || false;
    if(token){
      this.helper.criarSessao(token, this.servicoID).subscribe((data: any) => {
        if(data.token){
          this.sessaoCriada = true;
          this.form.fields['sessiontoken'] = data.token;
        }else{
          this.formPopupError = 'Aguarde alguns minutos, volte a tela de agendamento e realize novamente o processo.';
        }
      });
    }
  }

}
