import { Component } from '@angular/core';
import { TipoAgendamento } from '../../models/model';
import { ListaAgendamentoService } from '../../services/lista-agendamento.service';
import { Observable } from 'rxjs';
import { ResponsiveService } from '../../services/responsive.service';

@Component({
  selector: 'app-agendamento',
  templateUrl: './agendamento.component.html',
  styleUrls: ['./agendamento.component.css']
})
export class AgendamentoComponent {
  tiposAgendamento = new Observable<TipoAgendamento[]>();

  servicos: any[] = [];

  responsiveState: string = '';

  constructor(
    private listaAgendamentoService: ListaAgendamentoService,
    private responsiveService: ResponsiveService,
  ){
    this.listAgendamento();
  }

  ngOnInit(){
    this.initResponsiveState();
  }

  initResponsiveState(){
    this.responsiveService.onChange((state: string) => {
      if(state === 'tablet'){
        state = 'desktop';
      }
      this.responsiveState = state;
    });
  }

  listAgendamento(){
    this.listaAgendamentoService.getAll().subscribe((data: any) => {
      if(data){
        this.servicos = data.sort((a: any, b: any) => a.nome.localeCompare(b.nome));
      }
    });
  }

  capitalize(str: string){
    return str.trim().charAt(0).toUpperCase() + str.trim().slice(1).toLowerCase();
  }

  removeRichText(text: string){
    return text.replace(/<\/?[^>]+(>|$)/g, '');
  }

  limitText(text: string, count: number){
    return text.substr(0, count) + (text.length > count ? '...' : '');
  }
}
