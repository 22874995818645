export const environment = {
    recaptcha_site_key: '6LdIJnAUAAAAACRb0y2CeeG9PSC5SuCTfUsYcX4M',
    apikey: '7ed44607-e058-43f2-ad67-4124061f0fdf',
    apikey2: '7ed44607-e058-43f2-ad67-4124061f0fdf',
    apikey3: 'a7a9b899-d2f3-46ac-8f34-ee86101af30e',
    baseAPI: 'https://www.apigtw.pe.gov.br',
    api: 'http://localhost:3000',
    baseWP: 'https://pecidadao.pe.gov.br',
    tema: '/apiman-gateway/carta-servico/ativos/1.0/',
    agendamento: '/apiman-gateway/cidadao/agendamento/1.0/horarioAgenda/listarServicosComHorario',
    servico: '/apiman-gateway/carta-servico/servicos/1.0/buscarContando/',
    servicosMaisAcessados: '/apiman-gateway/gov/carta-servico/1.0/servicos/mais-acessados',
    buscaGeral: '/apiman-gateway/carta-servico/servicos/1.0/novoBuscaGeral/',
    dadoscadastro: '/apiman-gateway/cidadao/cadastro-cidadao/1.0/',
    atualizarcadastro: '/apiman-gateway/cidadao/cadastro/1.1/dados-pessoais',
    contatoForm: '/apiman-gateway/cidadao/publico/1.1/fale-conosco',
    agendamentoGet: '/apiman-gateway/cidadao/agendamento/1.0/agendamento',
    agendamentoParametroServico: '/apiman-gateway/cidadao/agendamento/1.0/parametroServico/getPorServico/',
    agendamentoParametroOrgao: '/apiman-gateway/cidadao/agendamento/1.0/parametroOrgao/getPorServico/',
    agendamentoParametroServicoUnidade: '/apiman-gateway/cidadao/agendamento/1.0/parametroServicoUnidade/getPorServicoUnidade/',
    agendamentoInformacaoAdicional: '/apiman-gateway/cidadao/agendamento/1.0/informacaoAdicional/consultarPorServico/',
    agendamentoAgendaUnidade: '/apiman-gateway/cidadao/agendamento/1.0/agendaUnidade/municipiosComServicoEAgenda',
    agendamentoBuscaMunicipios: '/apiman-gateway/cidadao/agendamento/1.0/agendaUnidade/listarUnidadesComAgendaPorFiltro',
    agendamentoBuscaRestricoesMunicipios: '/apiman-gateway/cidadao/agendamento/1.0/horarioAgenda/buscarRestricoesDatasAgendamento',
    agendamentoBuscaHorariosUnidade: '/apiman-gateway/cidadao/agendamento/1.0/horarioAgenda/consultarParaAgendamento',    
    agendamentoConsulta: '/apiman-gateway/cidadao/agendamento/1.0/agendamento/search',
    agendamentoCriarSessao: '/apiman-gateway/cidadao/agendamento/1.0/agendamento/iniciarSessao',
    agendamentoValidarSessao: '/apiman-gateway/cidadao/agendamento/1.0/agendamento/validarSessao',
    agendamentoReservar: '/apiman-gateway/cidadao/agendamento/1.0/agendamento/reservar',
    agendamentoConfirmar: '/apiman-gateway/cidadao/agendamento/1.0/agendamento/confirmar',
    reagendamentoConfirmar: '/apiman-gateway/cidadao/agendamento/1.0/agendamento/confirmarRemarcacao',
    reagendamentoReservar: '/apiman-gateway/cidadao/agendamento/1.0/agendamento/reservarRemarcacao',
    cancelamentoConfirmar: '/apiman-gateway/cidadao/agendamento/1.0/agendamento/cancelar',
    reagendamentoConfirmacao: '/apiman-gateway/cidadao/agendamento/1.0/agendamento/confirmacao',
    reagendamentoNaoConfirmar: '/apiman-gateway/cidadao/agendamento/1.0/agendamento/naoConfirmar',
    getHorarioCancelamento: '/apiman-gateway/cidadao/agendamento/1.0/parametroUnidade/getHorarioCancelamento/',
    emailProtocolo: '/apiman-gateway/cidadao/agendamento/1.0/agendamento/enviarEmailProtocolo',
    verificaDAE: '/apiman-gateway/cidadao/agendamento/1.0/integracaoDAE/verificarDAE',
    destaqueServicos: '/apiman-gateway/carta-servico/destaque-servicos/1.1',
    servicosPorTema: '/apiman-gateway/carta-servico/servicos-por-temas/1.0/',
    consultaAgendamentoAberto: '/apiman-gateway/cidadao/agendamento/1.0/agendamento/consultarAbertosRequerentes',
    consultaAgendamentoNaoComparecido: '/apiman-gateway/cidadao/agendamento/1.0/agendamento/listarAgendamentosNaoComparecidos',
    cadastrarUsuario: '/apiman-gateway/cidadao/cadastro-cidadao-publico/1.0/cadastrar',
    indicadores: '/apiman-gateway/gov/carta-servico/1.0/dashboard/indicadores-servicos',
    gerarDAE: '/apiman-gateway/cidadao/agendamento/1.0/integracaoDAE/gerarDAE',
    integracaoDAE: '/apiman-gateway/cidadao/agendamento/1.0/integracaoDAE/boleto',
    avaliacoesMotivos: '/apiman-gateway/gov/carta-servico/1.0/motivos-avaliacao-servico',
    avaliacoesContagem: '/apiman-gateway/gov/carta-servico/1.0/avaliacoes-servico/contagem',
    getAvaliacoesServico: '/apiman-gateway/gov/carta-servico/1.0/avaliacoes-servico',
    singleAgendamento: '/apiman-gateway/cidadao/agendamento/1.0/agendamento',
    listarTemaOrgaos: '/apiman-gateway/gov/carta-servico/1.0/orgaos/listarTemaOrgaos',
    listaServicosOrgao: '/apiman-gateway/gov/carta-servico/1.0/servicos/listarServicosAtivosPublicadosPorOrgao',
    homeTabPages:[
        '',
        'servicos',
        'fale-conosco',
    ],
};
