<div class="services">
  <div class="row">
    <div class="col">
      <h4 class=" font-style-4">Serviços por categoria</h4>
      <div class="mb-16"></div>
      <div class="list-services variation-2">
        <ul>
          <li *ngFor="let tema of temas$ | async;let i = index" [hidden]="responsiveState != 'desktop' && !expanded && i > 6">
            <a class="item" href="{{'/servicos-tema/tema-' + tema.id}}">

              <div class="icon" *ngIf="tema.icone !== ''; else iconShield">
                <i class={{tema.icone}}></i>
              </div>
              <ng-template #iconShield>
                <div class="icon">
                  <i class="fa fa-shield fa-inverse"></i>
                </div>
              </ng-template>

              <p class="font-style-3">{{tema.titulo}}</p>
            </a>
          </li>
          <li (click)="expand()" *ngIf="!expanded && responsiveState != 'desktop'">
            <a class="item cursor-pointer item-center">

              <div class="icon expand-icon">
                <img src="../../../../assets/image/icons/icon-expand-blue.svg">
              </div>

              <p class="font-style-1 no-padding">ver mais</p>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>