import { Component } from '@angular/core';
import { TemaServico } from '../../../models/model';
import { ListaTemaService } from '../../../services/lista-tema.service';
import { Observable } from 'rxjs';
import { ResponsiveService } from '../../../services/responsive.service';

@Component({
  selector: 'app-lista-tema',
  templateUrl: './lista-tema.component.html',
  styleUrls: ['./lista-tema.component.css']
})
export class ListaTemaComponent {
    temas$ = new Observable<TemaServico[]>();

    expanded: boolean = false;

    responsiveState: string = '';

    constructor(
      private listaTemaService: ListaTemaService,
      private responsiveService: ResponsiveService,
    ){
      this.listTemas();
    }

    ngOnInit(){
      this.initResponsiveState();
    }
    
    initResponsiveState(){
      this.responsiveService.onChange((state: string) => {
        if(state === 'tablet'){
          state = 'desktop';
        }
        this.responsiveState = state;
      });
    }
    
    listTemas(){
      this.temas$ = this.listaTemaService.getAll();
    }

    capitalize(str: string){
      return str.trim().charAt(0).toUpperCase() + str.trim().slice(1).toLowerCase();
    }

    expand(){
      this.expanded = true;
    }

}
