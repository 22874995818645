import { Component } from '@angular/core';
import { EventsService } from '../../services/events.service';

@Component({
  selector: 'app-o-que-ha-de-novo',
  templateUrl: './o-que-ha-de-novo.component.html',
  styleUrls: ['./o-que-ha-de-novo.component.css']
})
export class OQueHaDeNovoComponent {
  constructor(private eventsService: EventsService){}

  ngOnInit(){
    this.eventsService.emit('breadcrumb-update', 'O que há de novo?');
  }
}
