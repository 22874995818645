import { Component } from '@angular/core';
import { EventsService } from '../../services/events.service';

@Component({
  selector: 'app-liga-digital',
  templateUrl: './liga-digital.component.html',
  styleUrls: ['./liga-digital.component.css']
})
export class LigaDigitalComponent {
  constructor(private eventsService: EventsService){}

  ngOnInit(){
    this.eventsService.emit('breadcrumb-update', 'Liga Digital');
  }
}