
<div class="container">
   <div class="row">
      <div class="col">

         <div class="fale-conosco">
            <div class="title padding-left"></div>
            <div class="ajuda-box box-bordered">
               <div class="box-title">Precisa de ajuda?</div>
               <p>Tire dúvidas, faça solicitações ou envie elogios, dúvidas e sugestões. Queremos ouvir você.</p>
               <a href="/fale-conosco/contato">
                  <button>Fale conosco</button>
               </a>
            </div>
            <h1 class="title">Como podemos ajudar você?</h1>
            <hr class="hr-fullwidth">
            <div class="ajuda-box">
               <div class="accordion accordion-flush" id="accordionAgendamento">
                  <div class="accordion-item" *ngFor="let pergunta of getObjectKeys(perguntasFrequentes); let i = index">
                     <h2 class="accordion-header" id="flush-headingThree">
                       <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" [attr.data-bs-target]="'#flush-collapse'+ i" aria-expanded="false" aria-controls="flush-collapseThree">
                           {{pergunta}}
                       </button>
                     </h2>
                     <div id="flush-collapse{{i}}" class="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionAgendamento">
                       <div class="accordion-body">
                           <div class="main">
                              <div class="subpergunta" *ngFor="let subpergunta of $any(perguntasFrequentes)[pergunta];let i2 = index">
                                 <div class="pergunta-title" (click)="toggleSubperguntaActive(i, i2)">
                                    <div class="pergunta-icon {{subperguntaActive == i2 && perguntaActive == i ? 'active' : ''}}"></div>
                                    <div>{{subpergunta.title}}</div>
                                 </div>
                                 <div class="pergunta-body {{subperguntaActive == i2 && perguntaActive == i ? 'active' : ''}}">
                                    <p [innerHTML]="subpergunta.content"></p>
                                 </div>
                              </div>
                           </div>
                       </div>
                     </div>
                  </div>
              </div>
            </div>
         </div>

      </div>
   </div>
</div>