import { Component, Input, OnChanges, SimpleChanges, Injectable } from '@angular/core';
import { SimpleObject } from '../models/model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FormService {
  fields: { [key: string]: string } = {};
  validationMessage: { [key: string]: string } = {};
  validation: { [key: string]: string } = {};
  message: { [key: string]: string } = {};
  masks: { [key: string]: string } = {};

  constructor() { }

  validate(){
    const fieldKeys = Object.keys(this.fields);
    var isValid = true;
    fieldKeys.forEach(fieldKey => {
      const message = this.validateField(fieldKey);
      this.message[fieldKey] = message;
      if(message != ''){
        isValid = false;
      }
    });
    return isValid;
  }

  field(name: string, validation: string, message: string, mask?: string){
    this.fields[name] = '';
    this.validationMessage[name] = message;
    this.validation[name] = validation;
    this.message[name] = '';
    this.masks[name] = mask || '';
  }

  validateField(key: string){
    var type = this.validation[key];
    var value = this.fields[key];
    var maxLength = 0;
    const message = this.validationMessage[key];
    if(type.includes(':')){
      maxLength = parseInt(type.split(':')[1] || '0');
      type = type.split(':')[0];
      if(maxLength > 0 && value.length > maxLength) return 'Essa informação precisa ter no máximo '+ maxLength +' caracteres.';
    }
    switch (type) {
      case 'empty':
        return '';
        break;
      case 'email':
        if(value.indexOf('@') == -1) return 'Insira um endereço de e-mail válido.';
        return value ? '' : message;
        break;
      case 'birth':
        const birth_validation = this.ValidateBirth(value, message);
        return birth_validation;
        break;
      case 'checked':
        return value ? '' : message;
        break;
      case 'text':
        return value ? '' : message;
        break;
      case 'cpf':
        return this.ValidationCPF(value) ? '' : message;
        break;
      case 'date':
        return value ? '' : message;
        break;
      case 'email':
        return value ? '' : message;
        break;
      case 'phone':
          return value ? '' : message;
          break;
      default:
        return '';
        break;
    }
  }

  ValidationCPF(CPF: string){
    var strCPF = CPF.replace(/\D/g,'');
    var Soma = 0;
    var Resto;
    if (/^(\d)\1{10}$/.test(strCPF)) return false;
    for (var i=1; i<=9; i++) Soma = Soma + parseInt(strCPF.substring(i-1, i)) * (11 - i);
    Resto = (Soma * 10) % 11;
    if ((Resto == 10) || (Resto == 11))  Resto = 0;
    if (Resto != parseInt(strCPF.substring(9, 10)) ) return false;
    Soma = 0;
    for (i = 1; i <= 10; i++) Soma = Soma + parseInt(strCPF.substring(i-1, i)) * (12 - i);
    Resto = (Soma * 10) % 11;
    if ((Resto == 10) || (Resto == 11))  Resto = 0;
    if (Resto != parseInt(strCPF.substring(10, 11) ) ) return false;
    return true;
  }

  ValidateBirth(value: string, defaultMessage: string){
    if(!value) return defaultMessage;
    if(value.length < 10) return 'Insira uma data de nascimento válida.';
    const birthInfo = value.split('/');
    const birthDate = new Date(`${birthInfo[1]}-${birthInfo[0]}-${birthInfo[2]}`);
    const currentDate = new Date(Date.now());
    currentDate.setHours(0,0,0,0);
    if(birthDate > currentDate) return 'Insira uma data de nascimento válida.';
    return '';
  }

  updateMasks(){
    const maskKeys = Object.keys(this.masks);
    maskKeys.forEach((key) => {
      this.fields[key] = this.applyMask(this.fields[key], this.masks[key]);
    });
  }

  applyMask(value: string, type: string){
    switch(type){
      case 'cpf':
        const cpf = value.replace(/\D/g, '');
        var result = cpf;
        if(cpf.length > 3){
          result = cpf.substr(0, 3) + '.' + cpf.substr(3, 3);
        }
        if(cpf.length > 6){
          result = cpf.substr(0, 3) + '.' + cpf.substr(3, 3) + '.' + cpf.substr(6, 3);
        }
        if(cpf.length > 9){
          result = cpf.substr(0, 3) + '.' + cpf.substr(3, 3) + '.' + cpf.substr(6, 3) + '-' + cpf.substr(9, 2);
        }
        return result;
        break;
      case 'birth':
        const birth = value.replace(/\D/g, '');
        var result = birth;
        if(birth.length > 2){
          result = birth.substr(0, 2) + '/' + birth.substr(2, 8);
        }
        if(birth.length > 4){
          result = birth.substr(0, 2) + '/' + birth.substr(2, 2) + '/' + birth.substr(4, 4);
        }
        return result;
        break;
      case 'rg':
        return value.replace(/\D/g, '');
        break;
      case 'phone':
          const phone = value.replace(/\D/g, '');
          var result = phone;
          if(phone.length > 2){
            result = '(' + phone.substr(0, 2) + ') ' + phone.substr(2, 4);
          }
          if(phone.length > 6){
            result = '(' + phone.substr(0, 2) + ') ' + phone.substr(2, 4) + '-' + phone.substr(6, 10);
          }
          if(phone.length > 10){
            result = '(' + phone.substr(0, 2) + ') ' + phone.substr(2, 5) + '-' + phone.substr(7, 5);
          }
          return result.substr(0,16);
          break;
      default:
        return value;
        break;
    }
    return value;
  }

  preventSubmit(event: Event) {
    event.preventDefault();
  }

}
