import { Component } from '@angular/core';
import { RouteService } from 'src/app/services/route.service';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { ResponsiveService } from '../../../services/responsive.service';

@Component({
  selector: 'app-home-tabs',
  templateUrl: './home-tabs.component.html',
  styleUrls: ['./home-tabs.component.css']
})
export class HomeTabsComponent {

  rotaAtiva: any = {};

  responsiveState: string = '';

  constructor(
    private location: Location,
    private router: Router,
    private responsiveService: ResponsiveService,
  ) {}

  routeService: RouteService = new RouteService(this.location, this.router);

  ngOnInit(): void {
    this.rotaAtiva = this.routeService.getCurrentRoute();
    this.initResponsiveState();
  }

  initResponsiveState(){
    this.responsiveService.onChange((state: string) => {
      if(state === 'tablet'){
        state = 'desktop';
      }
      this.responsiveState = state;
    });
  }

  CanShowComponent(){
    return this.routeService.GetMenuType() == 'initial' || this.responsiveState == 'desktop';
  }
}
