import { Component } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { RouteService } from '../../../services/route.service';
import { EventsService } from '../../../services/events.service';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.css']
})
export class BreadcrumbsComponent {

  constructor(
    private Location: Location,
    private router: Router,
    private eventsService: EventsService
  ) { }

  routeService: RouteService = new RouteService(this.Location, this.router);

  route: any = {};

  ngOnInit() {
    this.route = this.routeService.getCurrentRoute();
    this.eventsService.when('breadcrumb-update', (path: string) => {
      this.route.data['breadcrumbs'] = path;
    });
  }
}