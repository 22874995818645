import { Component, Input } from '@angular/core';
import { EventsService } from '../../../services/events.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.css']
})
export class LoaderComponent {
  @Input() UseEvents: boolean = false;
  @Input() message: string = 'Carregando...';

  enabled: boolean = false;

  constructor(
    private eventsService: EventsService
  ){}

  ngOnInit(){
    if(this.UseEvents){
      this.eventsService.when('loader-show', () => {
        this.message = 'Carregando...';
        this.enabled = true;
      });
      this.eventsService.when('loader-hide', () => {
        this.enabled = true;
      });
      this.eventsService.when('loader-toggle', (enabled: boolean, message: string) => {
        this.enabled = enabled;
        if(message){
          this.message = message;
        }
      });
    }else{
      this.enabled = true;
    }
  }
}
