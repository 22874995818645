import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ResponsiveService {

  listeners: any[] = [];

  state: string = '';

  timeout: number = 0;

  constructor() {
    (window as any).addEventListener('resize', () => {
      this.updateState();
      this.refreshCallbacks();
    });
    (window as any).addEventListener('load', () => {
      this.updateState();
      this.refreshCallbacks();
    });
  }

  onChange(callback: any){
    this.listeners.push(callback);
    clearTimeout(this.timeout);
    setTimeout(() => {
      this.updateState();
      this.refreshCallbacks();
    }, 100);
  }

  updateState(){
    const width = (window as any).outerWidth;
    if(width <= 768){
      this.state = 'mobile';
    }
    if(width > 768){
      this.state = 'tablet';
    }
    if(width >= 991){
      this.state = 'desktop';
    }
  }

  refreshCallbacks(){
    this.listeners.forEach(callback => callback(this.state));
  }
}
