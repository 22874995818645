import { Injectable } from '@angular/core';
import { TipoAgendamento, AgendamentoParametroServico, AgendamentoPojo } from '../models/model';
import {environment} from '../../environments/environment';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CacheService } from './cache.service';

@Injectable({
  providedIn: 'root'
})
export class AgendamentoService {

  constructor(private http: HttpClient) {}

  cache: CacheService = new CacheService();

  getParametroServicoUnidade(idOrgao: any, idServico: any, idUnidade: any){
    return this.http.get(`${environment.baseAPI}${environment.agendamentoParametroServicoUnidade}${idOrgao}/${idServico}/${idUnidade}/?apikey=${environment.apikey2}`)
    .pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.error instanceof ErrorEvent) {
          return of(error.error.message);
        }else{
          return of(error.error);
        }
      })
    );
  }

  getUnidadeHorarioCancelamento(unidadeID: number){
    return this.http.get(`${environment.baseAPI}${encodeURI(environment.getHorarioCancelamento)}${unidadeID}?apikey=${environment.apikey2}`)
    .pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.error instanceof ErrorEvent) {
          return of(error.error.message);
        }else{
          return of(error.error);
        }
      })
    );
  }

  verificaDadosDAE(formData: any, servicoID: number){
    const nascimento = formData.fields['nascimento'].split('/').reverse().join('-') + 'T03:00:00.000Z';
    const data = {
      "servicoId": servicoID,
      "pessoa":{
        "nome": formData.fields['nomecompleto'].toUpperCase(),
        // "nomeMae": formData.fields['nomemae'],
        "cpf": formData.fields['cpf'].replace(/\D/g,''),
        "nascimento": nascimento,
        "email": formData.fields['email'],
        "telefoneCelular": formData.fields['celular'],
        "telefoneFixo": formData.fields['fixo']
      },
      "titularParticipa":true,
    };
    const options = { headers: new HttpHeaders({ 'agendamento-security': formData.fields['sessiontoken'] }) };
    return this.http.post(`${environment.baseAPI}${encodeURI(environment.verificaDAE)}?apikey=${environment.apikey2}`, data, options)
  }

  gerarDAE(dadosDae: any, token: string){
    const options = { headers: new HttpHeaders({ 'agendamento-security': token }) };
    return this.http.post(`${environment.baseAPI}${environment.gerarDAE}?apikey=${environment.apikey2}`, dadosDae, options);
  }

  gerarBoletoDAE(codigoDae: number, codigoServico: number, token: string){
    const req = new HttpRequest('GET', `${environment.baseAPI}${environment.integracaoDAE}?codigo_dae=${codigoDae}&codigo_servico=${codigoServico}&apikey=${environment.apikey2}`, {
        headers: new HttpHeaders({ 'agendamento-security': token }),
        responseType: 'blob',
        reportProgress: true,
    });
    return this.http.request(req);
  }

  criarSessao(captchaResponse: string, servicoId: number){
    const data: any = {
      captchaResponse,
      servicoId,
    };
    return this.http.post(`${environment.baseAPI}${encodeURI(environment.agendamentoCriarSessao)}?apikey=${environment.apikey2}`, data)
    .pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.error instanceof ErrorEvent) {
          return of(error.error.message);
        }else{
          return of(error.error);
        }
      })
    );
  }

  validarSessao(captchaResponse: string, token: string, idServico: number){
    const data: any = {
      captchaResponse,
      token,
      idServico,
    };
    return this.http.post(`${environment.baseAPI}${encodeURI(environment.agendamentoValidarSessao)}?apikey=${environment.apikey2}`, data)
    .pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.error instanceof ErrorEvent) {
          return of(error.error.message);
        }else{
          return of(error.error);
        }
      })
    );
  }

  consultaAgendamento(fields: any){
    const data = {
      cpf: decodeURI(fields.cpf),
      nascimento: decodeURI(fields.nascimento) + "T03:00:00.000Z",
    };
    return this.http.post(`${environment.baseAPI}${encodeURI(environment.agendamentoConsulta)}?apikey=${environment.apikey}`, data)
    .pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.error instanceof ErrorEvent) {
          return of(error.error.message);
        }else{
          return of(error.error);
        }
      })
    );
  }

  getAgendamento(fields: any){
    return this.http.get(`${environment.baseAPI}${encodeURI(environment.agendamentoGet)}/${fields.protocolo}?apikey=${environment.apikey2}`)
    .pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.error instanceof ErrorEvent) {
          return of(error.error.message);
        }else{
          return of(error.error);
        }
      })
    );
  }

  sendCancelamentoForm(agendamento: any, token: string){
    const options = { headers: new HttpHeaders({ 'agendamento-security': token }) };
    return this.http.post(`${environment.baseAPI}${encodeURI(environment.cancelamentoConfirmar)}?apikey=${environment.apikey}`, agendamento, options)
    .pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.error instanceof ErrorEvent) {
          return of(error.error.message);
        }else{
          return of(error.error);
        }
      })
    );
  }

  sendReservaReagendamentoForm(formData: any, agendamentoAnterior: any, token: string){
    var agendamentoNovo: any = {
      horarioAgenda: formData.agendamento.fields['horario'],
      horariosRelacionados: agendamentoAnterior.horariosRelacionados,
      infosAdicionaisAgendamentos: agendamentoAnterior.infosAdicionaisAgendamentos,
      lembreteEmail: agendamentoAnterior.lembreteEmail,
      pessoa: agendamentoAnterior.pessoa,
      titularParticipa: agendamentoAnterior.titularParticipa,
    };
    if(agendamentoAnterior.codigoDae){
      agendamentoNovo.codigoDae = agendamentoAnterior.codigoDae;
    }
    const data: any = {
      "novoAgendamento":agendamentoNovo,
      "agendamentoAnterior":agendamentoAnterior,
    };
    return this.http.post(`${environment.baseAPI}${encodeURI(environment.reagendamentoReservar)}?apikey=${environment.apikey2}`, data)
    .pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.error instanceof ErrorEvent) {
          return of(error.error.message);
        }else{
          return of(error.error);
        }
      })
    );
  }

  reservarAgendamento(formData: any, token: string, dadosDAE: any, servico: any){
    formData.section3.fields['horario'].servicoUnidade.servico = {
      categoria: {
        id: 0,
        orgao: {
          id:0,
          nome: servico.categoria.orgao.nome,
          unidadeOrganizacional: '',
        },
      },
      descricao: servico.descricao,
      flagGratuito: servico.flagGratuito,
      flagPublicado: servico.flagPublicado,
      flagTotalmenteOnline: servico.flagTotalmenteOnline,
      id: servico.id,
      nome: servico.nome,
      requisitos: servico.requisitos,
      sigla: servico.sigla,
      tempoEntrega: servico.tempoEntrega,
    };
    var data: any = {
      "novoAgendamento":{
        "horarioAgenda": formData.section3.fields['horario'],
        "horariosRelacionados":[],
        "pessoa": {
          nome: formData.section1.fields['nomecompleto'],
          cpf: formData.section1.fields['cpf'],
          nascimento: new Date(formData.section1.fields['nascimento'].split('/').reverse().join('-') + 'T03:00:00.000Z'),
          telefoneCelular: formData.section1.fields['celular'].replace(/\D/g,'').substr(0,12),
          email: formData.section1.fields['email'],
          dependentes: [],
        },
        "titularParticipa":true,
        "lembreteEmail": formData.section1.fields['autorizacao'] ? true : false,
        "infosAdicionaisAgendamentos":[],
      },
      "agendamentoAnterior":{}
    };
    if(dadosDAE && dadosDAE.codigoDae){
      data.novoAgendamento.codigoDae = dadosDAE.codigoDae;
    }
    const options = { headers: new HttpHeaders({ 'agendamento-security': token }) };
    return this.http.post(`${environment.baseAPI}${encodeURI(environment.agendamentoReservar)}?apikey=${environment.apikey2}`, data, options)
    .pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.error instanceof ErrorEvent) {
          return of(error.error.message);
        }else{
          return of(error.error);
        }
      })
    );
  }

  sendAgendamentoForm(agendamentoReserva: any, token: string){
    const options = { headers: new HttpHeaders({ 'agendamento-security': token }) };
    return this.http.post(`${environment.baseAPI}${encodeURI(environment.agendamentoConfirmar)}?apikey=${environment.apikey2}`, agendamentoReserva, options)
    .pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.error instanceof ErrorEvent) {
          return of(error.error.message);
        }else{
          return of(error.error);
        }
      })
    );
  }

  sendReagendamentoForm(agendamento: any, token: string){
    return this.http.post(`${environment.baseAPI}${encodeURI(environment.reagendamentoConfirmar)}?apikey=${environment.apikey2}`, agendamento)
    .pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.error instanceof ErrorEvent) {
          return of(error.error.message);
        }else{
          return of(error.error);
        }
      })
    );
  }

  sendEmailProtocolo(email: string, token: string){
    const options = { headers: new HttpHeaders({ 'agendamento-security': token }) };
    return this.http.post(`${environment.baseAPI}${encodeURI(environment.emailProtocolo)}?apikey=${environment.apikey2}`, { emailCidadao: email }, options)
    .pipe(
      catchError((error: HttpErrorResponse) => {
        return of(error);
      })
    );
  }

  getAgendamentoData(id: string){
    return {
      parametroServico: this.getAgendamentoParametroServico(id),
      parametroOrgao: this.getAgendamentoParametroOrgao(id),
      informacaoAdicional: this.getAgendamentoInformacaoAdicional(id),
      agendaUnidade: this.getAgendamentoAgendaUnidade(id),
    };
  }

  getAgendamentoBuscaUnidades(id: string, municipio: string){
    const data = {
      "idServico": id,
      "municipios": [municipio],
    };
    return this.http.post(`${environment.baseAPI}${encodeURI(environment.agendamentoBuscaMunicipios)}/?apikey=${environment.apikey}`,data);
  }

  getAgendamentoBuscaRestricoesDataUnidades(id: string, unidade: string){
    const data = {
      "totalResultados":0,
      "primeiroResultado":0,
      "tamanhoPagina":10,
      "entidadeConsulta":{},
      "servicoId":id,
      "unidadeIds":[unidade],
    };
    
    return this.http.post(`${environment.baseAPI}${encodeURI(environment.agendamentoBuscaRestricoesMunicipios)}/?apikey=${environment.apikey}`,data);
  }

  getAgendamentoBuscaHorariosUnidade(id: string, unidade: string, dataAgendar: string, pagina: number){
    const data = {
      data: dataAgendar + "T03:00:00.000Z",
      entidadeConsulta: {},
      primeiroResultado: pagina * 10,
      servicoId: id,
      tamanhoPagina: 10,
      unidadeIds:[unidade],
      resultado: [],
    };
    
    return this.http.post(`${environment.baseAPI}${encodeURI(environment.agendamentoBuscaHorariosUnidade)}/?apikey=${environment.apikey}`,data);
  }

  getBuscaGeral(searchQuery: string) {
    return this.http.post(`${environment.baseAPI}${encodeURI(environment.buscaGeral)}`, searchQuery);
  }

  getServicosMaisAcessados() {
    return this.http.get(`${environment.baseAPI}${encodeURI(environment.servicosMaisAcessados)}?apikey=${environment.apikey}`);
  }

  getAgendamentoParametroServico(id: string) {
    return this.http.get(`${environment.baseAPI}${encodeURI(environment.agendamentoParametroServico)}${id}/?apikey=${environment.apikey}`);
  }

  getAgendamentoParametroOrgao(id: string) {
    return this.http.get(`${environment.baseAPI}${encodeURI(environment.agendamentoParametroOrgao)}/${id}/?apikey=${environment.apikey}`);
  }

  getAgendamentoInformacaoAdicional(id: string) {
    return this.http.get(`${environment.baseAPI}${encodeURI(environment.agendamentoInformacaoAdicional)}/${id}/?apikey=${environment.apikey}`);
  }

  getAgendamentoAgendaUnidade(id: string) {
    return this.http.get(`${environment.baseAPI}${encodeURI(environment.agendamentoAgendaUnidade)}/${id}/?apikey=${environment.apikey}`);
  }

  verificaAgendamentosAbertos(formData: any, servicoID: number, orgaoId: number){
    const data = {
      "totalResultados": 0,
      "primeiroResultado": 0,
      "tamanhoPagina": 10,
      "entidadeConsulta": {},
      "agendamento": {},
      "pessoa": {
          "nome": formData.section1.fields['nomecompleto'],
          "cpf": formData.section1.fields['cpf'],
          "nascimento": new Date(formData.section1.fields['nascimento'].split('/').reverse().join('-') + 'T03:00:00.000Z'),
          "email": formData.section1.fields['email'],
          "telefoneCelular": formData.section1.fields['celular'],
      },
      "consultaPorTilular": true,
      "servicoId": servicoID,
      "orgaoId": orgaoId,
    };
    return this.http.post(`${environment.baseAPI}${environment.consultaAgendamentoAberto}?apikey=${environment.apikey2}`, data);
  }

  verificaAgendamentosNaoComparecidos(formData: any, servicoID: number, orgaoId: number){
    const data = {
      "totalResultados": 0,
      "primeiroResultado": 0,
      "tamanhoPagina": 10,
      "entidadeConsulta": {},
      "agendamento": {},
      "pessoa": {
          "nome": formData.section1.fields['nomecompleto'],
          "cpf": formData.section1.fields['cpf'],
          "nascimento": new Date(formData.section1.fields['nascimento'].split('/').reverse().join('-') + 'T03:00:00.000Z'),
          "email": formData.section1.fields['email'],
          "telefoneCelular": formData.section1.fields['celular'],
      },
      "consultaPorTilular": true,
      "servicoId": servicoID,
      "orgaoId": orgaoId,
    };
    return this.http.post(`${environment.baseAPI}${environment.consultaAgendamentoNaoComparecido}?apikey=${environment.apikey2}`, data);
  }

  confirmarAgendamentoSemToken(agendamento: any){
    return this.http.post(`${environment.baseAPI}${environment.reagendamentoConfirmacao}?apikey=${environment.apikey2}`, agendamento)
    .pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.error instanceof ErrorEvent) {
          return of(error.error.message);
        }else{
          return of(error.error);
        }
      })
    );
  }

  naoConfirmarRemarcacao(agendamento: any){
    return this.http.post(`${environment.baseAPI}${environment.reagendamentoNaoConfirmar}?apikey=${environment.apikey2}`, agendamento)
    .pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.error instanceof ErrorEvent) {
          return of(error.error.message);
        }else{
          return of(error.error);
        }
      })
    );
  }

  getAvaliacoesServico(servicoId: number){
    return this.http.get(`${environment.baseAPI}${environment.avaliacoesContagem}/${servicoId}?apikey=${environment.apikey}`);
  }

  getMotivosAvaliacao(){
    return this.http.get(`${environment.baseAPI}${environment.avaliacoesMotivos}?apikey=${environment.apikey}`);
  }

  addAvaliacaoServico(servicoId: number, avaliacao: number, motivoId: number, outroMotivo: string){
    const data = {
        "idServico": servicoId,
        "avaliacao": avaliacao,
        "comentario": outroMotivo,
        "motivos": [{idAvaliacao: null, idMotivo: motivoId}],
    };
    return this.http.post(`${environment.baseAPI}${environment.getAvaliacoesServico}?apikey=${environment.apikey}`, data);
  }

  getSingleAgendamento(protocolo: string){
    return this.http.get(`${environment.baseAPI}${environment.singleAgendamento}/${protocolo}?apikey=${environment.apikey2}`);
  }
  
}
