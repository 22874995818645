import { Component } from '@angular/core';
import { TemaServico } from '../../../models/model';
import { ListaTemaService } from '../../../services/lista-tema.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-lista-orgao',
  templateUrl: './lista-orgao.component.html',
  styleUrls: ['./lista-orgao.component.css']
})
export class ListaOrgaoComponent {
  orgaos: any = [];

  constructor(
    private listaTemaService: ListaTemaService
  ){}

  ngOnInit(){
    this.listaTemaService.getOrgaosAll()
    .subscribe((orgaos: any) => {
      if(orgaos){
        this.orgaos = orgaos.sort((a: any, b: any) => a.nomeOrgao.localeCompare(b.nomeOrgao));
      }
    });
  }

  capitalize(str: string){
    return str.trim().charAt(0).toUpperCase() + str.trim().slice(1).toLowerCase();
  }

  limitText(text: string, count: number){
    return text.substr(0, count) + (text.length > count ? '...' : '');
  }
}
