import { Component } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-faleconosco',
  templateUrl: './faleconosco.component.html',
  styleUrls: ['./faleconosco.component.css']
})
export class FaleConoscoComponent {
  perguntasFrequentes: any[] = [];

  perguntaActive: number = 0;
  subperguntaActive: number = 0;

  constructor(
    private http: HttpClient
  ) {}

  ngOnInit(){
    this.http.get(environment.baseWP + '/wp-json/perguntas-frequentes/v1/perguntas-frequentes').subscribe((result: any) => {
      this.perguntasFrequentes = result;
    });
  }

  removeRichText(text: string){
    return text.replace(/<\/?[^>]+(>|$)/g, '');
  }

  limitText(text: string, count: number){
    return text.substr(0, count) + (text.length > count ? '...' : '');
  }

  getObjectKeys(obj: object){
    return Object.keys(obj);
  }

  toggleSubperguntaActive(i: number, index: number){
    if(i == this.perguntaActive && index == this.subperguntaActive){
      this.perguntaActive = -1;
      this.subperguntaActive = -1;
    }else{
      this.perguntaActive = i;
      this.subperguntaActive = index; 
    }
  }
}
