<section class="form-agendamento">
    <div class="container">
      <div class="row">
        <div class="col-12">
  
          <form class="row  needs-validation" novalidate>
  
            <div *ngIf="!erroPagamento">
                <div class="box">
                    <h6 class="light-title">Dados do(a) solicitante</h6>
                    <p class="no-margin">Nome: <span>{{getField('section1','nomecompleto')}}</span></p>
                    <p class="no-margin">CPF: <span>{{getField('section1','cpf')}}</span></p>
                    <p class="no-margin">Nascimento: <span>{{getField('section1','nascimento')}}</span></p>
                    <p class="no-margin">E-mail: <span>{{getField('section1','email')}}</span></p>
                    <p class="no-margin">Celular: <span>{{getField('section1','celular')}}</span></p>
                </div>

                <div class="info-box">
                    <p>Você terá acesso às vagas de agendamento após a confirmação de pagamento do boleto (DAE). O tempo estimado para a confirmação é de 24 horas após o pagamento.</p><br>
                    <p>Após a confirmação do pagamento, retorne ao agendamento, preencha os seus dados para realizar o agendamento do atendimento.</p>
                </div>
            </div>

            <div *ngIf="erroPagamento">
                {{erroPagamento}}
            </div>
          
            <div class="col-12">
              <hr>
              <div class="buttons">
                <a class="btn btn-form-return" (click)="navigate('back')">Voltar</a>
                <button class="btn btn-form-submit" *ngIf="!erroPagamento" (click)="togglePopup('aviso-pagamento')">Baixar boleto</button>
              </div>
            </div>
  
          </form>
  
        </div>
      </div>
    </div>
  </section>
  
<app-modal *ngIf="modal == 'aviso-pagamento'">
  <div class="m-title">
    <img src="../../../../assets/image/icons/icon-check.png" alt="" srcset="">
  </div>
  <div class="m-body m-body-servico-pagamento">
    <h3 class="text-center text-medium">Efetuar pagamento</h3>
    <p *ngIf="servico" [innerHTML]="formatText(servico.valor)"></p>
  </div>
  <div class="m-action m-action-servico-pagamento">
    <a (click)="togglePopup('')" class="btn-back">Voltar</a>
    <a (click)="imprimirBoleto();togglePopup('aviso-agendamento')" class="btn-form-submit"><img src="../../../../assets/image/icons/icon-download.png">Baixar boleto</a>
  </div>
</app-modal>

<app-modal *ngIf="modal == 'aviso-agendamento'">
  <div class="m-title">
    <img src="../../../../assets/image/icons/icon-check.png" alt="" srcset="">
  </div>
  <div class="m-body m-body-servico-aviso-agendamento">
    <h3 class="text-center text-medium">Efetuar pagamento</h3>
    <p>Seu boleto foi baixado!<br>Verifique sua pasta de downloads ou abra o gerenciador de arquivos para acessá-lo.</p>
  </div>
  <div class="m-action m-action-servico-aviso-agendamento">
    <a (click)="togglePopup('')" class="btn-back">Voltar</a>
    <a href="/" class="btn-form-submit">Acompanhar agendamento</a>
  </div>
</app-modal>