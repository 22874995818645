<section class="blue-section template-default">
    <div class="container">
        <div class="desktop-flex-left-center desktop-mb-88">
            <img class="mr-15" src="../../../assets/image/ligadigital-logo.svg">
            <img src="../../../assets/image/ligadigital-texto.svg">
            <p class="color-white desktop-ml-125">
                A LIGA DIGITAL surgiu como movimento de mudança proposto pela
                Secretaria Executiva de Transformação Digital (SETD), da Secretaria de 
                Administração (SAD), para enfrentar desafios de Governo Digital que vão além da tecnologia.
                <br><br>
                O movimento, em parceria com a Agência de Tecnologia de Informação de Pernambuco (ATI-PE), quer
                iniciar um novo ciclo de mudanças, que envolva processos e pessoas para promover uma transformação 
                cultural profunda no governo.
            </p>
        </div>
        <div>
            <div class="cardlist-default desktop-negative-bottom">
                <div class="card-default">
                    <img src="../../../assets/image/o-que-ha-de-novo-1.png" alt="Image">
                    <div class="card-default-title">Bases de estratégia e governança</div>
                    <div class="card-default-content">
                        A LIGA DIGITAL atua dentro dos princípios e do Plano de Ação da 3ª edição da <a href="https://egd.pe.gov.br/" target="_blank">Estratégia de Governo Digital (EGD)</a>, que reúne diretrizes e objetivos estratégicos para o Governo de Pernambuco no período de 2024 a 2027.
                        <br>
                        O documento foi aprovado pela Câmara de Governo Digital (CGD), criada em 2022, e ativada em 2024 pela Secretaria Executiva de Transformação Digital (SETD/SAD).
                    </div>
                </div>
                <div class="card-default">
                    <img src="../../../assets/image/o-que-ha-de-novo-2.png" alt="Image">
                    <div class="card-default-title">Central de Soluções</div>
                    <div class="card-default-content">
                        Inspirada no mercado de tecnologia e de startups, a LIGA DIGITAL conecta órgãos estaduais com desafios que possam ser resolvidos com soluções de governo digital.
                        <br>
                        Para isso, utiliza novos formatos de aceleração de projetos e equipes, como incubação de times e mentorias em grupo, sempre visando otimizar custos e agilizar a multiplicação de boas práticas.
                    </div>
                </div>
                <div class="card-default">
                    <img src="../../../assets/image/o-que-ha-de-novo-3.png" alt="Image">
                    <div class="card-default-title">Central de Soluções</div>
                    <div class="card-default-content">
                        A comunidade interna LIGA DIGITAL conecta agentes públicos que atuam sob o tema de governo digital.
                        <br>
                        A comunidade busca criar um novo ciclo de protagonismo, capaz de trazer mudanças profundas em processos e pessoas.
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<div class="desktop-spacer-negative-bottom"></div>
<section class="section-default">
    <h3>Nossa comunidade</h3>
    <p>Dezenas de agentes públicos de mais de 20 órgãos já estão conectados com a LIGA DIGITAL.</p>
    <!-- <a href="/estrategia-governo-pe" class="btn-primary-default">Decreto completo</a> -->
</section>
<div class="grid-default">
    <div class="grid-default-item">
        <img src="../../../assets/image/liga-digital/liga0.jpg" alt="Imagem Liga Digital">
    </div>
    <div class="grid-default-item">
        <img src="../../../assets/image/liga-digital/liga1.jpg" alt="Imagem Liga Digital">
    </div>
    <div class="grid-default-item">
        <img src="../../../assets/image/liga-digital/liga2.jpg" alt="Imagem Liga Digital">
    </div>
    <div class="grid-default-item">
        <img src="../../../assets/image/liga-digital/liga3.jpg" alt="Imagem Liga Digital">
    </div>
    <div class="grid-default-item">
        <img src="../../../assets/image/liga-digital/liga4.jpg" alt="Imagem Liga Digital">
    </div>
    <div class="grid-default-item">
        <img src="../../../assets/image/liga-digital/liga5.jpg" alt="Imagem Liga Digital">
    </div>
</div>