import { Component, Input, Output, ViewChild } from '@angular/core';
import { FormService } from '../../../services/form.service';
import { AgendamentoService } from '../../../services/agendamento.service';
import { Observable, Subject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import {HttpClient} from '@angular/common/http';

@Component({
  selector: 'app-dependentes',
  templateUrl: './dependentes.component.html',
  styleUrls: ['./dependentes.component.css']
})
export class DependentesComponent {
  @Input() OnSubmit: Function = (fields?: any) => {};
  @Input() OnNavigate: Function = (fields?: any) => {};
  @Input() FormName: string = '';
  @Input() FormData: { [key: string]: FormService } = {};

  captchaSolved: boolean = false;

  form: FormService[] = [];

  helper: AgendamentoService = new AgendamentoService(this.http);

  parametroServico: any = {};

  maxDependentes: number = 1;

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
  ) {}

  novoDependente(): void{
    if(this.form.length + 1 >= this.maxDependentes){
      return alert(`O número máximo de pessoas para esse serviço é ${this.maxDependentes}.`);
    }
    var formIndex = this.form.length;
    this.form[formIndex] = new FormService();
    this.form[formIndex].field('nomecompleto','text','Preencha esse campo.');
    this.form[formIndex].field('cpf','cpf','Insira um CPF válido.','cpf');
    this.form[formIndex].field('nascimento','text','Preencha esse campo.','birth');
  }

  removerDependente(formIndex: number){
    this.form = this.form.slice(0,formIndex).concat(this.form.slice(formIndex + 1,this.form.length));
  }

  navigate(name: string){
    this.OnNavigate(name);
  }

  submit(){
    var isValid = true;
    for(let form of this.form){
      if(!form.validate() || !this.ValidateCPFs()){
        isValid = false;
      }
    }
    if(isValid){
      this.OnSubmit(this.form, this.FormName);
    }
  }

  mask(){
    this.form.forEach((key, i) => this.form[i].updateMasks());
  }

  ValidateCPFs(){
    const  requerenteCPF = this.FormData['section1'].fields['cpf'].replace(/\D/g,'');
    var listaCPFs = [requerenteCPF];
    var isValid = true;
    this.form.forEach((form: FormService) => {
      const dependenteCPF = form.fields['cpf'].replace(/\D/g,'');
      if(listaCPFs.includes(dependenteCPF)){
        form.message['cpf'] = 'CPF já utilizado por outro requerente';
        isValid = false;
      }
      listaCPFs.push(dependenteCPF);
    });
    return isValid;
  }

  onCaptchaSolve(token: any){
    if(token === null){
      this.captchaSolved = false;
    }else{
      this.captchaSolved = true;
    }
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      const id = params['id'];
      this.parametroServico = this.helper.getAgendamentoParametroServico(id);
      this.parametroServico.subscribe((value: any) => {
        if(value && value.id){
          this.maxDependentes = value.maximoPorAgendamento;
        }
      });
    });
  }
}
