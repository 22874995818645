import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import { CacheService } from './cache.service';

@Injectable({
  providedIn: 'root'
})
export class ServicosService {

  constructor(private http: HttpClient) { }

  cache: CacheService = new CacheService();
   
  getDestaqueServicos(){
    return this.http.get(`${environment.baseAPI}${encodeURI(environment.destaqueServicos)}`);
  }

  getCachedDestaqueServicos(){ 
    // return this.cache.getCachedObserverServicosDestaque(() => {
      return this.http.get(`${environment.baseAPI}${encodeURI(environment.destaqueServicos)}`);
    // });
  }

  getServicosPorTema(id: string){
    return this.http.get(`${environment.baseAPI}${encodeURI(environment.servicosPorTema)}${id}`);
  }

  getServicosPorOrgao(id: string){
    return this.http.get(`${environment.baseAPI}${environment.listaServicosOrgao}/${id}?apiKey=${environment.apikey}`);
  }

  getTemas(){
    return this.http.get(`${environment.baseAPI}${encodeURI(environment.tema)}`);
  }
}
