<section class="content-body">
    <div class="container">
          <div class="row">
              <div class="col-12 mb-17">
                  <div [class]="responsiveState == 'desktop' ? 'info-section' : 'border info-section'">
                    <div>
                      <h6>Dados do(a) solicitante</h6>
                      <p>Nome: <span>{{getField('section1', 'nomecompleto')}}</span></p>
                      <p>CPF: <span>{{getField('section1', 'cpf')}}</span> </p>
                      <p>Nascimento: <span>{{getField('section1', 'nascimento')}}</span></p>
                      <p>Email: <span>{{getField('section1', 'email')}}</span></p>
                      <p>Celular: <span>{{getField('section1', 'celular')}}</span></p>
                      <div class="spacer-2"></div>
                    </div>
                    <div>
                      <h6>Dados de atendimento</h6>
                      <h5>{{Servico.nome}}</h5>
                      <p>Município: <span>{{getField('section3', 'municipio')}}</span></p>
                      <p>Local de atendimento: <span>{{getField('section3', 'unidadenome')}}</span></p>
                      <p>Endereço: <span>{{getField('section3', 'unidadeendereco')}}</span></p>
                      <p>Data: <span>{{getField('section3', 'dataatendimento')}}</span></p>
                      <p>Horário: <span>{{getField('section3', 'horario')}}</span></p>
                    </div>
                  </div>
              </div>

              <re-captcha *ngIf="!SessionValid" (resolved)="onCaptchaSolve($event)"></re-captcha>

              <div class="col-12">
                  <hr>
                  <div class="box-buttons">
                    <a class="btn btn-form-return" (click)="navigate('back')">Voltar</a>
                    <button class="btn btn-form-submit" (click)="submit()">Confirmar agendamento</button>
                  </div>
              </div>
          </div>
      </div>
</section>

