import { FormService } from '../../../services/form.service';
import { ContatoService } from '../../../services/contato.service';
import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {HttpClient} from '@angular/common/http';
import { EventsService } from '../../../services/events.service';

@Component({
  selector: 'app-contato-form',
  templateUrl: './contato-form.component.html',
  styleUrls: ['./contato-form.component.css']
})
export class ContatoFormComponent {
  
  result: any = { confirmed: false, submitted: false };

  erroMensagem: string = '';

  constructor(
    private route: ActivatedRoute,
    private http: HttpClient,
    private eventsService: EventsService
  ) {}

  helper: ContatoService = new ContatoService(this.http);

  form: FormService = new FormService();

  ngOnInit(){
    this.form.field('remetente','text:100','Para continuar inclua esta informação, ela é obrigatória.');
    this.form.field('email','text:100','Para continuar inclua esta informação, ela é obrigatória.');
    this.form.field('tipo','date','Selecione um assunto válido.');
    this.form.field('assunto','empty:100','');
    this.form.field('mensagem','text:2000','Para continuar inclua esta informação, ela é obrigatória.');
  }

  formSubmit(){
    this.erroMensagem = '';
    this.form.fields['assunto'] = this.form.fields['tipo'];
    if(this.form.validate()){
      this.eventsService.emit('loader-toggle', true);
      this.helper.contato(this.form.fields).subscribe((result: any) => {
        this.eventsService.emit('loader-toggle', false);
        this.result.submitted = true;
        this.result.confirmed = false;
        if(result == 'Email enviado com sucesso'){
          this.result.confirmed = true;
        }else{
          const erro = this.getResultError(result);
          if(erro == 'O Tipo informado não é válido'){
            this.result.submitted = false;
            this.form.message['tipo'] = erro;
          }else{
            this.erroMensagem =  erro || 'Tente novamente mais tarde.';
          }
        }
      });
    }
  }

  getResultError(result: any){
    if(result.error){
      var error = JSON.parse(result.error);
      try{
        if(Array.isArray(error) && error[0]){
          return error[0].error;
        }
      }
      catch(e){
        return false;
      }
    }
  }

  OnAssuntoSelect(assunto: string){
    this.form.fields['tipo'] = assunto;
  }
}
