<app-modal *ngIf="formResult.submitted && formResult.confirmed">
    <div class="m-title">
      <img src="../../../../assets/image/icons/icon-check.png" alt="" srcset="">
    </div>
    <div class="m-body">
      <h3 class="text-center mb-3">Reagendamento de serviço confirmado</h3>
      <p>Enviamos para o seu e-mail que você cadastrou anteriormente o número do seu processo. Você pode usar esse número para consultar, cancelar ou reagendar o serviço.</p>
      <p>A lista de documentos que devem ser apresentados no balcão de atendimento também foi enviada.</p>
    </div>
    <div class="m-action" *ngIf="agendamento.horarioAgenda">
      <a href="/agendamento/documentos/{{servico.id}}" class="btn-form-submit">Consultar instruções de atendimento</a>
    </div>
</app-modal>

<app-modal *ngIf="formErrorPopup != ''">
    <div class="m-title">
      <img src="../../../../assets/image/icons/icon-close.png" alt="" srcset="">
    </div>
    <div class="m-body">
      <h3 class="text-center mb-3">Houve um erro no seu agendamento</h3>
      <p>{{formErrorPopup}}</p>
    </div>
    <div class="m-action">
      <a (click)="closeAllPopups()" class="btn-form-submit">Voltar</a>
    </div>
</app-modal>





<app-confirmar-agendamento
    [OnNavigate]="navigate.bind(this)"
    [OnSubmit]="submit.bind(this)"
    [FormName]="'remarcacao'"
    [FormData]="formData"
    [SessionValid]="true"
    [Protocolo]="protocolo"
    [Servico]="servico"
></app-confirmar-agendamento>