import { Component } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { AgendamentoService } from '../../../services/agendamento.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-consulta-processo',
  templateUrl: './consulta-processo.component.html',
  styleUrls: ['./consulta-processo.component.css']
})
export class ConsultaProcessoComponent {
  constructor(
    private route: ActivatedRoute,
    private http: HttpClient
  ) {}

  protocolo: string = '';
  nascimento: string = '';
  processo: string = '';

  tempoMinimoUnidade: string = '';

  agendamento: any = {};

  listEmpty: boolean = false;

  isLoading: boolean = true;
  
  helper: AgendamentoService = new AgendamentoService(this.http);
  
  ngOnInit(){
    this.route.params.subscribe(params => {
      this.protocolo = params['protocolo'];
      this.nascimento = params['nascimento'];
      this.processo = params['processo'];
      this.helper.consultaAgendamento({ protocolo: this.protocolo, nascimento: this.nascimento })
      .subscribe((result: any) => {
        this.isLoading = false;
        if(result.resultado){
          for (const item of result.resultado) {
            if(item.id == this.processo){
              this.agendamento = item;
              this.gotUnidadeAgendamento(this.agendamento.horarioAgenda.servicoUnidade.unidadeAtendimento);
            }
          }
          if(result.resultado.length == 0){
            this.listEmpty = true;
          }
        }else{
          this.listEmpty = true;
        }
      });
    });
  }

  gotUnidadeAgendamento(unidade: any){
    this.helper.getUnidadeHorarioCancelamento(unidade.id).subscribe((result: any) => {
      if(result){
        this.tempoMinimoUnidade = result;
      }
    });
  }

  formatarDataHora(data: string){
    const dataInfo: any = {
      data: this.formatarData(data),
      horario: this.formatarHorario(data),
    };
    return `${dataInfo.data} ${dataInfo.horario}`;
  }

  formatarData(data: string){
    const dataParsed = new Date(data);
    const dataInfo: any = {
      year: dataParsed.getFullYear(),
      month: dataParsed.getMonth() + 1,
      day: dataParsed.getDate(),
    };
    if(dataInfo.day.toString().length == 1){
      dataInfo.day = '0' + dataInfo.day;
    }
    if(dataInfo.month.toString().length == 1){
      dataInfo.month = '0' + dataInfo.month;
    }
    return `${dataInfo.day}/${dataInfo.month}/${dataInfo.year}`;
  }

  formatarHorario(data: string){
    const dataParsed = new Date(data);
    const dataInfo: any = {
      hour: dataParsed.getHours(),
      minute: dataParsed.getMinutes(),
    };
    return `${dataInfo.hour}:${dataInfo.minute}`;
  }
  
  isHoraAnteriorValida() {
    let dataAgendamento = new Date(this.agendamento.horarioAgenda.horario);
    let tempoMinimo = this.tempoMinimoUnidade;
    let today = new Date();
    if (tempoMinimo) {
        const tempo = tempoMinimo.split(':');
        let horaMinima = new Date();
        horaMinima.setHours(today.getHours() + Number(tempo[0]));
        horaMinima.setMinutes(today.getMinutes() + Number(tempo[1]));
        return horaMinima < dataAgendamento;
    }
    return true;
  }

  isEnabled(action: string){
    if(action == 'reagendar' && this.isHoraAnteriorValida()){
      return this.agendamento.status == 'AGENDADO' || this.agendamento.status == 'A_CONFIRMAR';
    }
    if(action == 'cancelar' && this.isHoraAnteriorValida()){
      return this.agendamento.status == 'AGENDADO' || this.agendamento.status == 'A_CONFIRMAR';
    }
    if(action == 'texto-agendado'){
      return this.agendamento.status == 'AGENDADO' || this.agendamento.status == 'A_CONFIRMAR';
    }
    if(action == 'lista-documentos-btn'){
      return this.agendamento.status == 'AGENDADO' || this.agendamento.status == 'A_CONFIRMAR';
    }
    if(action == 'voltar-btn'){
      return this.agendamento.status == 'CANCELADO' || this.agendamento.status == 'CANCELADO_USUARIO';
    }
    return false;
  }
}
