import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EventsService {

  constructor() { }

  events: any[] = [];

  when(when: string, event: any){
    this.events.push({ when, event  });
  }

  emit(name: string, data: any){
    this.events.forEach(event => {
      if(name == event.when){
        event.event(data);
      }
    });
  }

}
