import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AgendamentoService } from 'src/app/services/agendamento.service';
import { HttpClient } from '@angular/common/http';
import { EventsService } from '../../../services/events.service';

@Component({
  selector: 'app-remarcacao',
  templateUrl: './remarcacao.component.html',
  styleUrls: ['./remarcacao.component.css']
})
export class RemarcacaoComponent {

  agendamento: any = {};
  
  formData: any = {};

  servico: any = {};

  protocolo: any = '';

  helper: AgendamentoService = new AgendamentoService(this.http);

  formResult: any = { submitted: false, confirmed: false };

  formErrorPopup: string = '';

  constructor(
    private route: ActivatedRoute,
    private http: HttpClient,
    private eventsService: EventsService
  ){}

  ngOnInit(){
    this.route.params.subscribe(params => {
      this.protocolo = params['protocolo'];
      this.helper.getSingleAgendamento(this.protocolo)
      .subscribe((agendamento: any) => {
        this.agendamento = agendamento;
        this.servico = agendamento.horarioAgenda.servicoUnidade.servico;
        this.eventsService.emit('breadcrumb-update', 'Agendamento > <a href="/#acompanhar-agendamento">Acompanhar agendamento</a> > ' + this.servico.nome);
        this.formData = {
          section1:{
            fields: {
              nomecompleto: agendamento.pessoa.nome,
              nascimento: agendamento.pessoa.nascimento,
              cpf: agendamento.pessoa.cpf,
              email: agendamento.pessoa.email,
              celular: agendamento.pessoa.telefoneCelular,
            },
          },
          section3:{
            fields: {
              municipio: agendamento.horarioAgenda.servicoUnidade.unidadeAtendimento.endereco.municipio,
              unidadenome: agendamento.horarioAgenda.servicoUnidade.unidadeAtendimento.nome,
              horario: agendamento.horarioAgenda,
              dataatendimento: agendamento.horarioAgenda.horario,
              unidadeendereco: agendamento.horarioAgenda.servicoUnidade.unidadeAtendimento,
            },
          },
        };
      });
    });
  }

  submit(){
    this.formResult = { submitted: false, confirmed: false };
    this.formErrorPopup = '';
    this.eventsService.emit('loader-toggle', true);
    this.helper.confirmarAgendamentoSemToken(this.agendamento).subscribe((result: any) => {
      this.eventsService.emit('loader-toggle', false);
      this.formErrorPopup = this.getErrorMessage(result);
      if(this.formErrorPopup){
        this.formResult = { submitted: true, confirmed: false };
      }else{
        this.formResult = { submitted: true, confirmed: true };
      }
    });
  }

  navigate(name: string){
    window.history.back();
  }

  getErrorMessage(result: any){
    if(Array.isArray(result)){
      if(result[0] && result[0].error){
        return result[0].error;
      }
      return '';
    }
    return '';
  }

  closeAllPopups(){
    this.formResult = { confirmed: false, submitted: false };
    this.formErrorPopup = "";
  }
}
