<div class="indicador-servicos">
    <div class="title">Indicador de serviços digitais</div>
    <div class="splitter">
        <div>
            <div class="description">Serviços no Portal</div>
            <div class="indicator">{{options.total}}</div>
        </div>
        <div>
            <div class="description">Serviços digitais</div>
            <div class="indicator">{{options.digitais}}</div>
        </div>
    </div>
</div>