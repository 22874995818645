<div [class]="responsiveState == 'desktop' ? 'container' : ''">
    <div class="informacoes-agendamento pt-20" *ngIf="agendamento && agendamento.servicosOnline">
        <h4>{{ agendamento.nome }}</h4>
        <p class="orgao" *ngIf="agendamento">{{agendamento.categoria.orgao.nome}}</p>
        <div class="servico-digital" *ngIf="agendamento.totalmenteOnline">Serviço digital</div>
        <a href="/agendamento/agendar/{{servicoID}}" *ngIf="agendamentoEnabled(agendamento.id)" class="btn btn-primary">Agendar</a>
        <a href="{{agendamento.servicosOnline[0].url}}" *ngIf="agendamento.servicosOnline[0] && !agendamentoEnabled(agendamento.id)" class="btn btn-primary">Começar agora</a>
        <a href="/" *ngIf="!agendamento.servicosOnline[0] && !agendamentoEnabled(agendamento.id)" class="btn btn-primary">Voltar para o início</a>
    </div>
    <hr class="mb-custom">
    <div class="informacoes-agendamento">
        <div class="social-share">
            <p>Compartilhe:</p>
            <a href="#" (click)="ClickShare($event)">
                <img src="../../../../assets/image/icons/icon-share.png" alt="Compartilhar">
            </a>
            <a href="#"  (click)="ClickPrint($event)" class="left-marker">
                <img src="../../../../assets/image/icons/icon-social-print.png" alt="Print">
            </a>
        </div>
        <div [class]="'informacoes-info info-mt ' + (accordionStatus[0] ? 'active' : '')" (click)="toggleAccordion(0)" *ngIf="agendamento.descricao">
            <div class="info">
                <div class="info-header">
                    <div class="opener"><img src="../../../../assets/image/icons/arrow-more.png"></div>
                    <p>O que é?</p>
                </div>
                <div class="info-body richtext-font-1" [innerHTML]="agendamento.descricao"></div>
            </div>
        </div>
        <div [class]="'informacoes-info info-mt ' + (accordionStatus[4] ? 'active' : '')" (click)="toggleAccordion(4)" *ngIf="agendamento.requisitos">
            <div class="info">
                <div class="info-header">
                    <div class="opener"><img src="../../../../assets/image/icons/arrow-more.png"></div>
                    <p>Como fazer?</p>
                </div>
                <div class="info-body richtext-font-1" [innerHTML]="agendamento.requisitos"></div>
            </div>
        </div>
        <div [class]="'informacoes-info info-mt ' + (accordionStatus[1] ? 'active' : '')" (click)="toggleAccordion(1)">
            <div class="info">
                <div class="info-header">
                    <div class="opener"><img src="../../../../assets/image/icons/arrow-more.png"></div>
                    <p>Quanto custa?</p>
                </div>
                <div class="info-body richtext-font-1" [innerHTML]="agendamento.valor || 'Serviço-gratuito'"></div>
            </div>
        </div>
        <div [class]="'informacoes-info info-mt ' + (accordionStatus[2] ? 'active' : '')" (click)="toggleAccordion(2)">
            <div class="info">
                <div class="info-header">
                    <div class="opener"><img src="../../../../assets/image/icons/arrow-more.png"></div>
                    <p>Quanto tempo leva?</p>
                </div>
                <div class="info-body richtext-font-1" [innerHTML]="agendamento.tempoEntrega || 'Aguardando Atualização'"></div>
            </div>
        </div>
        <div [class]="'informacoes-info info-mt ' + (accordionStatus[3] ? 'active' : '')" (click)="toggleAccordion(3)" *ngIf="agendamento.outrasInformacoes">
            <div class="info">
                <div class="info-header">
                    <div class="opener"><img src="../../../../assets/image/icons/arrow-more.png"></div>
                    <p>Outros detalhes</p>
                </div>
                <div class="info-body richtext-font-1" [innerHTML]="agendamento.outrasInformacoes"></div>
            </div>
        </div>
    </div>
    <hr>
    <app-canais-atendimento [Servico]="agendamento" *ngIf="agendamento.id"></app-canais-atendimento>
    <div class="container avaliacao-servico">
        <div class="row">
            <div class="subtitle">Este conteúdo foi útil para você?</div>
            <div class="actions">
                <button (click)="sendAvaliacaoServico(1)" [class]="minhaAvaliacao === 1 ? 'active' : ''">
                    <img src="../../../../assets/image/icons/icon-like-up.png">
                    <div>{{minhaAvaliacao > -1 || negativeVoteForm ? "("+avaliacoes.positivas+")" : "sim"}}</div>
                </button>
                <button (click)="showNegativeVoteForm()" [class]="minhaAvaliacao === 0 || negativeVoteForm ? 'active' : ''">
                    <img src="../../../../assets/image/icons/icon-like-down.png">
                    <div>{{minhaAvaliacao > -1 || negativeVoteForm ? "("+avaliacoes.negativas+")" : "não"}}</div>
                </button>
            </div>
            <div class="avaliacao-aviso-agradecimento" *ngIf="showAvaliacaoConcluida && !showAvaliacaoJaRealizada">
                <p>Agradecemos a sua contribuição para melhoria do Portal!</p>
            </div>
            <div class="avaliacao-aviso-ja-realizada" *ngIf="showAvaliacaoJaRealizada">
                <img src="../../../../assets/image/icons/warning.png" alt="Aviso ícone"/>
                <p>Sua avaliação já foi realizada</p>
            </div>
            <div class="vote-form" *ngIf="negativeVoteForm">
                <div class="label" *ngFor="let item of motivosAvaliacao;let i = index">
                    <input type="radio" name="negative-option" (change)="toggleOption(i)" [id]="'negative-option-' + i">
                    <label (click)="toggleOption(i)">{{item.descricao}}</label>
                </div>
                <textarea *ngIf="motivosAvaliacao[option].descricao == 'Outros'" [(ngModel)]="outroMotivo"></textarea>
                <p class="textarea-message" *ngIf="motivosAvaliacao[option].descricao == 'Outros'">Descreva em 200 caracteres o seu motivo de insatisfação</p>
                <button class="btn btn-sumbit" (click)="SendNegativeForm()">Enviar avalição de conteúdo</button>
            </div>
        </div>
    </div>

    <!-- <div class="container" [hidden]="true">
        <div class="row">
            <div class="col">
                <div class="box">
                    <div class="title">Mais informações do serviço</div>
                    <div *ngIf="tipoAgendamento | async as agendamento; else loading">
                        <div class="accordion accordion-flush" id="accordionAgendamento">
                            <div class="accordion-item">
                            <h2 class="accordion-header" id="flush-headingThree">
                                <button class="accordion-button uppercase" type="button" data-bs-toggle="collapse" [attr.data-bs-target]="'#flush-collapse0'" aria-expanded="true" aria-controls="flush-collapseThree">
                                    O que este serviço oferece:
                                </button>
                            </h2>
                            <div id="flush-collapse0" class="accordion-collapse collapse show" aria-labelledby="flush-headingThree" data-bs-parent="#accordionAgendamento">
                                <div class="accordion-body">
                                    <div class="main">
                                        <p [innerHTML]="agendamento.descricao"></p>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div class="accordion accordion-flush" id="accordionAgendamento" [hidden]="!agendamento.requisitos">
                            <div class="accordion-item">
                            <h2 class="accordion-header" id="flush-headingThree">
                                <button class="accordion-button uppercase" type="button" data-bs-toggle="collapse" [attr.data-bs-target]="'#flush-collapse1'" aria-expanded="true" aria-controls="flush-collapseThree">
                                    Como fazer:
                                </button>
                            </h2>
                            <div id="flush-collapse1" class="accordion-collapse collapse show" aria-labelledby="flush-headingThree" data-bs-parent="#accordionAgendamento">
                                <div class="accordion-body">
                                    <div class="main">
                                        <p [innerHTML]="agendamento.requisitos"></p>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div class="accordion accordion-flush" id="accordionAgendamento">
                            <div class="accordion-item">
                            <h2 class="accordion-header" id="flush-headingThree">
                                <button class="accordion-button uppercase" type="button" data-bs-toggle="collapse" [attr.data-bs-target]="'#flush-collapse2'" aria-expanded="true" aria-controls="flush-collapseThree">
                                    Custo a tempo:
                                </button>
                            </h2>
                            <div id="flush-collapse2" class="accordion-collapse collapse show" aria-labelledby="flush-headingThree" data-bs-parent="#accordionAgendamento">
                                <div class="accordion-body">
                                    <div class="main">
                                        <p [innerHTML]="agendamento.tempoEntrega"></p>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        <hr>
                        <div class="buttons">
                            <a href="/agendamento/agendar/{{servicoID}}" *ngIf="agendamentoEnabled(agendamento.id)" class="btn btn-primary">Agendar</a>
                            <a href="{{agendamento.servicosOnline[0].url}}" *ngIf="agendamento.servicosOnline[0]" class="btn btn-primary">Começar agora</a>
                            <a href="/" *ngIf="!agendamento.servicosOnline[0] && !agendamentoEnabled(agendamento.id)" class="btn btn-primary">Voltar para o início</a>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div> -->
</div>