<section class="header-section">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="info">
            <div class="info-title flex">
              <a (click)="navigate('back')" class="cursor-pointer">
                <img src="../../../../assets/image/icons/icon-return.png" alt="Voltar" >
              </a>
              <h4>Cancelar atendimento</h4>
            </div>
            <a (click)="navigate('close')" class="cursor-pointer">
              <img src="../../../../assets/image/icons/icon-close.png" alt="Fechar" >
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>

  
  <div *ngIf="!isLoading && !listEmpty">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="aviso-reagendamento">
            <div>
              <img src="../../../../assets/image/icons/warning.png">
            </div>
            <div>
              <p class="title">Deseja cancelar o atendimento?</p>
              <p>Ao cancelar o agendamento, você poderá perder a reserva do seu horário e precisará reagendar conforme a disponibilidade.</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="row">
        <div class="col-12">Verifique se os dados abaixo correspondem ao  atendimento que deseja cancelar.</div>
      </div>
    </div>

    <div class="spacer"></div>

  </div>

  <p *ngIf="isLoading" class="center-p">Carregando...</p>

  <p *ngIf="!isLoading && listEmpty" class="center-p">Houve um erro ao obter os dados</p>
  

<div class="container">
    <div class="row">
        <div class="col">
            <div class="box" *ngIf="!isLoading && !listEmpty">
                <p class="subtext text-bold">Dados do(a) solicitante</p>
                <p class="subtext">Nome: {{agendamento.pessoa.nome}}</p>
                <p class="subtext">CPF: {{agendamento.pessoa.cpf}}</p>
                <p class="subtext">Nascimento: {{agendamento.pessoa.nascimento.split('-').reverse().join('/')}}</p>
                <p class="subtext">E-mail: {{agendamento.pessoa.email}}</p>
                <p class="subtext">Celular: {{agendamento.pessoa.telefoneCelular}}</p>
                <p class="subtext text-bold">Dados de atendimento</p>
                <p class="subtext text-medium">{{agendamento.horarioAgenda.servicoUnidade.servico.nome}}</p>
                <p class="subtext">Município: {{agendamento.horarioAgenda.servicoUnidade.unidadeAtendimento.endereco.municipio}} - {{agendamento.horarioAgenda.servicoUnidade.unidadeAtendimento.endereco.uf}}</p>
                <p class="subtext">Endereço: {{agendamento.horarioAgenda.servicoUnidade.unidadeAtendimento.endereco.logradouro}}, {{agendamento.horarioAgenda.servicoUnidade.unidadeAtendimento.endereco.numero}} - {{agendamento.horarioAgenda.servicoUnidade.unidadeAtendimento.endereco.bairro}}</p>
                <p class="subtext">CEP: {{agendamento.horarioAgenda.servicoUnidade.unidadeAtendimento.endereco.cep}}</p>
                <p class="subtext">Local de atendimento: {{agendamento.horarioAgenda.servicoUnidade.unidadeAtendimento.nome}}</p>
                <p class="subtext">Data: {{formatarData(agendamento.horarioAgenda.horario)}}</p>
                <p class="subtext">Horário: {{formatarHorario(agendamento.horarioAgenda.horario)}}</p>
            </div>
        </div>
    </div>
    <div class="spacer"></div>
    <re-captcha (resolved)="whenCaptchaSolved($event)"></re-captcha>
    <div>
        <p>{{captchaValidationMessage}}</p>
    </div>
    <div class="col-12">
        <hr>
        <div class="buttons flex-end">
            <a class="btn btn-form-return"  (click)="navigate('back')">Voltar</a>
            <button class="btn btn-form-submit btn-secondary" (click)="submit()">Cancelar atendimento</button>
        </div>
    </div>
</div>






<app-modal *ngIf="result.submitted && result.confirmed">
  <div class="m-title">
    <img src="../../../../assets/image/icons/icon-check.png" alt="" srcset="">
  </div>
  <div class="m-body">
    <h3 class="text-center mb-3">Agendamento cancelado</h3>
    <p>Seu atendimento foi cancelado. Um comprovante com detalhes sobre o atendimento cancelado foi enviado para o seu e-mail cadastrado.</p>
  </div>
  <div class="m-action" *ngIf="agendamento.horarioAgenda">
    <a href="/agendamentos/{{agendamento.pessoa.cpf}}/{{nascimento}}" class="btn-form-submit">Voltar</a>
  </div>
</app-modal>

<app-modal *ngIf="result.submitted && !result.confirmed">
  <div class="m-title">
    <img src="../../../../assets/image/icons/icon-check.png" alt="" srcset="">
  </div>
  <div class="m-body">
    <h3 class="text-center mb-3">Houve um erro no cancelamento do agendamento</h3>
    <p>Aguarde alguns minutos.</p>
    <p>Volte a tela de cancelamento e realize novamente o processo.</p>
  </div>
  <div class="m-action">
    <a href="/agendamentos/{{agendamento.pessoa.cpf}}/{{nascimento}}/{{agendamento.id}}" class="btn-form-submit">Voltar</a>
  </div>
</app-modal>