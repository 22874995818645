<div [class]="'container variation-' + variation">
    <div class="row">
        <div class="col">
            <!-- Modal -->
            <div class="modal fade show" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true" style="display:block">
            <div class="modal-dialog">
                <div class="modal-content">
                <div class="modal-header" [hidden]="!hasTitle">
                    <button type="button"  data-bs-dismiss="modal" aria-label="Close">
                        <ng-content select=".m-title"></ng-content>
                    </button>
                </div>
                <div class="modal-body">
                    <ng-content select=".m-body"></ng-content>
                </div>
                <ng-content select=".m-extra"></ng-content>
                <div class="modal-footer">
                    <!-- <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button> -->
                    <!-- <button type="button" class="btn btn-primary">Ver lista de documentos</button> -->
                    <ng-content select=".m-action"></ng-content>
                </div>
                </div>
            </div>
            </div>

        </div>
    </div>
</div>
<div class="modal-backdrop fade show"></div>