<app-modal *ngIf="termsPopupOpen">
    <div class="m-title">
      <img src="../../../../assets/image/icons/icon-pen.png" alt="" srcset="">
    </div>
    <div class="m-body">
      <h3 class="text-center mb-3">Boas vindas!</h3>
      <p>Não se preocupe, o processo é rápido e fácil. Vamos guiá-lo(a) passo a passo para garantir que tudo corra bem.</p>
      <div class="flex-gap">
        <input type="checkbox" name="termos" id="termos" (click)="toggleTerms($event)">
        <label for="termos">Declaro que li e aceito a <a href="/privacidade">Política de Privacidade e os Termos de Uso</a>.</label>
      </div>
    </div>
    <div class="m-action">
      <a (click)="closeAllPopups()" class="btn-form-submit">Cadastrar nos serviços do governo</a>
      <a href="/login" class="btn-form-submit">Voltar à tela de login</a>
    </div>
</app-modal>

<app-modal *ngIf="errorMessage != ''">
    <div class="m-title">
      <img src="../../../../assets/image/icons/icon-close.png" alt="" srcset="">
    </div>
    <div class="m-body">
      <h3 class="text-center mb-3">Houve um erro no cadastro</h3>
      <p [innerHTML]="errorMessage.replaceAll('\n','<br>')"></p>
    </div>
    <div class="m-action">
      <a (click)="closeAllPopups()" class="btn-form-submit">Voltar</a>
    </div>
</app-modal>

<app-modal *ngIf="formSuccess">
    <div class="m-title">
      <img src="../../../../assets/image/icons/icon-check.png" alt="" srcset="">
    </div>
    <div class="m-body">
      <h3 class="text-center mb-3">Confirme seu e-mail!</h3>
        <p>Para concluir seu cadastro, por favor confirme seu endereço de email.</p>
        <p>Enviamos um e-mail de confirmação para você. Clique no link de confirmação contido no email para ativar sua conta. </p>
        <p>Se você não recebeu o email de confirmação, verifique sua pasta de spam ou clique no botão abaixo para reenviarmos.</p>
    </div>
    <div class="m-action">
      <!-- <a (click)="reenviarEmailConfirmacao()" class="btn-form-submit">Reenviar e-mail de confirmação</a> -->
      <a href="/" class="btn-form-submit">Cancelar</a>
    </div>
</app-modal>


<section class="header-section">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="info">
            <div class="info-title">
              <a (click)="navigate('back')" class="cursor-pointer">
                <img src="../../../../assets/image/icons/icon-return.png" alt="Voltar" >
              </a>
              <h4>Cadastro</h4>
            </div>
            <a (click)="navigate('close')" class="cursor-pointer">
              <img src="../../../../assets/image/icons/icon-close.png" alt="Fechar" >
            </a>
          </div>
        </div>
      </div>
    </div>
</section>
<section class="form-agendamento">
    <div class="container">
      <div class="row">
        <div class="col-12">

          <div class="loader"><div class="loader-bar" [style]="'width:'+getFormLevel()+'%'"></div></div>
  
          <h6>Preencha os seguintes dados para criar sua conta no Portal PE Cidadão.</h6>
  
          <form class="row  needs-validation" novalidate *ngIf="currentForm == 'form1'">

            <div class="col-12">
                <fieldset class="reset {{form.message['cpf'] ? 'has-error' : ''}}" for="cpf">
                  <legend class="reset">CPF*</legend>
                  <input type="search" class="form-control" id="cpf" name="cpf" required [(ngModel)]="form.fields['cpf']" #ctrl="ngModel" (keyup)="mask()">
                </fieldset>
                <div class="error-label" *ngIf="form.message['cpf']">
                  {{form.message['cpf']}}
                </div>
            </div>
  
            <div class="col-12">
              <fieldset class="reset {{form.message['nomecompleto'] ? 'has-error' : ''}}" for="nome-completo">
                <legend class="reset">Nome completo*</legend>
                <input type="search" class="form-control" id="nome-completo" name="nome" required [(ngModel)]="form.fields['nomecompleto']" #ctrl="ngModel" (keyup)="mask()">
              </fieldset>
              <div class="error-label" *ngIf="form.message['nomecompleto']">
                {{form.message['nomecompleto']}}
              </div>
            </div>
  
            
  
            <div class="col-12">
              <fieldset class="reset {{form.message['nascimento'] ? 'has-error' : ''}}" for="nascimento">
                <legend class="reset">Data de nascimento*</legend>
                <input type="search" class="form-control" id="nascimento" name="nascimento" required [(ngModel)]="form.fields['nascimento']" #ctrl="ngModel" (keyup)="mask()">
              </fieldset>
              <div class="error-label" *ngIf="form.message['nascimento']">
                {{form.message['nascimento']}}
              </div>
            </div>
  
            <div class="col-12">
              <fieldset class="reset {{form.message['email'] ? 'has-error' : ''}}" for="email">
                <legend class="reset">E-mail*</legend>
                <input type="search" class="form-control" id="email" name="email" required [(ngModel)]="form.fields['email']" #ctrl="ngModel" (keyup)="mask()">
              </fieldset>
              <div class="error-label" *ngIf="form.message['email']">
                {{form.message['email']}}
              </div>
            </div>
  
            <div class="col-12">
              <div class="policies">
                <div class="form-check form-switch">
                  <input class="form-check-input" type="checkbox" name="autorizacao" id="autorizacao" checked="checked" [(ngModel)]="form.fields['autorizacao']" #ctrl="ngModel">
                  <label class="form-check-label label-autorizacao" for="autorizacao">Autorizo o envio de emails com nossas atualizações e serviços.</label>
                </div>
              </div>
            </div>
          
            <div class="col-12">
              <hr>
              <div class="buttons">
                <button class="btn btn-form-submit" (click)="submit()">Continuar</button>
              </div>
            </div>
  
          </form>
          <form class="row  needs-validation" novalidate *ngIf="currentForm == 'form2'">

            <div class="col-12">
                <fieldset class="reset {{passForm.message['senha'] ? 'has-error' : ''}}" for="senha">
                  <legend class="reset">Senha*</legend>
                  <input type="password" class="form-control" id="senha" name="senha" required [(ngModel)]="passForm.fields['senha']" #ctrl="ngModel" (keyup)="mask()">
                </fieldset>
                <div class="error-label" *ngIf="passForm.message['senha']">
                  {{passForm.message['senha']}}
                </div>
            </div>
    
            <div class="col-12">
                <fieldset class="reset {{passForm.message['confirmarsenha'] ? 'has-error' : ''}}" for="confirmarsenha">
                  <legend class="reset">Confirmar senha*</legend>
                  <input type="password" class="form-control" id="confirmarsenha" name="confirmarsenha" required [(ngModel)]="passForm.fields['confirmarsenha']" #ctrl="ngModel" (keyup)="mask()">
                </fieldset>
                <div class="error-label" *ngIf="passForm.message['confirmarsenha']">
                  {{passForm.message['confirmarsenha']}}
                </div>
            </div>
            
            <re-captcha (resolved)="onCaptchaSolve($event)"></re-captcha>

            <div class="col-12">
                <hr>
                <div class="buttons">
                  <button class="btn btn-form-submit" (click)="submit()">Continuar</button>
                </div>
            </div>
          </form>
  
        </div>
      </div>
    </div>
</section>