import { Component } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { AgendamentoService } from '../../../services/agendamento.service';
import { FormService } from '../../../services/form.service';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { TipoAgendamento } from 'src/app/models/model';
import { EventsService } from '../../../services/events.service';

@Component({
  selector: 'app-reagendamento-form',
  templateUrl: './reagendamento-form.component.html',
  styleUrls: ['./reagendamento-form.component.css']
})
export class ReagendamentoFormComponent {

  constructor(
    private route: ActivatedRoute,
    private http: HttpClient,
    private eventsService: EventsService
  ) {}

  protocolo: string = '';
  nascimento: string = '';
  processo: string = '';

  formErrorPopup: string = '';

  agendamento: any = {};

  agendamentoServico: any = {};

  listEmpty: boolean = false;

  isLoading: boolean = true;
  
  helper: AgendamentoService = new AgendamentoService(this.http);

  formData: { [key: string]: FormService } = {};

  listaMunicipio: string[] = [];

  result: any = { confirmed: false, submitted: false };

  sessionToken: string = '';

  currentForm: string = 'form1';

  allForms: any = {
    form1: {
      title: 'Reagendar local de atendimento',
      step: 2,
      description: 'Selecione local e agenda que deseja atendimento',
    },
    form2: {
      title: 'Confirmar nova agenda de atendimento',
      step: 3,
      description: 'Verifique se os dados abaixo estão corretos e confirme seu agendamento.',
    },
  };
  
  ngOnInit(){
    this.route.params.subscribe(params => {
      this.protocolo = params['protocolo'];
      this.nascimento = params['nascimento'];
      this.processo = params['processo'];
      this.helper.getAgendamento({ protocolo: this.protocolo })
      .subscribe((result: any) => {
        this.isLoading = false;
        if(result.protocolo){
          this.agendamento = result;
          this.agendamentoServico = this.agendamento.horarioAgenda.servicoUnidade.servico;
          this.eventsService.emit('breadcrumb-update', 'Agendamento > <a href="/#acompanhar-agendamento">Acompanhar agendamento</a> > ' + this.agendamentoServico.nome);
          const data: { [key: string]: any } = this.helper.getAgendamentoData(`${this.agendamento.horarioAgenda.servicoUnidade.servico.id}`);
          data['agendaUnidade'].subscribe((value: any) => {
            this.listaMunicipio = value;
          });
        }else{
          this.listEmpty = true;
        }
      });
    });
  }

  navigate(name: string){
    if(this.currentForm == 'form1' || this.currentForm == 'agendamento'){
      window.location.href = `/agendamentos/${this.agendamento.pessoa.cpf}/${this.nascimento}`;
    }else{
      this.currentForm = 'form1';
    }
  }

  submit(form: FormService, name: string){
    this.formData[name] = form;
    this.currentForm = 'form2';
  }

  confirm(){
    this.currentForm = 'form1';
    this.sendFormData();
  }

  sendFormData(){
    this.helper.sendReservaReagendamentoForm(this.formData, this.agendamento, this.sessionToken).subscribe((agendamento: any) => {
      this.formErrorPopup = this.getErrorMessage(agendamento);
      if(!this.formErrorPopup){
        // agendamento.versao = 2;
        // agendamento.pessoa.versao = 2;
        // agendamento.horarioAgenda.versao = 0;
        agendamento.infosAdicionaisAgendamentos = [];
        // agendamento.status = "RESERVADO";
        agendamento.horarioAgenda.servicoUnidade.servico = this.agendamento.horarioAgenda.servicoUnidade.servico;
        this.helper.sendReagendamentoForm(agendamento, this.sessionToken).subscribe((result: any) => {
          this.formErrorPopup = this.getErrorMessage(result);
          if(!this.formErrorPopup){
            this.result.submitted = true;
            this.result.confirmed = false;
            if(result.status){
              this.result.confirmed = true;
            }
          }
        });
      }
    });
  }

  OnCaptchaSolve(token: any){
    if(token === null){
      this.sessionToken = '';
    }else{
      this.helper.criarSessao(token, this.agendamento.horarioAgenda.servicoUnidade.servico.id).subscribe((data: any) => {
        this.formErrorPopup = this.getErrorMessage(data);
        if(data.token){
          this.sessionToken = data.token;
        }
      });
    }
  }

  getErrorMessage(result: any){
    if(Array.isArray(result)){
      if(result[0] && result[0].error){
        return result[0].error;
      }
      return '';
    }
    return '';
  }

  closeAllPopups(){
    this.result = { confirmed: false, submitted: false };
    this.formErrorPopup = "";
  }

  formatarDataHora(data: string){
    const dataInfo: any = {
      data: this.formatarData(data),
      horario: this.formatarHorario(data),
    };
    return `${dataInfo.data} ${dataInfo.horario}`;
  }

  formatarData(data: string){
    const dataParsed = new Date(data);
    const dataInfo: any = {
      year: dataParsed.getFullYear(),
      month: dataParsed.getMonth() + 1,
      day: dataParsed.getDate(),
    };
    if(dataInfo.day.toString().length == 1){
      dataInfo.day = '0' + dataInfo.day;
    }
    if(dataInfo.month.toString().length == 1){
      dataInfo.month = '0' + dataInfo.month;
    }
    return `${dataInfo.day}/${dataInfo.month}/${dataInfo.year}`;
  }

  formatarHorario(data: string){
    const dataParsed = new Date(data);
    const dataInfo: any = {
      hour: dataParsed.getHours(),
      minute: dataParsed.getMinutes(),
    };
    if(dataInfo.minute.toString().length === 1){
      dataInfo.minute = '0' + dataInfo.minute;
    }
    return `${dataInfo.hour}:${dataInfo.minute}`;
  }
}
