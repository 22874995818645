<section class="header-section">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="info">
            <div class="info-title flex">
              <a (click)="navigate('back')" class="cursor-pointer">
                <img src="../../../../assets/image/icons/icon-return.png" alt="Voltar" >
              </a>
              <h4 *ngIf="agendamento.horarioAgenda">{{agendamento.horarioAgenda.servicoUnidade.servico.nome}}</h4>
            </div>
            <a (click)="navigate('close')" class="cursor-pointer">
              <img src="../../../../assets/image/icons/icon-close.png" alt="Fechar" >
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
  
  <div>
    <app-modal *ngIf="result.submitted && result.confirmed">
      <div class="m-title">
        <img src="../../../../assets/image/icons/icon-check.png" alt="" srcset="">
      </div>
      <div class="m-body">
        <h3 class="text-center mb-3">Nova data não confirmada</h3>
        <p>Enviamos para o e-mail que você cadastrou anteriormente a confirmação.</p>
      </div>
      <div class="m-action" *ngIf="agendamento.horarioAgenda">
        <a href="/agendamentos/{{agendamento.pessoa.cpf}}/{{nascimento}}/{{agendamento.id}}" class="btn-form-submit">Voltar</a>
      </div>
    </app-modal>
  
    <app-modal *ngIf="result.submitted && !result.confirmed">
      <div class="m-title">
        <img src="../../../../assets/image/icons/icon-check.png" alt="" srcset="">
      </div>
      <div class="m-body">
        <h3 class="text-center mb-3">Houve um erro ao cancelar a nova data</h3>
        <p>Aguarde alguns minutos.</p>
        <p>Volte a tela de cancelamento e realize novamente o processo.</p>
      </div>
      <div class="m-action">
        <a href="/agendamentos/{{agendamento.pessoa.cpf}}/{{nascimento}}/{{agendamento.id}}" class="btn-form-submit">Voltar</a>
      </div>
    </app-modal>
  </div>

  <p *ngIf="isLoading" class="center-p">Carregando...</p>

  <p *ngIf="!isLoading && listEmpty" class="center-p">Houve um erro ao obter os dados</p>
  

<div class="container">
    <div class="row">
        <div class="col">
            <div class="box" *ngIf="!isLoading && !listEmpty">
                <div class="title">Não confirma a nova data?</div>
                <div></div>
                <p class="subtext">DADOS DE ATENDIMENTO</p>
                <p class="subtext">Município: {{agendamento.horarioAgenda.servicoUnidade.unidadeAtendimento.endereco.municipio}}</p>
                <p class="subtext">Local de atendimento: {{agendamento.horarioAgenda.servicoUnidade.unidadeAtendimento.nome}}</p>
                <p class="subtext">Data: {{formatarData(agendamento.horarioAgenda.horario)}}</p>
                <p class="subtext">Horário: {{formatarHorario(agendamento.horarioAgenda.horario)}}</p>
                <hr>
                <div class="protocolo-title">Número do protocolo:</div>
                <div class="protocolo">{{protocolo.toUpperCase()}}</div>
            </div>
        </div>
    </div>
    <div class="spacer"></div>
    <div class="col-12">
        <hr>
        <div class="buttons flex-end">
            <a class="btn btn-form-return"  (click)="navigate('back')">Voltar</a>
            <button class="btn btn-form-submit btn-secondary" (click)="submit()">Não confirmar</button>
        </div>
    </div>
</div>