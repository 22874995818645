import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import { TipoAgendamento } from '../models/model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ListaAgendamentoService {
  
  constructor(private http: HttpClient) { }
   
  getAll(){
    return this.http.get<TipoAgendamento[]>(environment.baseAPI + encodeURI(environment.agendamento) + '/?apikey=' + environment.apikey);
  }
  
  get(id: number): Observable<TipoAgendamento | undefined> {
    return this.http.get<TipoAgendamento>(environment.baseAPI + encodeURI(environment.servico) + id);
  }
}
