import { Component, ViewChildren } from '@angular/core';
import { FormService } from '../../../services/form.service';
import { AgendamentoService } from '../../../services/agendamento.service';
import {HttpClient} from '@angular/common/http';
import { ResponsiveService } from '../../../services/responsive.service';
import { EventsService } from '../../../services/events.service';

@Component({
  selector: 'app-agendamento-home',
  templateUrl: './agendamento-home.component.html',
  styleUrls: ['./agendamento-home.component.css']
})
export class AgendamentoHomeComponent {
  @ViewChildren('cpf') InputCPF: any;
  @ViewChildren('nascimento') InputNascimento: any;

  helper: AgendamentoService = new AgendamentoService(this.http);

  form: FormService = new FormService();

  formIsOpened: boolean = false;

  showProtocoloPopup: boolean = false;

  formProtocoloLoading: boolean = false;

  formProtocoloResult: any = { submitted: false, result: false };
  
  formProtocoloMessage: string = "";

  formEmail: string = "";

  formToken: string = "";

  formProtocoloErrorMessage: string = "";

  responsiveState: string = '';

  constructor(
    private http: HttpClient,
    private responsiveService: ResponsiveService,
    private eventsService: EventsService,
  ) {}

  ngAfterViewInit(){
    setTimeout(() => {
      this.InputCPF.first.nativeElement.value = '';
      this.InputNascimento.first.nativeElement.value = '';
    }, 100);
  }

  ngOnInit(){
    this.form.field('cpf','cpf','Insira um CPF válido.','cpf');
    this.form.field('nascimento','text','Insira uma data válida.','birth');
    this.initResponsiveState();
  }

  initResponsiveState(){
    this.responsiveService.onChange((state: string) => {
      if(state === 'tablet'){
        state = 'desktop';
      }
      this.responsiveState = state;
    });
  }

  mask(){
    this.form.updateMasks();
  }

  submit(){
    if((this.formIsOpened || this.responsiveState == 'desktop') && this.form.validate()){
      const cpf = this.form.fields['cpf'].replaceAll('/','-');
      const nascimento = this.form.fields['nascimento'].split('/').reverse().join('-');
      this.InputCPF.first.nativeElement.value = '';
      this.InputNascimento.first.nativeElement.value = '';
      window.location.href = `/agendamentos/${encodeURI(cpf)}/${encodeURI(nascimento)}`;
    }
    this.formIsOpened = true;
  }

  ShowProtocoloPopup(){
    this.showProtocoloPopup = true;
  }

  EnviarEmailProtocolo(){
    this.formProtocoloMessage = "";
    if(this.formEmail == ""){
      this.formProtocoloMessage = "Insira um e-mail válido.";
      return;
    }
    if(this.formToken == ""){
      return;
    }
    this.formProtocoloLoading = true;
    this.helper.sendEmailProtocolo(this.formEmail,this.formToken).subscribe((result: any) => {
      this.formProtocoloLoading = false;
      this.formProtocoloResult.submitted = true;
      if(result.error){
        this.formProtocoloResult.result = false;
        this.formProtocoloErrorMessage = result?.error[0]?.error || "Ocorreu um erro de conexão com o servidor. Tente novamente ou informe ao Administrador do Sistema</p><p>Ocorreu um erro de conexão com o servidor. Tente novamente ou informe ao Administrador do Sistema";
      }else{
        this.formProtocoloResult.result = true;
      }
    });
  }

  CloseErrorPopup(){
    this.formProtocoloResult.submitted = false;
    this.formProtocoloMessage = "";
    this.formProtocoloResult = { submitted: false, result: false };
  }

  CloseProtocoloForm(){
    this.showProtocoloPopup = false;
  }

  onCaptchaSolve(token: any){
    if(token){
      this.formToken = token;
    }else{
      this.formToken = "";
    }
  }

}
