import { Component, ContentChild, Renderer2, AfterContentInit, TemplateRef, ElementRef, Input } from '@angular/core';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.css']
})
export class ModalComponent {
  @Input() variation: string = 'default';

  constructor(private renderer: Renderer2, private el: ElementRef) { }

  hasTitle: boolean = false;

  ngOnInit(): void {
    this.renderer.addClass(document.body, 'modal-open');
  }

  ngAfterContentInit() {
    var childNode = this.el.nativeElement.childNodes[0];
    var titleNode = childNode.querySelector('.modal-header>button');
    if(titleNode.childElementCount > 0){
      this.hasTitle = true;
    }
  }
}
