import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class RouteService {

  constructor(private Location: Location, private router: Router) { }

  routeUrl: string = '';

  routePath: string = '';

  routeData: any = {};

  RulesToShowInitialMenu: String[] = ['', 'servicos', 'fale-conosco'];

  GetMenuType(){
    return this.RulesToShowInitialMenu.includes(this.getCurrentRoute().path) ? 'initial' : 'default';
  }

  getCurrentRoute(){
    this.routeUrl = this.Location.path();
    if(this.routeUrl == '/' || this.routeUrl.trim() == ''){
      this.routePath = '';
    }else if(this.routeUrl.startsWith('/cadastro')){
      this.routePath = 'cadastro';
    }else if(this.routeUrl.startsWith('/meu-perfil')){
      this.routePath = 'meu-perfil';
    }else if(this.routeUrl.startsWith('/servicos-tema/')){
      this.routePath = 'servicos-tema/:id';
    }else if(this.routeUrl.startsWith('/servicos-orgao/')){
      this.routePath = 'servicos-orgao/:id';
    }else if(this.routeUrl.startsWith('/servicos')){
      this.routePath = 'servicos';
    }else if(this.routeUrl.startsWith('/fale-conosco/contato')){
      this.routePath = 'fale-conosco/contato';
    }else if(this.routeUrl.startsWith('/fale-conosco')){
      this.routePath = 'fale-conosco';
    }else if(this.routeUrl.startsWith('/busca-geral/')){
      this.routePath = 'busca-geral/:query';
    }else if(this.routeUrl.startsWith('/servico/informacoes-servico')){
      this.routePath = 'servico/informacoes-servico/:id';
    }else if(this.routeUrl.startsWith('/agendamento/agendar')){
      this.routePath = 'agendamento/agendar/:id';
    }else if(this.routeUrl.startsWith('/agendamento/documentos')){
      this.routePath = 'agendamento/documentos/:id';
    }else if(this.routeUrl.startsWith('/agendamentos')){
      this.routePath = 'agendamentos/:protocolo/:nascimento';
    }else if(this.routeUrl.startsWith('/agendamento')){
      this.routePath = 'agendamento';
    }else{
      this.routePath = '**';
    }
    this.routeData = this.router.config.find(route => route.path === this.routePath) || {};
    return this.routeData;
  }
}
