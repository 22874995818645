import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TipoAgendamento } from '../../../models/model';
import { ListaAgendamentoService } from '../../../services/lista-agendamento.service';
import { AgendamentoService } from 'src/app/services/agendamento.service';
import { ResponsiveService } from '../../../services/responsive.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EventsService } from'src/app/services/events.service';

@Component({
  selector: 'app-informacoes-agendamento',
  templateUrl: './informacoes-agendamento.component.html',
  styleUrls: ['./informacoes-agendamento.component.css']
})
export class InformacoesAgendamentoComponent {

  orgaoID: string = "";

  tipoAgendamento = new Observable<TipoAgendamento | undefined>();

  helper: AgendamentoService = new AgendamentoService(this.http);

  servicoID: number = 0;

  servicosComAgendamento: any = {};

  motivosAvaliacao: any[] = [];

  outroMotivo: string = '';

  accordionStatus: boolean[] = [];

  agendamento: any = {};

  avaliacoes: any = { positivas: 0, negativas: 0 };

  minhaAvaliacao: number = -1;

  negativeVoteForm: boolean = false;

  option: number = 0;

  showAvaliacaoConcluida: boolean = false;

  showAvaliacaoJaRealizada: boolean = false;

  responsiveState: string = '';
  
  constructor(
    private route: ActivatedRoute,
    private listaAgendamentoService: ListaAgendamentoService,
    private http: HttpClient,
    private eventsService: EventsService,
    private responsiveService: ResponsiveService,
  ) { }

  toggleAccordion(i: number){
    this.accordionStatus[i] = !this.accordionStatus[i];
  }

  ngOnInit() {
    this.listaAgendamentoService.getAll().subscribe((response: any) => {
      this.servicosComAgendamento = response;
    });
    this.route.params.subscribe(params => {
      this.servicoID = params['id'];
      this.orgaoID = params['orgaoID'];
      this.listaAgendamentoService.get(this.servicoID).subscribe((response: any) => {
        this.agendamento = response;
        if(this.orgaoID){
          this.eventsService.emit('breadcrumb-update', '<a href="/serviços">Serviços</a> > ' +  '<a href="/servicos-orgao/orgao-' + this.orgaoID + '">' + this.agendamento.categoria.orgao.nome + '</a> > ' + this.agendamento.nome);
        }else{
          this.eventsService.emit('breadcrumb-update', '<a href="/servicos">Serviços</a> > ' +  '<a href="/servicos-tema/tema-' + this.agendamento.listaTemaServico[0].temaServico.id + '">' + this.agendamento.listaTemaServico[0].temaServico.titulo + '</a> > ' + this.agendamento.nome);
        }
      });
      this.helper.getAvaliacoesServico(this.servicoID).subscribe((response: any) => {
        if(response){
          this.avaliacoes = {
            positivas: response.positivas || 0,
            negativas: response.negativas || 0,
          };
        }
      });
    });
    this.helper.getMotivosAvaliacao().subscribe((response: any) => {
      if(Array.isArray(response)){
        response = response.sort((a,b) => a.ordem - b.ordem);
        response.forEach((item: any, i: number) => {
          this.accordionStatus.push(i === 0 ? true : false);
          this.motivosAvaliacao.push(item);
        });
      }
    });
    this.initResponsiveState();
  }

  initResponsiveState(){
    this.responsiveService.onChange((state: string) => {
      if(state === 'tablet'){
        state = 'desktop';
      }
      this.responsiveState = state;
    });
  }

  agendamentoEnabled(servicoID: number){
    if(Array.isArray(this.servicosComAgendamento) && this.servicosComAgendamento){
      return this.servicosComAgendamento.filter((s:any) => s.id === servicoID).length > 0;
    }
    return false;
  }

  navigate(route: string){
    if(route == 'back'){
      if (window.history.length > 1) {
        window.history.back();
      } else {
        window.location.href = '/agendamento';
      }
    }
  }

  sendAvaliacaoServico(avaliacao: number){
    if(this.minhaAvaliacao === -1){
      this.negativeVoteForm = false;
      this.helper.addAvaliacaoServico(this.servicoID, avaliacao, this.motivosAvaliacao[this.option].id, this.outroMotivo).subscribe((response: any) => {
        this.minhaAvaliacao = avaliacao;
        this.showAvaliacaoConcluida = true;
        if(avaliacao === 0){
          this.avaliacoes.negativas++;
        }
        if(avaliacao === 1){
          this.avaliacoes.positivas++;
        }
      });
    }else{
      this.showAvaliacaoJaRealizada = true;
    }
  }

  removeBlankParagraphs(text: string){
    return text.replace('<p style=\"margin-left:0cm; margin-right:0cm; text-align:justify\">&nbsp;</p>', '');
  }

  showNegativeVoteForm(){
    if(this.minhaAvaliacao === -1){
      this.negativeVoteForm = true;
    }else{
      this.showAvaliacaoJaRealizada = true;
    }
  }

  toggleOption(id: number){
    this.option = id;
    const option = document.querySelector('#negative-option-' + id);
    if(option){
      (option as any).click();
    }
  }

  SendNegativeForm(){
    if(this.option != 0){
      this.sendAvaliacaoServico(0);
    }
  }

  ClickShare(event: any){
    event.preventDefault();
    event.stopPropagation();
    navigator.share({
      title: this.agendamento.nome,
      url: window.location.href,
      text: this.agendamento.descricao
    });
  }

  ClickPrint(event: any){
    event.preventDefault();
    event.stopPropagation();
    window.print();
  }
}
