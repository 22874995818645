<div class="search {{boxOpen ? 'open' : 'close'}} variation-{{variation}}">
    <div class="row">
      <div class="col search-col">
        <form method="get" (keyup.enter)="formSubmit()">
          <input type="text" class="input-search" name="s" [hidden]="variation === '2' && !hasFocus" [placeholder]="'Digite aqui sua busca'" [(ngModel)]="searchQuery" #ctrl="ngModel" (ngModelChange)="updatePredict()" (focus)="focusChanged(1)" (blur)="focusChanged(0)" autocomplete="off">
          <div class="cancel-btn" [hidden]="!hasFocus" (click)="cleanSearch()"><img src="../../../../assets/image/icons/icon-close.png"></div>
          <button type="submit" [hidden]="boxOpen" (click)="FocusField($event)"><img src="../../../../assets/image/icons/icon-lupa.png"></button>
          <div class="search-title" *ngIf="variation === '2' && !hasFocus" (click)="PreventInput($event)">Serviços e informações para você</div>
          <div class="results" [hidden]="!boxOpen || variation === '2'">
            <div class="results-title" [hidden]="searchQuery != ''">Serviços mais acessados</div>
            <div class="results-title" *ngIf="isLoading">Carregando...</div>
            <a class="result-item" href="/servico/informacoes-servico/{{item.id}}" *ngFor="let item of prediction">{{item.nome}}</a>
          </div>
        </form>
      </div>
    </div>
</div>