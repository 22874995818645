<div class="container">
    <div class="row">
        <div class="col">

            <h4 class="title center" *ngIf="responsiveState == 'desktop'">Agendamento</h4>

            <div class="accordion accordion-flush" id="accordionAgendamento">
                <div class="accordion-item" *ngFor="let tipoAgendamento of servicos; let i = index">
                  <h2 class="accordion-header" id="flush-headingThree">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" [attr.data-bs-target]="'#flush-collapse'+ i" aria-expanded="false" aria-controls="flush-collapseThree">
                        <div class="title-medium">
                            {{tipoAgendamento.nome}}
                        </div>
                    </button>
                  </h2>
                  <div id="flush-collapse{{i}}" class="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionAgendamento">
                    <div class="accordion-body">
                        <div class="header">
                            <h3>{{tipoAgendamento.sigla}}</h3>
                        </div>
                        
                        <div class="main">
                            <p class="font-style-1" [innerHTML]="limitText(removeRichText(tipoAgendamento.descricao), 2000)"></p>
                            <div class="info">
                                <a href="servico/informacoes-servico/{{tipoAgendamento.id}}" rel="noopener noreferrer">Mais informações</a>
                                <a href="agendamento/agendar/{{tipoAgendamento.id}}" class="btn-agendamento" rel="noopener noreferrer">Agendar</a>
                            </div>
                        </div>
                    </div>
                  </div>
                </div>
            </div>
        </div>
    </div>
</div>