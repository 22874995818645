<section class="form-agendamento">
  <div class="container">
    <div class="row">
      <div class="col-12">

        <form class="row  needs-validation" novalidate>

          <div [class]="responsiveState == 'desktop' ? 'col-8' : 'col-12'">
            <fieldset class="reset {{form.message['nomecompleto'] ? 'has-error' : ''}}" for="nome-completo">
              <legend class="reset">Nome completo*</legend>
              <input type="search" class="form-control" maxlength="100" id="nome-completo" name="nome" required [(ngModel)]="form.fields['nomecompleto']" #ctrl="ngModel" (keyup)="mask()">
            </fieldset>
            <div class="error-label" *ngIf="form.message['nomecompleto']">
              {{form.message['nomecompleto']}}
            </div>
          </div>

          <!-- <div class="col-12">
            <fieldset class="reset {{form.message['nomesocial'] ? 'has-error' : ''}}" for="nome-social">
              <legend class="reset">Nome social</legend>
              <input type="search" class="form-control" id="nome-social" name="nomesocial" required [(ngModel)]="form.fields['nomesocial']" #ctrl="ngModel" (keyup)="mask()">
            </fieldset>
            <div class="field-tip">Insira o nome pelo qual você prefere ser chamado(a) durante o atendimento.</div>
            <div class="error-label" *ngIf="form.message['nomesocial']">
              {{form.message['nomesocial']}}
            </div>
          </div> -->

          <div [class]="responsiveState == 'desktop' ? 'col-4' : 'col-12'">
            <fieldset class="reset {{form.message['nascimento'] ? 'has-error' : ''}}" for="nascimento">
              <legend class="reset">Nascimento*</legend>
              <input type="search" class="form-control" id="nascimento" name="nascimento" required inputmode="numeric" [(ngModel)]="form.fields['nascimento']" #ctrl="ngModel" (keyup)="mask()">
            </fieldset>
            <div class="error-label" *ngIf="form.message['nascimento']">
              {{form.message['nascimento']}}
            </div>
          </div>

          <div [class]="responsiveState == 'desktop' ? 'col-4' : 'col-12'">
            <fieldset class="reset {{form.message['cpf'] ? 'has-error' : ''}}" for="cpf">
              <legend class="reset">CPF*</legend>
              <input type="search" class="form-control" id="cpf" name="cpf" required inputmode="numeric" [(ngModel)]="form.fields['cpf']" #ctrl="ngModel" (keyup)="mask()">
            </fieldset>
            <div class="error-label" *ngIf="form.message['cpf']">
              {{form.message['cpf']}}
            </div>
          </div>

          <!-- <div class="col-12">
            <fieldset class="reset {{form.message['nomemae'] ? 'has-error' : ''}}" for="nomemae">
              <legend class="reset">Nome da Mãe Completo*</legend>
              <input type="search" class="form-control" id="nomemae" name="nomemae" required [(ngModel)]="form.fields['nomemae']" #ctrl="ngModel" (keyup)="mask()">
            </fieldset>
            <div class="error-label" *ngIf="form.message['nomemae']">
              {{form.message['nomemae']}}
            </div>
          </div> -->

          <!-- <div class="col-12">
            <fieldset class="reset {{form.message['rg'] ? 'has-error' : ''}}" for="nascimento">
              <legend class="reset">RG* (Somente números)</legend>
              <input type="search" class="form-control" id="rg" name="rg" required [(ngModel)]="form.fields['rg']" #ctrl="ngModel" (keyup)="mask()">
            </fieldset>
            <div class="error-label" *ngIf="form.message['rg']">
              {{form.message['rg']}}
            </div>
          </div> -->

          <div [class]="responsiveState == 'desktop' ? 'col-4' : 'col-12'">
            <fieldset class="reset {{form.message['email'] ? 'has-error' : ''}}" for="email">
              <legend class="reset">E-mail*</legend>
              <input type="search" class="form-control" id="email" name="email" required [(ngModel)]="form.fields['email']" #ctrl="ngModel" (keyup)="mask()">
            </fieldset>
            <div class="error-label" *ngIf="form.message['email']">
              {{form.message['email']}}
            </div>
          </div>

          <div [class]="responsiveState == 'desktop' ? 'col-4' : 'col-12'">
            <fieldset class="reset {{form.message['celular'] ? 'has-error' : ''}}" for="celular">
              <legend class="reset">Celular*</legend>
              <input type="search" class="form-control" id="celular" name="celular" inputmode="numeric" required [(ngModel)]="form.fields['celular']" #ctrl="ngModel" (keyup)="mask()">
            </fieldset>
            <div class="error-label" *ngIf="form.message['celular']">
              {{form.message['celular']}}
            </div>
          </div>

          <!-- <div class="col-12">
            <fieldset class="reset {{form.message['fixo'] ? 'has-error' : ''}}" for="celular">
              <legend class="reset">Fixo*</legend>
              <input type="search" class="form-control" id="fixo" name="fixo" required [(ngModel)]="form.fields['fixo']" #ctrl="ngModel" (keyup)="mask()">
            </fieldset>
            <div class="error-label" *ngIf="form.message['fixo']">
              {{form.message['fixo']}}
            </div>
          </div> -->

          <div class="col-12">
            <div class="policies">
              <div class="form-check">
                <input class="form-check-input" type="checkbox" name="termos" id="termos" checked [(ngModel)]="form.fields['termos']" #ctrl="ngModel">
                <label class="form-check-label label-termos" for="termos">Li e aceito a <a href="/privacidade" target="_blank">Política de Privacidade e os Termos de Uso.</a></label>
              </div>
              <div class="error-label" *ngIf="form.message['termos']">{{form.message['termos']}}</div>
              <div class="form-check">
                <input class="form-check-input" type="checkbox" name="autorizacao" id="autorizacao" checked [(ngModel)]="form.fields['autorizacao']" #ctrl="ngModel">
                <label class="form-check-label label-autorizacao" for="autorizacao">Autorizo o envio de e-mails com atualizações do meu agendamento.</label>
              </div>
            </div>
          </div>

          <re-captcha (resolved)="onCaptchaSolve($event)"></re-captcha>
        
          <div class="col-12">
            <hr>
            <div class="buttons">
              <a class="btn btn-form-return"  (click)="navigate('back')">Voltar</a>
              <button class="btn btn-form-submit" (click)="submit()">Avançar</button>
            </div>
          </div>

        </form>

      </div>
    </div>
  </div>
</section>



<app-modal *ngIf="formPopupError">
  <div class="m-title">
    <img src="../../../../assets/image/icons/icon-close.png" alt="" srcset="">
  </div>
  <div class="m-body">
    <h3 class="text-center mb-3">Houve um erro no seu agendamento</h3>
    <p>{{formPopupError}}</p>
  </div>
  <div class="m-action">
    <a href="/" class="btn-form-submit">Voltar a tela inicial</a>
  </div>
</app-modal>