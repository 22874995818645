import { Component } from '@angular/core';
import { AgendamentoService } from '../../../services/agendamento.service';
import {HttpClient} from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { Input } from '@angular/core';

@Component({
  selector: 'app-search-box',
  templateUrl: './search-box.component.html',
  styleUrls: ['./search-box.component.css']
})
export class SearchBoxComponent {
  @Input() variation: string = "1";

  boxOpen: boolean = false;

  isLoading: boolean = false;

  searchQuery: string = "";

  defaultServices: any[] = [];

  prediction: any[] = this.defaultServices;

  timeoutMs: number = 2000;

  searchTimeout: any = setTimeout(() => {}, this.timeoutMs);

  hasFocus: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private http: HttpClient
  ) {}

  helper: AgendamentoService = new AgendamentoService(this.http);

  ngOnInit(){
    this.trackSearchForm();
    this.route.params.subscribe(params => {
      this.searchQuery = params['query'];
    });
    this.helper.getServicosMaisAcessados().subscribe((result: any) => {
      if(Array.isArray(result)){
        this.defaultServices = result;
        this.prediction = result;
      }
    });
  }

  search(){
    this.helper.getBuscaGeral(this.searchQuery).subscribe((result: any) => {
      this.isLoading = false;
      if(Array.isArray(result)){
        this.prediction = result;
      }else{
        this.prediction = [];
      }
    });
  }

  openSearchBox(){
    this.boxOpen = true;
  }

  closeSearchBox(){
    this.boxOpen = false;
  }

  updatePredict(){
    if(this.searchQuery == ""){
      this.isLoading = false;
      this.prediction = this.defaultServices;
    }else{
      this.prediction = [];
      this.isLoading = true;
      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(() => {
        this.search();
      }, this.timeoutMs);
    }
  }

  trackSearchForm(){
    const that = this;
    window.addEventListener('click', function(e) {
      var parentElement = document.querySelector('.search');
      if (parentElement && parentElement.contains(e.target as any)) {
        that.openSearchBox();
      }else{
        that.closeSearchBox();
      }
    });
  }

  formSubmit(){
    window.location.href = "/busca-geral/" + encodeURI(this.searchQuery);
  }

  focusChanged(status: number){
    setTimeout(() => {
      this.hasFocus = status === 1;
      this.boxOpen = status === 1;
    },200);
  }

  cleanSearch(){
    this.searchQuery = "";
    this.closeSearchBox();
    this.prediction = this.defaultServices;
  }

  FocusField(event: any){
    this.hasFocus = true;
    setTimeout(() => {
      event.target.parentElement.parentElement.querySelector('input').focus();
    },100);
  }

  PreventInput(event: any){
    event.preventDefault();
    event.stopPropagation();
  }
}
