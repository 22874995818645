

<div class="container">
    <div class="row">
        <div class="col">
            
            <app-search-box></app-search-box>

        </div>
    </div>
</div>

<section class="section-desktop-blue">
    <div class="container">
        <div class="row">
            <div class="col">
                
                <app-servicos-destaque></app-servicos-destaque>
    
            </div>
        </div>
    </div>
</section>

<div class="container">
    <div class="row">
        <div class="col">
            
            <app-agendamento-home></app-agendamento-home>

            <app-wp-banner></app-wp-banner>
            
            <app-lista-tema></app-lista-tema>

        </div>
    </div>
</div>

<section [class]="responsiveState == 'desktop' ? 'bg-lightgray' : ''">
    <div class="container">
        <div class="row">
            <div class="col">
    
                <app-indicador-servicos></app-indicador-servicos>
    
            </div>
        </div>
    </div>
</section>

