import { Component, ElementRef, Input } from '@angular/core';
import { EventsService } from '../../../services/events.service';

@Component({
  selector: 'app-seletor',
  templateUrl: './seletor.component.html',
  styleUrls: ['./seletor.component.css']
})
export class SeletorComponent {
  @Input() Placeholder: string = '';
  @Input() ClearEvent: string = '';
  @Input() options: any[] = [];
  @Input() error: string = '';
  @Input() titulo: string = '';
  @Input() LabelField: string = '';
  @Input() ValueField: string = '';
  @Input() OnChange: Function = () => {};
  @Input() FormatLabel: Function = (label: any) => label;
  @Input() Icon: string = "";
  @Input() SizePreset: string = "";
  @Input() Pagination: boolean = false;
  @Input() GetMore: Function = () => {};
  @Input() Variation: string = "0";

  constructor (private elRef: ElementRef, private eventsService: EventsService) {
    this.rootElement = elRef;
  }

  rootElement: ElementRef;

  isOpen: boolean = false;

  value: string = '';

  page: number = 0;

  ngOnInit(){
    if(this.ClearEvent){
      this.eventsService.when(this.ClearEvent, () => {
        this.value = "";
        this.page = 0;
      });
    }
  }

  OpenSelectBox(){
    this.isOpen = true;
  }

  Select(id: any, label?: string){
    this.value = label ? this.FormatLabel(label) : id;
    this.OnChange(id);
    setTimeout(() => {
      this.Close();
    },100);
  }

  Open(){
    this.isOpen = true;
  }

  Close(){
    this.isOpen = false;
  }

  TrackClick(event: any){
    if(event?.target && event?.target?.classList){
      if (!this.rootElement.nativeElement.contains(event.target) && !event.target.classList.contains('ignore-select')) {
        this.Close();
      }
    }
  }

  PreventInput(event: any){
    event.preventDefault();
    event.stopPropagation();
  }

  getMore(event: any){
    this.page++;
    this.GetMore(this.page);
  }
}
