import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { ServicosComponent } from './components/servicos/servicos.component';
import { FaleConoscoComponent } from './components/fale-conosco/faleconosco.component';
import { AgendamentoComponent } from './components/agendamento/agendamento.component';
import { InformacoesAgendamentoComponent } from './components/agendamento/informacoes-agendamento/informacoes-agendamento.component';
import { AgendarComponent } from './components/agendamento/agendar/agendar.component';
import { FormAgendamentoComponent } from './components/agendamento/form-agendamento/form-agendamento.component';
import { ModalComponent } from './components/agendamento/modal/modal.component';
import { ListaDocumentosComponent } from './components/agendamento/lista-documentos/lista-documentos.component';
import { ConsultaAgendamentoComponent } from './components/home/consulta-agendamento/consulta-agendamento.component';
import { ConsultaProcessoComponent } from './components/home/consulta-processo/consulta-processo.component';
import { ReagendamentoFormComponent } from './components/agendamento/reagendamento-form/reagendamento-form.component';
import { CancelamentoFormComponent } from './components/agendamento/cancelamento-form/cancelamento-form.component';
import { BuscaGeralComponent } from './components/busca-geral/busca-geral.component';
import { ContatoFormComponent } from './components/fale-conosco/contato-form/contato-form.component';
import { TemaComponent } from './components/temas/tema/tema.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { CadastroComponent } from './components/cadastro/cadastro.component';
import { PerfilComponent } from './components/perfil/perfil.component';
import { OrgaoComponent } from './components/temas/orgao/orgao.component';
import { RemarcacaoComponent } from './components/agendamento/remarcacao/remarcacao.component';
import { NaoconfirmarComponent } from './components/agendamento/naoconfirmar/naoconfirmar.component';
import { OQueHaDeNovoComponent } from './components/o-que-ha-de-novo/o-que-ha-de-novo.component';
import { LigaDigitalComponent } from './components/liga-digital/liga-digital.component';

// Front-end

const routes: Routes = [
  { path: '', component: HomeComponent, title: 'Início', data: {  } },
  { path: 'cadastro', component: CadastroComponent, data: { breadcrumbs: 'Cadastro', hasInternalMenu: true } },
  { path: 'meu-perfil', component: PerfilComponent, data: { breadcrumbs: 'Meu perfil', hasInternalMenu: true } },
  { path: 'servicos', component: ServicosComponent, title: 'Serviços', data: {  } },
  { path: 'fale-conosco', component: FaleConoscoComponent, title: 'Fale conosco', data: { } },
  { path: 'fale-conosco/contato', component: ContatoFormComponent, data: { breadcrumbs: '<a href="/fale-conosco">Fale conosco</a> > Enviar mensagem', hasInternalMenu: true } },
  { path: 'busca-geral/:query', component: BuscaGeralComponent, data: { breadcrumbs: 'Busca geral', hasInternalMenu: true } },
  { path: 'servicos-tema/:id', component: TemaComponent, data: { breadcrumbs: 'Serviços', hasInternalMenu: true } },
  { path: 'servicos-orgao/:id', component: OrgaoComponent, data: { breadcrumbs: 'Serviços', hasInternalMenu: true } },
  { path: 'agendamento', component: AgendamentoComponent, data: { breadcrumbs: 'Agendamento', hasInternalMenu: true } },
  { path: 'servico/informacoes-servico/:id', component: InformacoesAgendamentoComponent, data: { breadcrumbs: 'Informações do serviço', hasInternalMenu: true } },
  { path: 'servico/informacoes-servico/:id/:orgaoID', component: InformacoesAgendamentoComponent, data: { breadcrumbs: 'Informações do serviço', hasInternalMenu: true } },
  { path: 'agendamento/agendar/:id', component: AgendarComponent, data: { breadcrumbs: 'Agendamento', hasInternalMenu: true } },
  { path: 'agendamento/documentos/:id', component: ListaDocumentosComponent, data: { breadcrumbs: 'Documentos necessários', hasInternalMenu: true } },
  { path: 'agendamentos/:protocolo/:nascimento', component: ConsultaAgendamentoComponent, data: { breadcrumbs: 'Acompanhar agendamento',hasInternalMenu: true } },
  { path: 'agendamentos/:protocolo/:nascimento/:processo', component: ConsultaProcessoComponent, data: { breadcrumbs: 'Acompanhar agendamento',hasInternalMenu: true } },
  { path: 'agendamentos/:protocolo/:nascimento/:processo/reagendamento', component: ReagendamentoFormComponent, data: { breadcrumbs: 'Reagendamento',hasInternalMenu: true } },
  { path: 'agendamentos/:protocolo/:nascimento/:processo/cancelamento', component: CancelamentoFormComponent, data: { breadcrumbs: 'Cancelamento',hasInternalMenu: true } },
  { path: 'agendamentos/:protocolo/:nascimento/:processo/remarcacao', component: RemarcacaoComponent, data: { breadcrumbs: 'Confirmar remarcação',hasInternalMenu: true } },
  { path: 'agendamentos/:protocolo/:nascimento/:processo/naoconfirmar', component: NaoconfirmarComponent, data: { breadcrumbs: 'Não confirmar remarcação',hasInternalMenu: true } },
  { path: 'o-que-ha-de-novo', component: OQueHaDeNovoComponent, data: { breadcrumbs: 'O que há de novo?', hasInternalMenu: true } },
  { path: 'liga-digital', component: LigaDigitalComponent, data: { breadcrumbs: 'Liga Digital', hasInternalMenu: true } },
  { path: '**', pathMatch: 'full',  component: NotFoundComponent, data: { breadcrumbs: 'PE Cidadão', hasInternalMenu: true } },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
