import { Component, Input, Output, ViewChild } from '@angular/core';
import { FormService } from '../../../services/form.service';

@Component({
  selector: 'app-pagamento',
  templateUrl: './pagamento.component.html',
  styleUrls: ['./pagamento.component.css']
})
export class PagamentoComponent {
  @Input() servico: any = {};
  @Input() OnSubmit: Function = (fields?: any) => {};
  @Input() OnNavigate: Function = (fields?: any) => {};
  @Input() FormData: { [key: string]: FormService } = {};
  @Input() erroPagamento: string = "";

  modal: string = '';

  getField(section: string, name: string){
    if(!this.FormData[section]){
      return '';
    }
    if(!this.FormData[section].fields){
      return '';
    }
    const fieldValue: any = this.FormData[section].fields[name];
    return fieldValue;
  }

  navigate(name: string){
    this.OnNavigate(name);
  }

  imprimirBoleto(){
    this.OnSubmit();
  }

  formatText(text: string){
    return text.replace('\n','<br>');
  }

  togglePopup(name: string){
    this.modal = name;
  }

  gotoHome(){
    window.location.href = '/';
  }
}
