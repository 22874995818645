<div class="container">
    <div class="row">
        <div class="col-12">
            <section class="header-section">
                <div class="info">
                    <div class="flex">
                        <a href="/fale-conosco">
                            <img src="../../../../assets/image/icons/icon-return.png" alt="Voltar" >
                        </a>
                        <h4>Fale conosco</h4>
                    </div>
                    <a href="/fale-conosco" ><img src="../../../../assets/image/icons/icon-close.png" alt="Fechar" ></a>
                </div>
            </section>
        </div>
    </div>

    <div class="row">
        <div class="col">
            <div class="box">
                <div class="title font-style-1">Como podemos ajudar?</div>
                <div class="subtitle font-style-2">Descreva o seu problema ou conte sua história. Queremos ouvir você.</div>
                <form class="row  needs-validation" novalidate id="form-consulta">

                    <div class="col-12">
                      <fieldset class="reset {{form.message['remetente'] ? 'has-error' : ''}}" for="nome">
                        <legend class="reset">Nome*</legend>
                        <input type="text" class="form-control" id="nome" name="nome" required [(ngModel)]="form.fields['remetente']" #ctrl="ngModel">
                      </fieldset>
                      <div class="error-label" *ngIf="form.message['remetente']">
                        {{form.message['remetente']}}
                      </div>
                    </div>

                    <div class="col-12">
                      <fieldset class="reset {{form.message['email'] ? 'has-error' : ''}}" for="email">
                        <legend class="reset">E-mail*</legend>
                        <input type="text" class="form-control" id="email" name="email" required [(ngModel)]="form.fields['email']" #ctrl="ngModel">
                      </fieldset>
                      <div class="error-label" *ngIf="form.message['email']">
                        {{form.message['email']}}
                      </div>
                    </div>

                    <app-seletor
                      [options]="[{ nome: 'Elogio', id: 'Elogio' }, { nome: 'Reclamação', id: 'Reclamacao' }, { nome: 'Solicitação', id: 'Solicitacao' }, { nome: 'Sugestão', id: 'Sugestao' }]"
                      [titulo]="'Assunto*'"
                      [error]="form.message['tipo']"
                      [OnChange]="OnAssuntoSelect.bind(this)"
                      [LabelField]="'nome'"
                      [ValueField]="'id'"
                    ></app-seletor>

                    <div class="col-12">
                        <fieldset class="reset {{form.message['mensagem'] ? 'has-error' : ''}}" for="mensagem">
                            <legend class="reset">Escreva sua mensagem*</legend>
                            <textarea class="form-control" name="mensagem" id="mensagem" [(ngModel)]="form.fields['mensagem']" #ctrl="ngModel"></textarea>
                        </fieldset>
                        <label class="fieldset-label" *ngIf="!form.message['mensagem']">Texto de até 2000 caracteres</label>
                        <div class="error-label mt-10" *ngIf="form.message['mensagem']">
                            {{form.message['mensagem']}}
                        </div>
                    </div>

                </form>
                <div class="message-box">
                    O nosso horário de atendimento é de segunda a sexta das 08:00 às 17:00hs.
                    <br>
                    Mensagens enviadas fora desse horário serão respondidas no dia útil consecutivo.
                    <br><br>
                    Fique de olho no seu email que logo responderemos sua mensagem.
                </div>
                <button (click)="formSubmit()">Enviar mensagem</button>
            </div>
            <div class="message-box only-desktop">
              O nosso horário de atendimento é de segunda a sexta das 08:00 às 17:00hs.
              <br>
              Mensagens enviadas fora desse horário serão respondidas no dia útil consecutivo.
              <br><br>
              Fique de olho no seu email que logo responderemos sua mensagem.
            </div>
        </div>
    </div>
</div>

  <app-modal *ngIf="result.submitted && result.confirmed">
    <div class="m-title">
      <img src="../../../../assets/image/icons/icon-check.png" alt="" srcset="">
    </div>
    <div class="m-body">
      <h3 class="text-center mb-3">Sua mensagem foi enviada</h3>
      <p>Lembre-se de ficar de olho no seu email que responderemos a sua mensagem o quanto antes.</p>
      <br>
      <p>Nosso horário de atendimento é de segunda à sexta das 8:00 às 17:00</p>
    </div>
    <div class="m-action">
      <a href="/" class="btn-form-submit">Voltar para o início</a>
      <a href="/fale-conosco/contato" class="btn-form-submit">Enviar outra mensagem</a>
    </div>
  </app-modal>

  <app-modal *ngIf="result.submitted && !result.confirmed">
    <div class="m-title">
      <img src="../../../../assets/image/icons/icon-close.png" alt="" srcset="">
    </div>
    <div class="m-body">
      <h3 class="text-center mb-3">Houve um erro ao enviar sua mensagem</h3>
      <p>{{erroMensagem}}</p>
    </div>
    <div class="m-action">
      <a href="/" class="btn-form-submit">Voltar para o início</a>
      <a href="/fale-conosco/contato" class="btn-form-submit">Tentar novamente</a>
    </div>
  </app-modal>
