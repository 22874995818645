import { Component } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-wp-banner',
  templateUrl: './wp-banner.component.html',
  styleUrls: ['./wp-banner.component.css']
})
export class WpBannerComponent {

  currentSlide = 0;

  slides: any[] = [];

  constructor(
    private http: HttpClient
  ) {}

  ngOnInit(){
    this.http.get(`${environment.baseWP}/wp-json/wp-banners/v1/banners`).subscribe((result: any) => {
      this.slides = result;
    });
  }

}
