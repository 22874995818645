<div [class]="'col-12 seletor-preset-' + SizePreset + ' seletor-placeholder-' + (Placeholder ? 'active' : 'inactive' ) + ' ' + (value ? 'has-value' : 'has-no-value')" (window:click)="TrackClick($event)">
    <fieldset class="reset {{error ? 'has-error' : ''}}">
      <legend class="reset">{{titulo}}</legend>
      <input type="text" class="form-control" value="{{value}}" (click)="Open()" (keydown)="PreventInput($event)" (paste)="PreventInput($event)" autocapitalize="off" autocomplete="off" autocorrect="off" autofill="off" role="presentation" [placeholder]="!value && Placeholder ? Placeholder : ''">
      <div class="sel-icon" *ngIf="Icon" (click)="Open()"><img [src]="'../../../../assets/image/icons/' + Icon"></div>
    </fieldset>
    <div class="seletor-options" [hidden]="!isOpen">
        <div class="container">
            <div [class]="'row floating-options variation-' + Variation">
                <div class="col">
                    <div class="seletor-option {{value == option ? 'active' : ''}}" *ngFor="let option of options" (click)="Select(ValueField ? $any(option)[ValueField] : option, LabelField ? $any(option)[LabelField] : option)">{{LabelField ? FormatLabel($any(option)[LabelField]) : FormatLabel(option)}}</div>
                    <div class="seletor-pagination ignore-select" (click)="getMore($event)" *ngIf="Pagination">
                        <img src="../../../../assets/image/icons/arrow-expand.png" alt="+" class="ignore-select">
                        <span class="ignore-select">Ver mais</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="error-label" *ngIf="error">{{error}}</div>
</div>
