import { Component, Input, Output, ViewChild, OnChanges } from '@angular/core';
import { FormService } from '../../../services/form.service';
import { AgendamentoService } from '../../../services/agendamento.service';
import {HttpClient} from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { TipoAgendamento, AgendamentoParametroServico, AgendamentoParametroOrgao } from '../../../models/model';
import { ActivatedRoute } from '@angular/router';
import { ResponsiveService } from '../../../services/responsive.service';


@Component({
  selector: 'app-confirmar-agendamento',
  templateUrl: './confirmar-agendamento.component.html',
  styleUrls: ['./confirmar-agendamento.component.css']
})
export class ConfirmarAgendamentoComponent {
  @Input() OnSubmit: Function = (fields?: any) => {};
  @Input() OnNavigate: Function = (fields?: any) => {};
  @Input() FormName: string = '';
  @Input() FormData: { [key: string]: FormService } = {};
  @Input() OnSessionToken: Function = (token?: string) => {};
  @Input() SessionValid: Boolean = true;
  @Input() Protocolo: string = "";
  @Input() Servico: any = {};

  sessionToken: string = '';

  form: FormService = new FormService();

  helper: AgendamentoService = new AgendamentoService(this.http);

  servicoID: number = -1;

  responsiveState: string = '';

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private responsiveService: ResponsiveService,
  ) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.servicoID = params['id'];
    });
    this.initResponsiveState();
  }

  initResponsiveState(){
    this.responsiveService.onChange((state: string) => {
      if(state === 'tablet'){
        state = 'desktop';
      }
      this.responsiveState = state;
    });
  }

  getField(section: string, name: string){
    if(!this.FormData[section]){
      return '';
    }
    if(!this.FormData[section].fields){
      return '';
    }
    const fieldValue: any = this.FormData[section].fields[name];
    if(name == 'unidadeendereco'){
      const horarioValue: any = this.FormData[section].fields['horario'];
      const endereco = horarioValue.servicoUnidade.unidadeAtendimento.endereco;
      return endereco.logradouro + ', '+ endereco.numero + ', '+ endereco.complemento + ' ' + endereco.bairro + ', ' + endereco.municipio + ' - ' + endereco.uf + ' ' + endereco.cep;
    }
    if(name == 'dataatendimento'){
      return (new Date(fieldValue)).toLocaleDateString();
    }
    if(name == 'horario'){
      return (new Date(fieldValue.horario)).toLocaleTimeString().substr(0,5);
    }
    return fieldValue;
  }

  navigate(name: string){
    this.OnNavigate(name);
  }

  submit(){
    if(this.SessionValid){
      this.OnSubmit({ sessionToken: this.sessionToken }, this.FormName);
    }
  }

  onCaptchaSolve(token: any){
    if(token === null){
      this.sessionToken = '';
    }else{
      this.helper.criarSessao(token, this.servicoID).subscribe((data: any) => {
        if(data.token){
          this.sessionToken = data.token;
          this.OnSessionToken(this.sessionToken);
        }
      });
    }
  }

}
