import { Component, Input, Output, ViewChild } from '@angular/core';
import { FormService } from '../../../services/form.service';
import { AgendamentoService } from '../../../services/agendamento.service';
import {HttpClient} from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { TipoAgendamento, AgendamentoParametroServico, AgendamentoParametroOrgao } from '../../../models/model';
import { ActivatedRoute } from '@angular/router';
import { EventsService } from '../../../services/events.service';

@Component({
  selector: 'app-cancelamento-form',
  templateUrl: './cancelamento-form.component.html',
  styleUrls: ['./cancelamento-form.component.css']
})
export class CancelamentoFormComponent {
  constructor(
    private route: ActivatedRoute,
    private http: HttpClient,
    private eventsService: EventsService
  ) {}

  protocolo: string = '';
  nascimento: string = '';
  processo: string = '';

  agendamento: any = {};

  listEmpty: boolean = false;

  isLoading: boolean = true;
  
  helper: AgendamentoService = new AgendamentoService(this.http);

  result: any = { confirmed: false, submitted: false };

  sessionToken: string = '';
  
  captchaValidationMessage: string = '';

  ngOnInit(){
    this.route.params.subscribe(params => {
      this.protocolo = params['protocolo'];
      this.nascimento = params['nascimento'];
      this.processo = params['processo'];
      this.helper.getAgendamento({ protocolo: this.protocolo })
      .subscribe((result: any) => {
        this.isLoading = false;
        if(result.protocolo){
          this.agendamento = result;
          this.eventsService.emit('breadcrumb-update', 'Agendamento > <a href="/#acompanhar-agendamento">Acompanhar agendamento</a> > ' + this.agendamento.horarioAgenda.servicoUnidade.servico.nome);
        }else{
          this.listEmpty = true;
        }
      });
    });
  }

  navigate(name: string){
    window.location.href = `/agendamentos/${this.agendamento.pessoa.cpf}/${this.nascimento}`;
  }

  sendFormData(){
    this.eventsService.emit('loader-toggle', true);
    this.helper.sendCancelamentoForm(this.agendamento, this.sessionToken).subscribe((result: any) => {
      this.eventsService.emit('loader-toggle', false);
      this.result.submitted = true;
      this.result.confirmed = false;
      if(result.status){
        this.result.confirmed = true;
      }
    });
  }

  formatarDataHora(data: string){
    const dataInfo: any = {
      data: this.formatarData(data),
      horario: this.formatarHorario(data),
    };
    return `${dataInfo.data} ${dataInfo.horario}`;
  }

  formatarData(data: string){
    const dataParsed = new Date(data);
    const dataInfo: any = {
      year: dataParsed.getFullYear(),
      month: dataParsed.getMonth() + 1,
      day: dataParsed.getDate(),
    };
    if(dataInfo.day.toString().length == 1){
      dataInfo.day = '0' + dataInfo.day;
    }
    if(dataInfo.month.toString().length == 1){
      dataInfo.month = '0' + dataInfo.month;
    }
    return `${dataInfo.day}/${dataInfo.month}/${dataInfo.year}`;
  }

  formatarHorario(data: string){
    const dataParsed = new Date(data);
    const dataInfo: any = {
      hour: dataParsed.getHours(),
      minute: dataParsed.getMinutes(),
    };
    if(dataInfo.minute.toString().length === 1){
      dataInfo.minute = '0' + dataInfo.minute;
    }
    return `${dataInfo.hour}:${dataInfo.minute}`;
  }

  submit(){
    this.eventsService.emit('loader-toggle', true);
    this.captchaValidationMessage = '';
    if(this.sessionToken){
      this.helper.sendCancelamentoForm(this.agendamento, this.sessionToken).subscribe((result: any) => {
        this.eventsService.emit('loader-toggle', false);
        this.result.submitted = true;
        this.result.confirmed = false;
        if(result.status){
          this.result.confirmed = true;
        }
      });
    }else{
      this.captchaValidationMessage = 'Preencha corretamente o captcha.';
    }
  }

  whenCaptchaSolved(token: any){
    this.captchaValidationMessage = '';
    if(token === null){
      this.sessionToken = '';
    }else{
      this.helper.criarSessao(token, this.agendamento.horarioAgenda.servicoUnidade.servico.id).subscribe((data: any) => {
        if(data.token){
          this.sessionToken = data.token;
        }
      });
    }
  }
}
