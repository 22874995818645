<div class="container">
    <h3 class="title">{{orgaoTitulo}}</h3>
    <h3 class="title text-spaced" *ngIf="false">{{orgaoTitulo}} <div class="print-title"><img src="../../../../assets/image/icons/icon-download.svg" alt="Baixar">Baixar carta de serviço</div></h3>
    <div class="accordion accordion-flush" id="accordionAgendamento">
        <div class="accordion-item" *ngFor="let servico of servicos; let i = index">
          <h2 class="accordion-header" id="flush-headingThree">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" [attr.data-bs-target]="'#flush-collapse'+ i" aria-expanded="false" aria-controls="flush-collapseThree">
                <div class="title-medium">
                    {{servico.nome}}
                    <div class="servico-digital" *ngIf="servico.online">Serviço digital</div>
                </div>
            </button>
          </h2>
          <div id="flush-collapse{{i}}" class="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionAgendamento">
            <div class="accordion-body">
                <!-- <div class="header">
                    <h4>{{servico.nomeServico}}</h4>
                </div> -->

                <div class="servico-digital" *ngIf="servico.online">Serviço digital</div>
                
                <div class="main">
                    <p [innerHTML]="servico.descricao" class="richtext-font-1"></p>
                    <div class="info">
                        <a href="servico/informacoes-servico/{{servico.id}}/{{orgaoID}}" rel="noopener noreferrer">Mais informações</a>
                        <!-- <a href="agendamento/agendar/{{servico.id}}" class="btn-agendamento" rel="noopener noreferrer" *ngIf="servico.url && servico.online">Começar agora</a> -->
                        <!-- <a href="agendamento/agendar/{{servico.id}}" class="btn-agendamento" rel="noopener noreferrer" *ngIf="!servico.url && servico.online">Agendar</a> -->
                    </div>
                </div>
            </div>
          </div>
        </div>
    </div>
</div>