<h4 class="agendamentos-title font-style-4 no-margin section-title">Agendamentos</h4>
<div class="box-agendamento">
    <div class="row">
      <div class="col">
        <div class="box">
          <div class="header" *ngIf="responsiveState == 'desktop'">
            <div class="content">
              <div class="img">
                <img src="./../assets/image/icon-agendamento.png" alt="Agendamento">
              </div>
              <div class="text text-left">
                <h3 class="font-style-4">Agendamento online</h3>
                <h6>Quer fazer um agendamento?</h6>
              </div>
            </div>
            <div class="text text-left no-padding">
              <p class="text-left font-style-1">Consulte os nossos serviços de agendamento online e marque seu atendimento agora. </p>
              <a href="/agendamento" class="btn-defaut {{formIsOpened ? 'btn-secondary' : ''}}">Agendar serviço</a>
            </div>
          </div>
        </div>
        <div class="box">
          <div class="header" *ngIf="responsiveState != 'desktop'">
            <div class="content">
              <div class="img">
                <img src="./../assets/image/icon-agendamento.png" alt="Agendamento">
              </div>
              <div class="text text-left">
                <h3 class="font-style-4">Agende um serviço</h3>
              </div>
            </div>
            <div class="text text-left no-padding">
              <p class="text-left font-style-1">Consulte os serviços disponíveis e marque seu atendimento.</p>
              <a href="/agendamento" class="btn-defaut {{formIsOpened ? 'btn-secondary' : ''}}">Agendar serviço</a>
            </div>
          </div>
          
          <hr *ngIf="responsiveState != 'desktop'">
          <div class="content">
            <div>
              <h4 class="text-left font-style-4">Acompanhe seus agendamentos</h4>
              <div class="mb-16" *ngIf="responsiveState != 'desktop'"></div>
              <p class="text-left font-style-1">Para cancelar, remarcar ou consultar a situação dos seus pedidos.</p>
              <p class="text-left font-style-2" *ngIf="responsiveState == 'desktop'">Informe os dados da pessoa solicitante.</p>
            </div>

            <div [hidden]="!formIsOpened && responsiveState != 'desktop'">
              <p class="text-left font-style-2" *ngIf="responsiveState != 'desktop'">Informe os dados da pessoa solicitante.</p>

              <form class="row  needs-validation" novalidate id="form-consulta">

                <div class="col-12">
                  <fieldset class="default-fielset reset {{form.message['cpf'] ? 'has-error' : ''}}" for="protocolo">
                    <legend class="reset font-style-3">CPF*</legend>
                    <input type="search" class="form-control" id="cpf" name="cpf" required inputmode="numeric" [(ngModel)]="form.fields['cpf']" #ctrl="ngModel" (keyup)="mask()" #cpf>
                  </fieldset>
                  <div class="error-label" *ngIf="form.message['cpf']">
                    {{form.message['cpf']}}
                  </div>
                </div>
      
                <div class="col-12">
                  <fieldset class="default-fielset reset {{form.message['nascimento'] ? 'has-error' : ''}}" for="nascimento">
                    <legend class="reset font-style-3">Nascimento*</legend>
                    <input type="search" class="form-control" id="nascimento" name="nascimento" required inputmode="numeric" [(ngModel)]="form.fields['nascimento']" #ctrl="ngModel" (keyup)="mask()" #nascimento>
                  </fieldset>
                  <div class="error-label" *ngIf="form.message['nascimento']">
                    {{form.message['nascimento']}}
                  </div>
                </div>

                <div class="anchor-forget text-left">
                  <!-- <a (click)="ShowProtocoloPopup()" class="font-style-2">Esqueceu seu protocolo?</a> -->
                </div>

              </form>

              <a (click)="submit()" *ngIf="responsiveState == 'desktop'" class="btn-defaut lavender {{formIsOpened ? '' : 'btn-secondary'}}">Acompanhar agendamento</a>
            </div>
            
            <a (click)="submit()" *ngIf="responsiveState != 'desktop'" class="btn-defaut lavender {{formIsOpened ? '' : 'btn-secondary'}}">Acompanhar agendamento</a>
          </div>
        </div>
      </div>
    </div>
</div>


<app-modal *ngIf="showProtocoloPopup && !formProtocoloResult.submitted">
  <div class="m-title">
    <div>Recuperar protocolo</div>
  </div>
  <div class="m-body">
    <div>Informe seu E-mail para que possamos enviar o Protocolo do seu último agendamento.</div><br>
    <section class="form-agendamento">
      <form class="row  needs-validation" novalidate>
        <div class="col-12">
          <fieldset class="reset {{formProtocoloMessage ? 'has-error' : ''}}" for="email">
            <legend class="reset">E-mail*</legend>
            <input type="text" class="form-control" id="email" name="email" required [(ngModel)]="formEmail" #ctrl="ngModel">
          </fieldset>
          <div class="error-label" *ngIf="formProtocoloMessage">
            {{formProtocoloMessage}}
          </div>
          <div>Use o mesmo e-mail do seu cadastro/agendamento.</div><br>
          <re-captcha (resolved)="onCaptchaSolve($event)"></re-captcha>
        </div>
      </form>
    </section>
  </div>
  <div class="m-action">
    <div class="center-box">
      <div class="btn btn-back" (click)="CloseProtocoloForm()">Fechar</div>
      <div class="btn btn-defaut" (click)="EnviarEmailProtocolo()" *ngIf="!formProtocoloLoading">Enviar</div>
      <div *ngIf="formProtocoloLoading">Aguarde...</div>
    </div>
  </div>
</app-modal>

<app-modal *ngIf="formProtocoloResult.submitted && !formProtocoloResult.result">
  <div class="m-title">
    <img src="../../../../assets/image/icons/icon-close.png" alt="" srcset="">
  </div>
  <div class="m-body">
    <p>Houve um erro ao enviar o e-mail.</p>
    <p *ngIf="formProtocoloErrorMessage" [innerHTML]="formProtocoloErrorMessage"></p>
    <div class="btn btn-primary btn-center" (click)="CloseErrorPopup()">Voltar</div>
  </div>
</app-modal>

<app-modal *ngIf="formProtocoloResult.submitted && formProtocoloResult.result">
  <div class="m-title">
    <img src="../../../../assets/image/icons/icon-check.png" alt="" srcset="">
  </div>
  <div class="m-body">
    <p>Email enviado com sucesso. Por favor verifique sua caixa de entrada.</p>
    <div class="btn btn-primary btn-center" (click)="CloseErrorPopup()">Voltar</div>
  </div>
</app-modal>