import { Component, ViewChildren } from '@angular/core';
import { Servico } from '../../../models/model';
import {HttpClient} from '@angular/common/http';
import { ServicosService } from '../../../services/servicos.service';
import { ResponsiveService } from '../../../services/responsive.service';

@Component({
  selector: 'app-servicos-destaque',
  templateUrl: './servicos-destaque.component.html',
  styleUrls: ['./servicos-destaque.component.css']
})
export class ServicosDestaqueComponent {

  constructor(
    private http: HttpClient,
    private responsiveService: ResponsiveService,
  ) {}

  helper: ServicosService = new ServicosService(this.http);

  isLoading: boolean = true;

  allItens: any = [];
  itens: any = [];

  containerWidth: number = 0;
  slideWidth: number = 0;
  slidesToShow: number = 5;
  slideMargin: number = 0;

  responsiveState: string = '';
  adaptiveState: string = '';

  @ViewChildren('servicosdestaqueslides') slickslides: any;
  @ViewChildren('servicosdestaqueroot') slickslideroot: any;

  ngAfterViewInit(){
    this.slickslides.changes.subscribe((t: any) => {
      setTimeout(() => {
        this.insertSlickSlider();
      }, 100);
    });
  }

  ngOnInit(){
    this.helper.getCachedDestaqueServicos()
    .subscribe((itens: any) => {
      this.isLoading = false;
      this.allItens = itens;
      this.adjustSlickItems();
    });
    this.initResponsiveState();
  }

  initResponsiveState(){
    this.responsiveService.onChange((state: string) => {
      if(state === 'tablet'){
        state = 'desktop';
        this.adaptiveState = 'tablet';
      }
      this.responsiveState = state;
      this.adjustSlickItems();
    });
  }

  adjustSlickItems(){
    if(this.responsiveState == 'desktop' && this.allItens.length > 5){
      this.itens = this.allItens.concat(this.allItens.slice(0, 5 - (this.allItens.length % 5)));
    }else{
      this.itens = this.allItens;
    }
  }

  insertSlickSlider(){
    const self = this;
    this.slidesToShow = this.responsiveState == 'desktop' ? (this.adaptiveState == 'tablet' ? 4 : 5) : 3;
    this.containerWidth = this.slickslideroot.first.nativeElement.clientWidth;
    this.slideWidth = this.responsiveState == 'desktop' ? (this.adaptiveState == 'tablet' ? 140 : 205) : 134;
    this.slideMargin = (this.containerWidth - (this.slideWidth * this.slidesToShow)) / 5;
    const slideCount = this.itens.length;
    const isInfinite = (slideCount * this.slideWidth) > this.containerWidth;
    const jQuery = (window as any).jQuery;
    jQuery(document).ready(function($: any){
      $('.pe-slick-slider').slick({
        infinite: isInfinite,
        slidesToShow: isInfinite ? self.slidesToShow : 99,
        slidesToScroll: isInfinite ? (self.responsiveState == 'desktop' ? self.slidesToShow : 1) : 99,
        arrows: self.responsiveState == 'desktop',
        dots: self.responsiveState == 'desktop' ? false : isInfinite,
        centerMode: false,
        variableWidth: true,
      });
      setTimeout(() => {
        $('.pe-slick-slider').slick('setPosition');
      }, 100);
      $('.pe-slick-slider').on('wheel', function(e: any) {
        e.preventDefault();
        if (e.originalEvent.deltaY < 0) {
          $('.pe-slick-slider').slick('slickPrev');
        } else {
          $('.pe-slick-slider').slick('slickNext');
        }
      });
    });
  }

  limitText(text: string, limit: number){
    return text.substr(0, limit) + (text.length > limit ? '...' : '');
  }

  DisableEvent(event: any){
    event.stopPropagation();
    event.preventDefault();
  }
}
