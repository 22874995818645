import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-steps',
  templateUrl: './steps.component.html',
  styleUrls: ['./steps.component.css']
})
export class StepsComponent {
  @Input() current: string = "";
  @Input() available: any = {};

  total: number = 0;
  percent: number = 0;
  step: number = 0;

  ngOnChanges(changes: any) {
    if (changes.current || changes.available) {
      this.calculateSteps();
    }
  }

  ngOnInit() {
    this.calculateTotal();
    this.calculateSteps();
  }

  calculateTotal(){
    this.total = 0;
    for(let i in this.available){
      if(this.available[i].step > this.total){
        this.total = this.available[i].step;
      }
    }
    this.total++;
  }

  private calculateSteps() {
    this.step = this.available[this.current].step;
    this.percent = 100 / (this.total / (this.step + 1));
  }
}
