<div class="container" *ngIf="tipoAgendamento | async as agendamento; else loading">
    <div class="ajuda-box">
        <h3 class="internal-title">Instruções de atendimento</h3>
        <div class="accordion accordion-flush" id="accordionAgendamento">
            <div class="accordion-item">
                <h2 class="accordion-header" id="flush-headingThree">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" [attr.data-bs-target]="'#flush-collapse0'" aria-expanded="false" aria-controls="flush-collapseThree">
                    Instruções de atendimento
                </button>
                </h2>
                <div id="flush-collapse0" class="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionAgendamento">
                <div class="accordion-body">
                    <div class="richtext-font-1" [innerHTML]="agendamento.requisitos"></div>
                </div>
                </div>
            </div>
            <div class="accordion-item">
                <h2 class="accordion-header" id="flush-headingThree">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" [attr.data-bs-target]="'#flush-collapse1'" aria-expanded="false" aria-controls="flush-collapseThree">
                    Outros detalhes
                </button>
                </h2>
                <div id="flush-collapse1" class="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionAgendamento">
                <div class="accordion-body">
                    <div class="richtext-font-1" [innerHTML]="agendamento.tempoEntrega"></div>
                </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #loading>
    <p>Carregando...</p>
</ng-template>