<div class="container">

    <div class="row">
        <div class="col">
            
            <p class="font-style-2" *ngIf="isLoading">Carregando...</p>
            <p class="font-style-2" *ngIf="!isLoading && results.length == 0">Não foram encontrados serviços relacionados a {{searchQuery}} no Portal. Tente buscar usando outra palavra.</p>
            <p class="font-style-2" *ngIf="!isLoading && results.length > 0">{{results.length}} serviços relacionados a {{searchQuery}} no Portal.</p>
            <div class="box {{results.length == 1 ? 'active' : ''}}" id="box-{{i}}" *ngFor="let result of results;let i = index">
                <div class="flex-space-between cursor-pointer" (click)="OnToggleBox(i)"><div class="title">{{result.nome}}</div><div class="toggle-box"></div></div>
                <div class="subtitle content" [innerHTML]="removeRichText(result.descricao).substr(0, 200) + (removeRichText(result.descricao).length > 200 ? '...' : '')"></div>
                <div class="actions content">
                    <a href="/servico/informacoes-servico/{{result.id}}">
                        <button class="gray">Mais informações</button>
                    </a>
                    <!-- <a href="/agendamento/agendar/{{result.id}}">
                        <button class="blue">Agendar</button>
                    </a> -->
                </div>
            </div>
            <div class="spacer"></div>
        </div>
    </div>
</div>